import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Modal, Tag, ConfigProvider, Space } from "antd";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import { toast } from "react-toastify";
import Column from "antd/es/table/Column";

interface User {
  name: string;
  lastName: string;
  email: string;
  studentNumber: string;
  identifier: {
    identifier: string;
  }
  role: string;
  passwordSentAt: boolean;
  passwordSent: string;
  // Add more properties as needed
}

interface UserDataProps {
  // You can also make user prop optional if you want to handle the case where user data is still loading
  user?: User;
}

type UsersParams = {
    id: string;
  };


  const buildStatus = (status: string) => {
    if (status === "DELIVERED") {
      return "Entregado";
    } else if (status === "NOT_DELIVERED" ){
      return "No entregado";
    } else {
      return status;
    }
  }
  

const UserData: React.FC<UserDataProps> = () => {
  const { id } = useParams<UsersParams>();
  const authHeader = useAuthHeader();
  const [userDetails, setUserDetails] = useState<any>();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/assignmentUser?user=${id}`, { headers: { Authorization: authHeader() } });
        const responseData = response.data;
        setUserDetails(responseData.data);
      } catch (error) {
        toast.error(`Error al cargar datos: ${error || 'Error desconocido'}`, {
          position: "bottom-right",
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false
        });
        // Handle error, e.g., set an error state
      }
    };
    if (id) {
      fetchUserData();
    }
  }, [id]);

  return (
    <div className="Admin">
      <AdminNavbar />
      <Container className="mt-3 col-lg-10">
        <div className="card rounded p-2 custom-bg-gray border-none">
          <div className="card-body">
              <h5 className="card-title title-text-left">Ensayos del Usuario</h5>
            {userDetails ? 
             <>
              <div className="container mt-3 px-0">
                <div className="table-responsive">

                <ConfigProvider
                  theme={{
                    token: {
                      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                  sans-serif`,
                      colorIcon: 'white',
                    },
                    components: {
                      Table: {
                        headerBg: '#072f75',
                        headerColor: '#f0f0f0',
                        headerSortHoverBg: '#1165f6',
                        headerSortActiveBg: '#072f75',
                      },
                    },
                  }}
                >
                  <Table dataSource={userDetails} pagination={false} className="table bg-white" style={{ width: '100%' }}>
                    <Column title="ID" dataIndex="id" key="id" />
                    <Column title="Estado" dataIndex="status" key="status"
                      render={(status) => buildStatus(status)}
                    />
                    <Column
                      title="Buzón"
                      dataIndex="id"
                      key="assignmentUser"
                    />
                    <Column
                      title="Puntaje final"
                      dataIndex="score"
                      key="finalScore"
                      render={(score) => (score !== null && score !== undefined ? score : '--')}
                    />
                    <Column
                      title="Corrections"
                      dataIndex="corrections"
                      key="corrections"
                      render={(corrections) => (
                        <Space size="middle">
                          {corrections.map((correction: any) => (
                            <span key={correction.id}>{`Corrector ID: ${correction.correctorId}, Score: ${correction.score}`}</span>
                          ))}
                        </Space>
                      )}
                    />
                    <Column
                      title="Reviews Received"
                      dataIndex="reviewsRecieved"
                      key="reviewsRecieved"
                      render={(reviewsReceived) => (
                        <Space size="middle">
                          {reviewsReceived.map((review: any) => (
                            <span key={review.id}>{`Corrector ID: ${review.correctorId}, Score: ${review.correctionScore}`}</span>
                          ))}
                        </Space>
                      )}
                    />
                    <Column
                      title="Correcciones"
                      dataIndex="revisions"
                      key="revisions"
                      render={(revisions) => (
                        <Space size="middle">
                          <span >{revisions.reduce((total: any, revision: any) => total + revision.scoreDelta, 0).toString()}</span>
                          {/* {revisions.map((revision: any) => (
                            <span key={revision?.id}>{`${revision.scoreDelta}`}</span>
                          ))} */}
                        </Space>
                      )}
                    />
                    <Column
                      title="Ensayo"
                      dataIndex="attachments"
                      key="attachments"
                      render={(attachments) => (
                        <Space size="middle">
                          {attachments.map((attachment: any) => (
                            <Button key={attachment.id} href={attachment.url} target="_blank" className="btn-small">
                              Ver
                            </Button>
                          ))}
                        </Space>
                      )}
                    />
                  </Table>
                </ConfigProvider>
                </div>
             </div>
             </> 
             :
              <p>Cargando información...
                <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
              </p>
            }

          </div>
        </div>
      </Container>
    </div>
  );
};

export default UserData;
