import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CollaboratorNavbar from '../../components/CollaboratorNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Form} from 'antd';
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { Formik, useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from "yup";

interface Attachment2 {
  essayFileName: string;
  url: string;
}

interface Assignment {
  number: number;
}

interface CorrectionReview {
  correctedId: number;
  assigmentUser: {
    user: {
      name: string;
      lastName: string;
      email: string;
      studentNumber: string;
    };
  };
  correctionScore: number;
  corrected: boolean;
  studentComment: null | string;
  correction: {
    comments: null | string;
  };
}

interface ReviewsRecieved {
  correctedId: number;
  assigmentUser: {
    user: {
      name: string;
      lastName: string;
      email: string;
      studentNumber: string;
    };
  };
  correctionScore: number;
  corrected: boolean;
  studentComment: null | string;
  correction: {
    comments: null | string;
  };
}

interface Correction2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number;
  attachment: Attachment2;
}

interface CorrectionsMade2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number;
  attachment: Attachment2;
}

interface AssignmentUser2 {
  assignment: Assignment;
  id: number;
  status: string;
  user: {
    name: string;
    lastName: string;
    email: string;
    studentNumber: string;
  };
  corrections: Correction2[];
  correctionsMade: CorrectionsMade2[];
  correctionReviews: CorrectionReview[];
  reviewsRecieved: ReviewsRecieved[];
}

interface CorrectionData2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number;
  attachment: Attachment2;
}

interface Data2 {
  id: number;
  collaboratorId: number;
  requesterId: null | number;
  scoreDelta: null | number;
  studentComment: string;
  collaboratorComment: null | string;
  corrected: boolean;
  stage: string;
  assigmentUser: AssignmentUser2;
  correction: CorrectionData2;
  correctionReviews: CorrectionReview[];
  reviewsRecieved: ReviewsRecieved[];
  review: CorrectionReview;
}

interface ApiResponse {
  data: Data2[];
}

const AyudanteEtapa3: React.FC = () => {
  const [revisions, setRevisions] = useState<Data2[]>([]);
  const [editingRevision, setEditingRevision] = useState<Data2 | null>(null);
  // const [isCorrectionModalOpen, setIsCorrectionModalOpen] = useState(false);
  // const [correctionsSum, setCorrectionsSum] = useState<number>(0);
  // const [assignedScore, setAssignedScore] = useState<number>(0);
  const [filterLoading, setFilterLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isDisabled, setIsDisabled] = useState(false);

  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);
  const authUser = useAuthUser();
  const userId = authUser()?.id;

  const fetchData = async () => {
    formik.values.SearchType = 'false';
    setFilterLoading(true);
    try {
      const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/${userId}?stage=THIRD`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (response2.data && response2.data.data) {
        const filteredData = response2.data.data.filter((item: Data2) =>
          (item.assigmentUser && item.stage === "THIRD" && item?.review?.corrected === true)
          // (item.assigmentUser && item.assigmentUser.status === "MU" && item.stage === "THIRD" && !item.review)
        );
        setRevisions(filteredData);
      }
    } catch (error) {
      setFilterLoading(false);
      // console.error('Error fetching data:', error);
    }
    setFilterLoading(false);
  };
  
  const handleSubmitForm = async (values: any) => {
    try {
      setFilterLoading(true);
      const extraValue = values.SearchType != '' ? `&corrected=${values.SearchType === 'true' ? true : false}` : '';
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/${userId}?stage=THIRD${extraValue}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && response.data.data) {
        const filteredData = response.data.data.filter((item: Data2) =>
          (item.assigmentUser && item.stage === "THIRD" && item?.review?.corrected === true)
          // (item.assigmentUser && item.assigmentUser.status === "MU" && item.stage === "THIRD" && !item.review)
        );
        setRevisions(filteredData);
      }
      setFilterLoading(false);
    } catch (error) {
      setFilterLoading(false);
      toast.error(`Error al cargar datos`, {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
      });
    }
  };  
  
  const validationSchema = Yup.object({
    SearchType: Yup.string().required('Option is required'),
  });

  const formik = useFormik({
    initialValues: {
      SearchType: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmitForm,
  });

  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };


  useEffect(() => {
    fetchData();

    }, []);

  const updateCorrectionData = async (dataId: number, collaboratorComment: string, scoreDelta: number) => {
              try {
                const response = await axios.put(
                  `${process.env.REACT_APP_API_URL}/stages/fourth/${dataId}`,
                  {
                    comment: collaboratorComment,  // Use "comment" instead of "collaboratorComment"
                    scoreDelta,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
          
                // You may want to update the local state with the modified data if needed
                setRevisions((prevRevisions) => {
                  return prevRevisions.map((revision) => {
                    if (dataId === revision.id) {
                      return {
                        ...revision,
                        collaboratorComment: response.data.comment,  // Update with the correct property
                        scoreDelta: response.data.scoreDelta,
                      };
                    }
                    return revision;
                  });
                });
                window.location.reload();

              } catch (error) {
                // console.error('Error updating data:', error);
              }
  };

  return (
    <div className="Admin">
      <CollaboratorNavbar />
        <div className="container">
          <div className="text-center my-4">
                    <div className="title-text">Recorreccion Etapa 3 </div>
          </div>
            <div className="rounded bg-white p-2 border border-1 mb-2">
              <Formik
                    initialValues={{
                      SearchType: 'false',
                    }}
                    onSubmit={(e) => { handleSubmitForm(formik.values); }}
                  >
                    <Form className="row g-2 align-items-center">
                      {/* Dropdown */}
                      <div className="col-auto">
                          <select
                            id="SearchType"
                            name="SearchType"
                            className="form-select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.SearchType}
                          >
                            <option value="false">No corregidos</option>
                            <option value="true">Corregidos</option>
                          </select>
                      </div>

                      {/* Submit button */}
                      <div className="col-auto">
                        <Button type='primary' onClick={(e) => handleSubmitForm(formik.values)}>
                          Filtrar
                          {filterLoading && (
                            <span className="spinner-border spinner-border-sm ms-2" aria-hidden="true"></span>
                          )  
                          }
                        </Button>
                      </div>
                      <Button
                        disabled={isDisabled}
                        className="w-auto px-4 py-auto btn-secondary"
                        onClick={fetchData}
                      >
                        Resetear filtros
                        {filterLoading && (
                         <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        )  
                        }
                      </Button>
                    </Form>
              </Formik>
            </div>
          <Table dataSource={revisions} rowKey="id">
                  <Table.Column
                    title="Numero de Ensayo"
                    dataIndex={["assigmentUser", "assignment", "number"]}
                    key="assigmentUser.assignment.number"
                  />
                  <Table.Column title="Corregido?" dataIndex="corrected" key="corrected" render={corrected => corrected ? 'Si' : 'No'} />
                  <Table.Column
                    title="Email Estudiante"
                    dataIndex={["assigmentUser", "user", "email"]}
                    key="assigmentUser.user.email"
                  />
      
                <Table.Column
                  title="Puntaje Etapa 3 Pre Recorrección"
                  key="correctionsSum"
                  render={(_, record: Data2) => {
                    return <span>{record?.review?.correctionScore != null ?  record?.review?.correctionScore : '-'}</span>;
                  }}
                />
                <Table.Column
                  title="Puntaje Etapa 3 Post Recorrección"
                  key="totalScore"
                  render={(_, record: Data2) => {
                    const sumScoreDelta = record?.scoreDelta || 0;
                    return <span>{record?.corrected ? sumScoreDelta + record?.review?.correctionScore : '--'}</span>;
                  }}
                /> 

            <Table.Column
              title="Attachment URL"
              dataIndex={["assigmentUser", "correctionsMade", 0, "attachment", "url"]}
              key="assigmentUser.correctionsMade.0.attachment.url"
              render={(url: string) => (
                <a href={url} target="_blank" rel="noopener noreferrer">
                  Open URL
                </a>
              )}
            />

                    <Table.Column 
                      title="Acciones" 
                      key="correction" 
                      render={(_, record: Data2) => (
                        <Button type="primary" href={`/recorregir/${record.id}`}>
                          Recorregir  
                        </Button>
                        // <Button type="primary" onClick={() => handleCorrectionClick(record)}>
                        //   Recorregir
                        // </Button>
                      )}
                    />
      
          </Table>  
        </div>
    </div>
  );
};
      
export default AyudanteEtapa3; 
      


{/* <Modal
title="Detalle de Corrección 1"
visible={isCorrectionModalOpen}
onCancel={() => {
  setIsCorrectionModalOpen(false);
  setAssignedScore(0); // Reset assigned score on modal close
}}
onOk={() => {
  if (editingRevision) {
    const updatedData: Partial<Data2> = {
      id: editingRevision.id,
      scoreDelta: editingRevision.scoreDelta,
      collaboratorComment: editingRevision.collaboratorComment
    };
// Update scoreDelta based on the relation
updatedData.scoreDelta = assignedScore - correctionsSum;



handleSaveChanges(updatedData);
setIsCorrectionModalOpen(false);
setAssignedScore(0); // Reset assigned score on modal close
  }
}}
>
<Form layout="vertical">


<Form.Item label="Corrección 1 alumno:">
<Input.TextArea
value={editingRevision?.assigmentUser.correctionsMade[0].justification || ''}
disabled
// style={{ backgroundColor: '#f0f0f0', color: 'black', /* Add any other styles you need */ 
// }}
// onChange={(e) => {
// You can add any specific handling here if needed
// }}
// />
// </Form.Item>
// <Form.Item label="Corrección 2 alumno:">
// <Input.TextArea
// value={editingRevision?.assigmentUser.correctionsMade[1].justification || ''}
// disabled
// style={{ backgroundColor: '#f0f0f0', color: 'black', /* Add any other styles you need */ }}
// onChange={(e) => {
// You can add any specific handling here if needed
// }}
// />
// </Form.Item>

// <Form.Item label="Puntaje Total Etapa 2:">
// <span>{editingRevision?.assigmentUser.reviewsRecieved.reduce((total, correctionReviews) => total + correctionReviews.correctionScore, 0)}</span>
// </Form.Item>

// <Form.Item label="Comentario del Estudiante:">
// <Input.TextArea
// value={editingRevision?.studentComment || ''}
// disabled
// style={{ backgroundColor: '#f0f0f0', color: 'black', /* Add any other styles you need */ }}
// onChange={(e) => {
// You can add any specific handling here if needed
// }}
// />
// </Form.Item>

// <Form.Item label="Puntaje Asignado Etapa 2:">
// <InputNumber
// min={0}
// max={6}
// value={assignedScore !== 0 ? assignedScore : (editingRevision?.scoreDelta || 0) + correctionsSum}
// onChange={(value) => setAssignedScore(value ?? 0)}
// />
// </Form.Item>

// <Form.Item label="Comentario del Colaborador">
{/* <Input.TextArea
defaultValue={editingRevision?.collaboratorComment || ''}
onChange={(e) => {
if (editingRevision) {
setEditingRevision({
...editingRevision,
collaboratorComment: e.target.value
});
}
}}
/>
</Form.Item>
 */}}
