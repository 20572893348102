import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import "../../styles/customForms.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { useParams } from "react-router-dom";
import { ConfigProvider, DatePicker, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Button, Col, Container, Row } from "react-bootstrap";
import MyNavbar from "../../components/NavBar";

const Revisions: React.FC = () => {
  const authHeader = useAuthHeader();
  const authUser = useAuthUser();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [sectionFilter, setSectionFilter] = useState<any>();
  const { page, limit } = useParams();
  const [pageSize, setPageSize] = useState<number>(10);

  const setSpinners = (status: boolean) => {
    setIsDisabled(status);
    setLoading(status);
  }

  const columns: ColumnsType<any> = [
    {
      title: "Número Ensayo",
      dataIndex: "essayNumber",
      key: "essayNumber",
      sorter: (a: any, b: any) =>
        Number(a.essayNumber) - Number(b.essayNumber),
      filters: sectionFilter,
      onFilter: (value, record: any) =>
        record.essayNumber.indexOf(String(value)) === 0,
    },
    {
      title: "Etapa",
      dataIndex: "stage",
      key: "stage",
    },
    {
      title: "Corregido",
      dataIndex: "corrected",
      key: "corrected",
    },
    {
      title: "Puntaje",
      dataIndex: "scoreDelta",
      key: "scoreDelta"
    },
    {
      title: "Accion",
      dataIndex: "linkUser",
      key: "linkUser",
      render:(_, record) => (
        <Button variant="primary" size="sm" href={`/revisions/${record.id}`} >
          Ver
        </Button>
      ),
    },
  ];


  const setUserList = async () => {
    setSpinners(true);
    const usersEndpoint = "/revisions/students";
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${usersEndpoint}?requester=${authUser()?.id}&limit=${limit}&page=${page}`,
        { headers: { Authorization: authHeader() } }
      );
      const users_data = response.data.data;
      const tableData: any[] = users_data.map(
        (item: any, index: number) => {
          return {
            id: `${item.id}`,
            essayNumber: `${item?.assigmentUser?.assignment?.number || '-'}`,
            stage: `${item?.stage === "THIRD" ? "3" :  "2" }`,
            corrected: `${item?.corrected ? "Si" : "No"}`,
            scoreDelta: `${item?.scoreDelta ? item?.scoreDelta.toString() : '--'}`,
            linkUser: `/users/${item.id}`,
          };
        }
      );
      const EssayNumberFilter = Array.from(
        new Set(tableData.map((item) => item.essayNumber))
      )
        .sort((a: string, b: string) => Number(a) - Number(b))
        .map((x) => {
          return { text: x.toString(), value: x.toString() };
      });
      setSectionFilter(EssayNumberFilter);
      setData(tableData);
      setSpinners(false);
    } catch (error) {
        console.log(error);
        setSpinners(false);
        toast.error(`Error al cargar datos: ${error || 'Error desconocido'}`, {
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: false,
        });
    }
  }

  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };

  useEffect(() => {
    setUserList();
  }, []);
  

  return (
    <div className="Admin">
      <MyNavbar />
      <Container className="mt-3  col-lg-11 overflow-auto">
        <div className="card rounded p-2 custom-bg-gray border-none overflow-auto">
          <div className="card-body">
            <h5 className="card-title title-text-left">Recorrecciones</h5>
            <div className="card-text">
              { data && data.length > 0 ? 
                <>
                  <ConfigProvider
                    theme={{
                      token: {
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif`,
                        colorIcon: "white",
                      },
                      components: {
                        Table: {
                          /* here is your component tokens */
                          headerBg: "#072f75",
                          headerColor: "#f0f0f0",
                          headerSortHoverBg: "#1165f6",
                          headerSortActiveBg: "#072f75",
                        },
                      },
                    }}
                  >
                    <Table
                      columns={columns}
                      dataSource={data}
                      rowKey={(record) => record.id}
                      bordered
                      pagination={{
                        pageSize: pageSize,
                        total: data.length,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "10", "20", "50"],
                        onChange: handleChangePageSize,
                      }}
                    />
                  </ConfigProvider>
                </>
                : <>No hay recorrecciones hechas.</>
              }

            </div>
            
          </div>
        </div>
        
      </Container>

      <ToastContainer />
    </div>
  );
};

export default Revisions;