import React from "react";
import Logout from "./views/Logout";
import Login from "./views/Login";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./views/HomePage";
import Etapa1 from "./views/student/Etapa1";
import Etapa2 from "./views/student/Etapa2";
import Etapa3 from "./views/student/Etapa3";
import Revisions from "./views/student/Revisions";
import AyudanteEtapa2 from "./views/collaborator/Etapa2";
import AyudanteEtapa3 from "./views/collaborator/Etapa3";
import FormAdmin from "./views/admin/FormAdmin";
import LoadUsers from "./views/admin/LoadUsers";
import TablaEnsayos from "./views/admin/TablaEnsayos";
import NotFoundPage from "./views/NotFoundPage";
import FAQSPage from "./views/FAQS";
import Resultados from "./views/student/Resultados";
import PrivateRouteWithRole from "./utils/PrivateRoute";
import ChangePassword from "./views/ChangePassword";
import Formularios2 from "./views/admin/Formularios2";
import Ensayos from "./views/admin/Ensayos";
import RecorreccionEtapa3 from "./views/student/RecorreccionEtapa3";
import RecorreccionEtapa2 from "./views/student/RecorreccionEtapa2";
import AyudanteRecorreccionEtapa3 from "./views/collaborator/RecorreccionEtapa3";
import AyudanteRecorreccionEtapa2 from "./views/collaborator/RecorreccionEtapa2";
import PrivateCommonRoutes from "./utils/CommonRoutes";
import RecoverPassword from "./views/RecoverPassword";
import CorrectionsPage from "./views/admin/Corrections";
import Downloads from "./views/admin/Descargas";
import RecorrectionAdmin from "./views/admin/Recorrections";
import TableCorrectionsView from "./views/admin/TablaCorrecciones";
import TableRecorrectionsView from "./views/admin/TablaRecorrecciones";
import Students from "./views/admin/Students";
import RecorrectionSite from "./views/collaborator/RecorrectionSite";
import UserForm from "./views/admin/UserForm";
import UserShow from "./views/admin/Users";
import EditUser from "./views/admin/EditUser";
import UserData from "./views/admin/UserData";
import Files from "./views/admin/Files";
import RevisionShow from "./views/student/RevisionShow";
import SummaryCollaborator from "./views/collaborator/Summary";

export default function RoutesComponent() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/recuperar-contrasena" element={<RecoverPassword />} />
        <Route
          element={
            <PrivateRouteWithRole
              role={"COLLABORATOR"}
              redirectPath={"/login"}
            />
          }
        >
          <Route path="/summary/collaborator" element={<SummaryCollaborator />} />
          <Route path="/etapa2" element={<AyudanteEtapa2 />} />
          <Route path="/etapa3" element={<AyudanteEtapa3 />} />
          {/* <Route path="/etapa3" element={<AyudanteEtapa3 />} /> */}
          <Route path="/recorregir-etapa3" element={<AyudanteRecorreccionEtapa3 />} />
          <Route path="/recorregir-etapa2" element={<AyudanteRecorreccionEtapa2 />} />
          <Route path="/recorregir/:id" element={<RecorrectionSite />} />
        </Route>
        <Route
          element={
            <PrivateRouteWithRole role={"STUDENT"} redirectPath={"/login"} />
          }
        >
          <Route path="/etapa1A" element={<Etapa1 />} />
          <Route path="/etapa2A" element={<Etapa2 />} />
          <Route path="/etapa3A" element={<Etapa3 />} />
          <Route path="/resultados" element={<Resultados />} />
          <Route path="/revisions" element={<Revisions />} />
          <Route path="/revisions/:id" element={<RevisionShow />} />
          <Route
            path="/recorregir-etapa3-alumno"
            element={<RecorreccionEtapa3 />}
          />
          <Route
            path="/recorregir-etapa2-alumno"
            element={<RecorreccionEtapa2 />}
          />
        </Route>
        <Route
          element={
            <PrivateRouteWithRole role={"ADMIN"} redirectPath={"/login"} />
          }
        >
          <Route path="/crear_formulario" element={<FormAdmin />} />
          <Route path="/formularios/:page/:limit" element={<Formularios2 />} />
          <Route path="/ensayos" element={<TablaEnsayos />} />
          <Route path="/load-users" element={<LoadUsers />} />
          <Route path="/students" element={<Students />} />
          <Route path="/files" element={<Files />} />
          <Route path="/usersForm" element={<UserForm />} />
          <Route path="/users/:id" element={<UserShow />} />
          <Route path="/users/:id/edit" element={<EditUser />} />
          <Route path="/users/:id/data" element={<UserData />} />
          <Route
            path="/asignar-recorrecciones"
            element={<RecorrectionAdmin />}
          />
          <Route
            path="/ensayos/:sectionParam/:assignmentNumberParam"
            element={<Ensayos />}
          />
          <Route path="/correcciones" element={<TableCorrectionsView />} />
          {/* tablas de estados de correcciones */}
          <Route
            path="/correcciones/:stageParam/:sectionParam/:assignmentNumberParam"
            element={<CorrectionsPage />}
          />
          <Route path="/descargas" element={<Downloads />} />
          <Route path="/visualizar-recorrecciones" element={<TableRecorrectionsView />} />
        </Route>
        <Route element={<PrivateCommonRoutes redirectPath={"/login"} />}>
          <Route path="/logOut" element={<Logout />} />
          <Route path="/change-password" element={<ChangePassword />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/faq-user" element={<FAQSPage />} />
      </Routes>
    </BrowserRouter>
  );
}
