// LoginButton.tsx

import React from 'react';
import { useNavigate } from 'react-router-dom';

const LoginButton: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginClick = () => {
    navigate('/login');
  };

  return (
    <button onClick={handleLoginClick}>Iniciar Sesión</button>
  );
};

export default LoginButton;
