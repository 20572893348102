import React from "react";
import { useAuthUser } from "react-auth-kit";
import { useIsAuthenticated } from "react-auth-kit";
import AdminNavbar from "./AdminNavbar";
import MyNavbar from "./NavBar";
import HomeNavbar from "./HomeNavbar";
import CollaboratorNavbar from "./CollaboratorNavbar";

export default function RoleNavBar() {
  const auth = useAuthUser();
  const userRole = auth()?.role;
  const isAuthenticated = useIsAuthenticated();
  if (isAuthenticated()){
    if (userRole === "ADMIN") {
      return <AdminNavbar />;
  } else if (userRole === "STUDENT") {
      return <MyNavbar />;
  } else if (userRole === "COLLABORATOR") {
      return <CollaboratorNavbar />;
  }
  }
  return <HomeNavbar />;
}
