import React, { useEffect } from "react";
import { Navbar, Nav, NavDropdown, NavItem } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import CurrentUserInfo from "./CurrentUserInfo";
import { useIsAuthenticated } from "react-auth-kit";

export default function AdminNavbar() {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      validateSessionValidity(navigate);
    }
  }, [isAuthenticated, navigate]);

  const validateSessionValidity = (navigate: ReturnType<typeof useNavigate>): void => {
    if (isTokenExpired()) {
      navigate('/login');
    }
  };
  
  const isTokenExpired = (): boolean => {
    try {
      const expiryTimeString = localStorage.getItem('tokenExpiry');
  
      if (!expiryTimeString) {
        return true;
      }
  
      const expiryTimeNumber = Number(expiryTimeString);
  
      if (isNaN(expiryTimeNumber)) {
        return true;
      }
  
      const expiryDate = new Date(expiryTimeNumber);
  
      return new Date() >= expiryDate;
    } catch (error) {
      return true;
    }
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="shadow p-3">
      <Navbar.Brand as={Link} to="/formularios/1/10" className="navbar-link">
        Limerick
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="navbar-nav-dropdown"
        className="custom-toggler"
      />
      <Navbar.Collapse id="navbar-nav-dropdown">
        <Nav className="ms-auto">
          <NavItem>
            <CurrentUserInfo />
          </NavItem>
          <Nav.Link
            as={Link}
            to="/formularios/1/10"
            active
            className="navbar-link"
            style={{ marginRight: "13px" }}
          >
            Formularios
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/crear_formulario"
            active
            className="navbar-link"
            style={{ marginRight: "13px" }}
          >
            Crear Formulario
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/ensayos"
            active
            className="navbar-link"
            style={{ marginRight: "13px" }}
          >
            Etapa 1
          </Nav.Link>
          <Nav.Link
            as={Link}
            to="/correcciones"
            active
            className="navbar-link"
            style={{ marginRight: "13px" }}
          >
            Etapas 2-3
          </Nav.Link>

          <NavDropdown
            title={
              <span style={{ color: "#072f75", fontWeight: "bold" }}>
                Recorrecciones
              </span>
            }
            id="recorregir-dropdown"
            active
          >
            <NavDropdown.Item as={Link} to="/asignar-recorrecciones">
              Asignar ayudantes
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/visualizar-recorrecciones">
              Visualizar
            </NavDropdown.Item>
          </NavDropdown>

          <NavDropdown
            title={
              <span style={{ color: "#072f75", fontWeight: "bold" }}>
                Mi cuenta
              </span>
            }
            id="cuenta-dropdown"
            active
            align={{ lg: "end" }}
          >
            <NavDropdown.Item as={Link} to="/students">
              Usuarios
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/files">
              Archivos
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/descargas">
              Descargas
            </NavDropdown.Item>
            {/* <Nav.Link
            as={Link}
            to="/descargas"
            active
            className="navbar-link"
            style={{ marginRight: "13px" }}
          >
            Descargas
          </Nav.Link> */}
            <NavDropdown.Item as={Link} to="/load-users">
              Cargar Alumnos
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/change-password">
              Cambiar contraseña
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/logOut">
              Cerrar sesión
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
