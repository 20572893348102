import React, { useState, useEffect } from "react";
import { Evaluation, Homework3, BoxProps3 } from "./SharedTypes";

function formatDate(dateString: string | null) {
  if (dateString === null) {
    return null;
  }
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1; 
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;

  const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  return `${formattedDate}, ${formattedTime}`;
}



const BlockedEvaluationBox: React.FC<BoxProps3> = ({
  type,
  homeworkList: initialHomeworkList,
}) => {
  const [homeworks, setHomeworks] = useState<Homework3[]>(initialHomeworkList);
  const [showModal, setShowModal] = useState(false);
  const [currentEvaluation, setCurrentEvaluation] = useState<Evaluation | null>(
    null
  );
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null); 

  useEffect(() => {
    const initializedHomeworks = initialHomeworkList.map((hw) => ({
      ...hw,
      evaluations: hw.evaluations 
    }));
    setHomeworks(initializedHomeworks);
  }, [initialHomeworkList]);

  const handleOpenModal = (evaluation: Evaluation, index: number) => {
    setCurrentEvaluation(evaluation);
    setActiveAccordion(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);

};


  const handleAccordionClick = (index: number) => {
    if (activeAccordion === index) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(index);
    }
  };

  return (
    <div className={`homework-box ${type} container mt-3`}>
      <h2 style={{ color: "#072f75" }}>
        {type === "pending" ? "Próximas Tareas" : "Tareas Anteriores"}
      </h2>
      {homeworks.map((hw: Homework3, index: number) => (
        <div key={index} className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === index ? "active" : ""}`}
                type="button"
                onClick={() => handleAccordionClick(index)}
              >
                <span className="hw-title" style={{ width: "200px" }}> Ensayo {hw.assignmentNumber} </span>
                <span className="endDate">
                    {`Cerrado: ${formatDate(hw.deadline)}`}
                </span>
              </button>
            </h2>
            <div
               className={`accordion-collapse collapse ${activeAccordion === index ? "show" : ""}`}
            >
              <div className="accordion-body">
                {hw.evaluations && hw.evaluations.map(
                  (evaluation: Evaluation, evaluationIndex: number) => (
                
                    <div
                      key={evaluationIndex}
                      className={`correction-row mt-3 clickable ${evaluation.flowStatus === "OUT" ? "disabled" : ""}`}
                      onClick={() => evaluation.flowStatus !== "OUT" && handleOpenModal(evaluation, index)}
                    >
                      Evaluación {evaluationIndex + 1}
                      {evaluation.flowStatus === "OUT" ? (
                      <div className="points1">
                        Quedaste fuera del flujo
                      </div>
                    ) : (
                      <>
                        {evaluation.reviewText ? (
                          <div className="points1">
                            {evaluation.correctedReview ? (
                              <span>Evaluación a corrector: {evaluation.ratingGiven}/3</span>
                            ) : (
                              <span>No entregaste evaluación</span>
                            )}
                          </div>
                        ) : (
                          <div className="points1">
                            <span>No te han corregido</span>
                          </div>
                        )}
                      </>
                    )}                    
     
                    </div>
                    
                  )
                  
              
                    
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

  
  
  {showModal && (
      <div className="modal fade show d-block" tabIndex={-1}> 
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Detalles de Evaluación</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body">

                <div className="modal-body">
                <p><strong>Corrección:</strong> {currentEvaluation?.reviewText}</p>
                <p><strong>Evaluación a corrector:</strong> {currentEvaluation?.ratingGiven}/3</p>
              </div>

              
            </div>
            <div className="modal-footer">

              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseModal}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>

      </div>
  )}
  
    </div>
  );
};

export default BlockedEvaluationBox;