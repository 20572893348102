import React, { useState, useEffect } from 'react';
import MyNavbar from "../../components/NavBar";
import { HomeworkRecorrection2, BoxPropsRecorrection2, Recorrection2 } from "../../components/SharedTypes";
import RecorrectionBoxEtapa2 from "../../components/RecorrectionBoxEtapa2";
import BlockedRecorrectionBoxEtapa2 from "../../components/BlockedRecorrectionBoxEtapa2";
import axios from 'axios';
import "../../styles/etapa3.css";
import "../../styles/recorreccion3.css";
import { useAuthHeader, useAuthUser } from "react-auth-kit";



type ApiDataItem = {
  id: number;
  assignmentUser: AssignmentUser[];
};

type AssignmentUser = {
  revisions?: any;
  id: number;
  status: string;
  assignment: {
    number: number;
    assignmentSchedules: AssignmentSchedule[];
  };
  corrections: Correction[];
};

type AssignmentSchedule = {
  id: number;
  stage: string;
  status: string;
  startDate: string;
  endDate: string;
};

type Correction = {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  score: number;
  containsAuthorData: boolean;
  justification: string | null;
  comments: string | null;
};



const RecorreccionEtapa2: React.FC = () => {
  const [pendingHomework, setPendingHomework] = useState<HomeworkRecorrection2[]>([]);
  const [pastHomework, setPastHomework] = useState<HomeworkRecorrection2[]>([]);

  const authUser = useAuthUser();
  const userId = authUser()?.id;
  const backendUrl = process.env.REACT_APP_API_URL;
  const recorrectionEndpoint = `/stages/fourth/data/second/${userId}?corrections=true`
  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}${recorrectionEndpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseData = response.data;
        const apiData: ApiDataItem = responseData.data; 
        const assignmentUsers = apiData.assignmentUser
        const homeworks2: HomeworkRecorrection2[] = [];

        for (const assignmentUs of assignmentUsers){
          const assignmentNumber = assignmentUs.assignment.number.toString();
          const startDate = assignmentUs.assignment.assignmentSchedules[0].startDate;
          const deadline = assignmentUs.assignment.assignmentSchedules[0].endDate;
          const status = assignmentUs.assignment.assignmentSchedules[0].status;
          const stat = assignmentUs.status;
          const corrections = assignmentUs.corrections;
          const recorrections: Recorrection2[] = []
          for (const correction of corrections){
            recorrections.push(
                {
                  assignmentUserId: assignmentUs.id,
                  reviewText: correction.justification,
                  ratingObtained: correction.score,
                  corrected: correction.corrected,
                  flowStatus: stat,
                  instanceId: correction.id,
                  revisions:  assignmentUs.revisions.filter((revision: { correctionId: number; }) => revision.correctionId === correction.id )
                }
              );
          }

          if (recorrections.length !== 0){
            homeworks2.push({
              assignmentNumber: assignmentNumber,
              deadline: deadline,
              startDate: startDate,
              recorrections: recorrections,
              status: status
              
            });}
        }

        return homeworks2;
      } catch (error) {
        // console.error('Error al realizar la solicitud');
        return [];
      }
    };
    
    const currentDate = new Date();
    const pending: HomeworkRecorrection2[] = [];
    const past: HomeworkRecorrection2[] = [];

    fetchData()
      .then((homeworks2) => {
        homeworks2.forEach((homework) => {
          const deadlineString = homework.deadline;
          if (deadlineString !== null){
            const deadlineDate = new Date(deadlineString)

            
            if (currentDate < deadlineDate) {
              

              pending.push(homework);
            } else {
              past.push(homework);
            }
          }
        });

        setPendingHomework(pending);
        setPastHomework(past);
      })
      .catch((error) => {
        // console.error('Error al obtener los datos');
      });
  }, [backendUrl, userId, recorrectionEndpoint, token]);

  const pendingBoxProps: BoxPropsRecorrection2 = {
    type: "pending",
    homeworkList: pendingHomework
  };

  const pastBoxProps: BoxPropsRecorrection2 = {
    type: "past",
    homeworkList: pastHomework
  }


  return (
    <div className="App3">
      <MyNavbar />
      <div className="title-text">Recorrección Etapa 2</div>
      <div className="alerta">
        <div
          className="alert alert-warning alert-dismissible fade show custom-alert"
          role="alert"
        >
          <strong>Recuerda:</strong> Debes recorregir según los criterios de la pauta de recorrección. Si mandas a recorregir es posible que se te baje el puntaje. Puedes enviar cuantas recorrecciones quieras, solo se contará la última que envíes. Si existe algún item que no te hayan evaluado, no es necesario que pidas recorrección, automaticamante se te asignará un ayudante.
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>

        </div>
        <div className="box3">
        <RecorrectionBoxEtapa2 {...pendingBoxProps} />
        <BlockedRecorrectionBoxEtapa2 {...pastBoxProps} />

      </div>
      </div>
      
    </div>
  );
};

export default RecorreccionEtapa2;