import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import { Table, ConfigProvider } from "antd";
import Button from "react-bootstrap/Button";
import type { ColumnsType } from "antd/es/table";
import axios from "axios";
import "react-datetime/css/react-datetime.css";
import "../../styles/formularios.css";
import { useParams } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";

type UserData = {
  id: number;
  email: string;
  studentNumber: string;
  name: string;
  lastName: string;
  assignmentUser: AssignmentUser;
  courseSection: CourseSection;
  role: string;
};

type CourseSection = {
  number: number;
  teacher: string;
};

type AssignmentUser = AssignmentUserItem[];

type AssignmentUserItem = {
  assignment: {
    id: number;
    number: number;
    createdAt: string;
    updatedAt: string | null;
    averageScore: number | null;
    courseSectionId: number;
  };
  attachments: {
    id: number;
    userId: number;
    assignmentScheduleId: number;
    assignmentUserId: number;
    essayFileName: string;
    essayContentType: string | null;
    essayFileSize: number | null;
    url: string;
    essayUpdatedAt: string | null;
    auxName: string | null;
    correction: boolean;
    createdAt: string;
    updatedAt: string | null;
  }[];
};

type RouteParams = {
  sectionParam?: string;
  assignmentNumberParam?: string;
};

const Ensayos: React.FC = () => {
  const [userData, setUserData] = useState<UserData[]>([]);
  const backendUrl = process.env.REACT_APP_API_URL;
  const usersEndpoint = "/users/";
  const { sectionParam, assignmentNumberParam } = useParams<RouteParams>();
  const section = sectionParam ? +sectionParam : 1;
  const assignmentNumber = assignmentNumberParam ? +assignmentNumberParam : 1;
  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);
  const [pageSize, setPageSize] = useState<number>(10);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}${usersEndpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const apiData = response.data;
        const studentsInSection = apiData.data.filter((user: UserData) => {
          return (
            user.role === "STUDENT" &&
            user.courseSection &&
            user.courseSection.number === section &&
            user.assignmentUser &&
            user.assignmentUser.some(
              (assign) =>
                assign.assignment &&
                assign.assignment.number === assignmentNumber
            )
          );
        });
        setUserData(studentsInSection);
      } catch (error) {
        console.error("Error al realizar la solicitud:", error);
      }
    };

    fetchData();
  }, [backendUrl, section, assignmentNumber, token]);

  const handleEditClick = (user: UserData) => {
    if (user.assignmentUser.length > 0) {
      for (const item of user.assignmentUser) {
        if (item.assignment.number === assignmentNumber) {
          if (item.attachments.length > 0) {
            const urlFile = item.attachments[0].url;
            window.open(urlFile, "_blank");
          } else {
            // console.log("no hay datos de attachment");
          }
        }
      }
    }
  };
  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };
  const columns: ColumnsType<UserData> = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
      render: (_: string, user: UserData) => (
        <>
          {user.name} {user.lastName}
        </>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Número Alumno",
      dataIndex: "studentNumber",
      key: "studentNumber",
    },
    {
      title: "Ensayo",
      dataIndex: "collaboratorData",
      key: "collaboratorData",
      render: (_: string, user: UserData) => (
        <>
          <Button
            variant="light"
            onClick={() => handleEditClick(user)}
            disabled={
              user.assignmentUser.length === 0 ||
              user.assignmentUser.some(
                (item) =>
                  item.assignment.number === assignmentNumber &&
                  item.attachments.length === 0
              )
            }
          >
            {user.assignmentUser.length === 0 ||
            user.assignmentUser.some(
              (item) =>
                item.assignment.number === assignmentNumber &&
                item.attachments.length === 0
            )
              ? "Sin Archivo"
              : "Descargar"}
          </Button>
        </>
      ),
    },
  ];

  return (
    <div className="Admin">
      <AdminNavbar />
      <div className="title-text">
        Ensayos {assignmentNumber} Sección {section}
      </div>
      <div className="tabla">
        <ConfigProvider
          theme={{
            token: {
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif`,
              colorIcon: "white",
            },
            components: {
              Table: {
                /* here is your component tokens */
                headerBg: "#072f75",
                headerColor: "#f0f0f0",
                headerSortHoverBg: "#1165f6",
                headerSortActiveBg: "#072f75",
              },
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={userData}
            rowKey={(record) => record.id}
            bordered
            pagination={{
              pageSize: pageSize,
              total: userData.length,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "50"],
              onChange: handleChangePageSize,
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default Ensayos;
