import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BoxProps } from './SharedTypes';
import axios from 'axios';
import { useAuthHeader } from "react-auth-kit";

const HomeworkBox: React.FC<BoxProps> = ({ type, homeworkList }) => {
    const [openAccordion, setOpenAccordion] = useState<string | null>(null);
    const [fileURLs, setFileURLs] = useState<{ [key: string]: string }>({});  // This will store URLs by hw.title
    // No need for a useEffect here since the axios call will be made inside the map function for each homework item.

    const handleAccordionClick = (accordionId: string) => {
        setOpenAccordion((prevOpenAccordion) =>
            prevOpenAccordion === accordionId ? null : accordionId
        );
    };

    const authHeader = useAuthHeader();
    const attachmentInfoEndpoint = `${process.env.REACT_APP_API_URL}/attachments/`;

    return (
        <div className={`homework-box ${type} container `}>
            <h2 style={{ color: '#072f75' }}>
                {type === 'pending' ? 'Próximas Tareas' : 'Tareas Anteriores'}
            </h2>
            {homeworkList.map((hw, index) => {
                const accordionId = `${type}${index}`;
                const isOpen = openAccordion === accordionId;

                // Fetch URL if not already fetched
                if (!fileURLs[hw.title]) {
                    axios.get(`${attachmentInfoEndpoint}${hw.title}`, 
                    { headers: { Authorization: authHeader() } }
                    )
                        .then(response => {
                            setFileURLs(prevFileURLs => ({
                                ...prevFileURLs,
                                [hw.title]: response.data.data.url
                            }));
                        })
                        
                        .catch(error => {
                            // console.error(`Error fetching the file URL for hw.title = ${hw.title} :`, error);
                        });
                }

                return (
                    <div key={index} className="accordion" id={accordionId}>
                        <div className="accordion-item">
                            <h2 className="accordion-header" id={`heading${type}${index}`}>
                                <div className="d-flex align-items-center">
                                    <button
                                        className={`accordion-button ${isOpen ? '' : 'collapsed'}`}
                                        type="button"
                                        onClick={() => handleAccordionClick(accordionId)}
                                    >
                                        <span className="hw-title" style={{ width: '200px' }}> Ensayo {hw.title} </span>
                                        <span className="ml-auto fecha-title">Cerrado: {hw.deadline.toLocaleString()}</span>
                                    </button>
                                </div>
                            </h2>
                            <div
                                id={`collapse${type}${index}`}
                                className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                                aria-labelledby={`heading${type}${index}`}
                            >
                                <div className="accordion-body d-flex">
                                    <a href={hw.details}>Ver Archivo Subido</a>  {/* Use fileURL from state, if not available yet, default to '#' */}
                                    <span className="btn btn-danger mb-2" style={{ cursor: 'not-allowed', marginLeft: '50px' }}>
                                        Tarea Bloqueada {hw.deadline.toLocaleString()}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default HomeworkBox;