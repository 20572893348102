import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CollaboratorNavbar from '../../components/CollaboratorNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Button, Form } from 'antd';
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { Formik, useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from "yup";

interface Attachment2 {
  essayFileName: string;
  url: string;
}

interface Assignment {
  number: number;
}

interface Correction2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number;
  attachment: Attachment2;
}

interface CorrectionsMade2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number;
  attachment: Attachment2;
}

interface AssignmentUser2 {
  assignment: Assignment
  id: number;
  status: string;
  user: {
    name: string;
    lastName: string;
    email: string;
    studentNumber: string;
  };
  corrections: Correction2[];
  correctionsMade: CorrectionsMade2[];
}

interface CorrectionData2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number;
  attachment: Attachment2;
}

interface Data2 {
  id: number;
  collaboratorId: number;
  requesterId: null | number;
  scoreDelta: null | number;
  studentComment: string;
  collaboratorComment: null | string;
  corrected: boolean;
  stage: string;
  assigmentUser: AssignmentUser2;
  correction: CorrectionData2;
}

interface ApiResponse {
  data: Data2[];
}

const AyudanteEtapa2: React.FC = () => {
  const [revisions, setRevisions] = useState<Data2[]>([]);
  const [filterLoading, setFilterLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isDisabled, setIsDisabled] = useState(false);
  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);
  const authUser = useAuthUser();
  const userId = authUser()?.id;

  const fetchData = async () => {
    formik.values.SearchType = 'false';
    setFilterLoading(true);
    try {
      const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/${userId}?stage=SECOND`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response2.data && response2.data.data) {
        const filteredData = response2.data.data.filter((item: Data2) =>
          (item.assigmentUser && item.stage === "SECOND" && item?.correction?.corrected === true && item.correction?.score != -1)
        );
        setRevisions(filteredData);
      }
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
    setFilterLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSubmitForm = async (values: any) => {
    try {
      setFilterLoading(true);
      const extraValue = values.SearchType != '' ? `&corrected=${values.SearchType === 'true' ? true : false}` : '';
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/${userId}?stage=SECOND${extraValue}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.data && response.data.data) {
        const filteredData = response.data.data.filter((item: Data2) =>
          (item.assigmentUser && item.stage === "SECOND" && item?.correction?.corrected === true && item.correction?.score != -1)
        );
        setRevisions(filteredData);
      }
      setFilterLoading(false);
    } catch (error) {
      setFilterLoading(false);
      toast.error(`Error al cargar datos`, {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
      });
    }
  };

  const updateCorrectionData = async (dataId: number, collaboratorComment: string, scoreDelta: number) => {
              try {
                const response = await axios.put(
                  `${process.env.REACT_APP_API_URL}/stages/fourth/${dataId}`,
                  {
                    comment: collaboratorComment,  // Use "comment" instead of "collaboratorComment"
                    scoreDelta,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                // You may want to update the local state with the modified data if needed
                setRevisions((prevRevisions) => {
                  return prevRevisions.map((revision) => {
                    if (dataId === revision.id) {
                      return {
                        ...revision,
                        collaboratorComment: response.data.comment,  // Update with the correct property
                        scoreDelta: response.data.scoreDelta,
                      };
                    }
                    return revision;
                  });
                });
                window.location.reload();

              } catch (error) {
                // console.error('Error updating data:', error);
              }
  };

  const validationSchema = Yup.object({
    SearchType: Yup.string().required('Option is required'),
  });

  const formik = useFormik({
    initialValues: {
      SearchType: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmitForm,
  });

  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };

  return (
        <div className="Admin">
          <CollaboratorNavbar />
          <div className="container">
            <div className="text-center my-4">
              <div className="title-text">Recorreccion Etapa 2 </div>
            </div>
            <div className="rounded bg-white p-2 border border-1 mb-2">
              <Formik
                    initialValues={{
                      SearchType: 'false',
                    }}
                    onSubmit={(e) => { handleSubmitForm(formik.values); }}
                  >
                    <Form className="row g-2 align-items-center">
                      {/* Dropdown */}
                      <div className="col-auto">
                          <select
                            id="SearchType"
                            name="SearchType"
                            className="form-select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.SearchType}
                          >
                            <option value="false">No corregidos</option>
                            <option value="true">Corregidos</option>
                          </select>
                      </div>

                      {/* Submit button */}
                      <div className="col-auto">
                        <Button type='primary' onClick={(e) => handleSubmitForm(formik.values)}>
                          Filtrar
                          {filterLoading && (
                            <span className="spinner-border spinner-border-sm ms-2" aria-hidden="true"></span>
                          )  
                          }
                        </Button>
                      </div>
                      <Button
                        disabled={isDisabled}
                        className="w-auto px-4 py-auto btn-secondary"
                        onClick={fetchData}
                      >
                        Resetear filtros
                        {filterLoading && (
                         <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        )  
                        }
                      </Button>
                    </Form>
              </Formik>
            </div>
            <Table dataSource={revisions} rowKey="id">
              <Table.Column
                title="Numero de Ensayo"
                dataIndex={["assigmentUser", "assignment", "number"]}
                key="assigmentUser.assignment.number"
              />
              <Table.Column title="Corregido?" dataIndex="corrected" key="corrected" render={corrected => corrected ? 'Si' : 'No'} />
              <Table.Column
                title="Email Estudiante"
                dataIndex={["assigmentUser", "user", "email"]}
                key="assigmentUser.user.email"
              />
              <Table.Column
                title="Puntaje corrección 2 Pre Recorrección"
                key="correctionsSum"
                render={(_, record: Data2) => {
                  return <span>{record?.correction?.score}</span>;
                }}
              />
              <Table.Column
                title="Puntaje corrección Post Recorrección (Suma)"
                  key="totalScore"
                  render={(_, record: Data2) => {
                    const sumScoreDelta = record?.scoreDelta || 0;
                    return <span>{record?.scoreDelta != null ? sumScoreDelta + record?.correction?.score : '--'}</span>;
                }}
              /> 
              <Table.Column
                title="Attachment URL"
                dataIndex={["assigmentUser", "corrections", 0, "attachment", "url"]}
                key="assigmentUser.corrections.0.attachment.url"
                render={(url: string) => (
                  <a href={url} target="_blank" rel="noopener noreferrer">
                    Open URL
                  </a>
                )}
              />
              <Table.Column 
                title="Accion" 
                  key="correction" 
                  render={(_, record: Data2) => (
                    <>
                    <Button type="primary" href={`/recorregir/${record.id}`}>
                      Recorregir  
                    </Button>
                    </>
                )}
              />
            </Table>
    </div>
  </div>
  );
};

export default AyudanteEtapa2; 
