import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, ConfigProvider, Tag, Modal } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useAuthHeader } from "react-auth-kit";

type RecorrectionSummaryData = {
  id: string;
  collaboratorData: string;
  courseSection: string;
  reason: string;
  essay: string;
  state: string;
  stage: string;
  essayNumber: string;
  scoreDelta: number | string;
  score: number | string;
  finalScore: number | string;
  collaboratorComment: string;
  studentComment: string;
  studentInfo: string;
};
type filterData = {
  text: string;
  value: string | number;
};

const TableRecorrectionsView: React.FC = () => {
  const [data, setData] = useState<RecorrectionSummaryData[]>([]);
  const [sectionFilter, setSectionFilter] = useState<filterData[]>([]);
  const [essayFilter, setEssayFilter] = useState<filterData[]>([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const backendUrl = process.env.REACT_APP_API_URL;
  const summaryEndpoint = "/stages/summarize";
  const [pageSize, setPageSize] = useState<number>(10);
  const [comment, setComment] = useState("");
  const authHeader = useAuthHeader();
  const mapName: { [key: string]: string } = {
    Pendiente: "volcano",
    Corregido: "green",
    SECOND: "2",
    THIRD: "3",
    ALL: "Todas",
    NC: "No corregido",
    MU: "Datos de autor",
    OUT: "out",
    DELIVERED: "Solicitud de reajuste",
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal = (commentValue: string) => {
    setComment(commentValue);
    setIsModalOpen(true);
  };

  const handleData = async () => {
    try {
      const response = await axios.get(`${backendUrl}${summaryEndpoint}`, {
        headers: {
          Authorization: authHeader(),
        },
      });
      const tableData: RecorrectionSummaryData[] = response.data.data.map(
        (summary: any, index: number) => {
          const assignmentUser = summary.assigmentUser;
          const collaborator = summary.collaborator;
          const modelAssociatedScore = summary.stage === 'SECOND' ? summary?.correction?.score : summary?.review?.correctionScore;
          const systemGeneratedNC =
            summary.studentComment === "Generado por sistema, es NC";
          const systemGeneratedMU =
            summary.studentComment === "Generado por sistema, es MU (datos de autor)";
          if (collaborator === null) {
            return {
              id: `${index}`,
              collaboratorData: "--",
              studentInfo: `${assignmentUser.user.email}`,
              courseSection: `${assignmentUser.assignment.courseSection.number}`,
              essayNumber: `${assignmentUser.assignment.number}`,
              // essay: `${assignmentUser.attachments[0].url}`,
              state: "Pendiente",
              stage: mapName[summary.stage as keyof typeof mapName],
              reason: mapName[assignmentUser.status as keyof typeof mapName],
              scoreDelta: "--",
              finalScore: assignmentUser.finalScore != null ? assignmentUser.finalScore : "--",
              score: modelAssociatedScore != null ? modelAssociatedScore : '--',
              collaboratorComment: "No realizado",
              studentComment:
                systemGeneratedNC || systemGeneratedMU
                  ? "Generado por el sistema"
                  : summary.studentComment,
            };
          } else {
            return {
              id: `${index}`,
              collaboratorData: summary.collaborator.name,
              studentInfo: `${assignmentUser.user.email}`,
              courseSection: `${assignmentUser.assignment.courseSection.number}`,
              essayNumber: `${assignmentUser.assignment.number}`,
              // essay: `${assignmentUser.attachments[0].url}`,
              state: summary.corrected ? "Corregido" : "Pendiente",
              stage: mapName[summary.stage as keyof typeof mapName],
              //assignmentUser.status,
              reason:   mapName[assignmentUser.status as keyof typeof mapName],
              scoreDelta: summary?.scoreDelta != null ? summary.scoreDelta : '--',
              finalScore: assignmentUser.finalScore != null ? assignmentUser.finalScore : "--",
              score: modelAssociatedScore != null ? modelAssociatedScore : '--',
              collaboratorComment:
                summary.collaboratorComment !== null
                  ? summary.collaboratorComment
                  : "No realizado",
              studentComment:
                systemGeneratedNC || systemGeneratedMU
                  ? "Generado por el sistema"
                  : summary.studentComment,
            };
          }
        }
      );
      const filteredData = tableData.filter((data) => data.reason !== "out");
      const sectionDataFilter = Array.from(
        new Set(filteredData.map((item) => item.courseSection))
      )
        .sort((a: string, b: string) => Number(a) - Number(b))
        .map((x) => {
          return { text: x.toString(), value: x.toString() };
        });
      const essayDataFilter = Array.from(
        new Set(filteredData.map((item) => item.essayNumber))
      )
        .sort((a: string, b: string) => Number(a) - Number(b))
        .map((x) => {
          return { text: x.toString(), value: x.toString() };
        });
      setEssayFilter(essayDataFilter);
      setSectionFilter(sectionDataFilter);
      setData(filteredData);
    } catch (error) {
      console.error("Ocurrió un error inesperado:", error);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const columns: ColumnsType<RecorrectionSummaryData> = [
    {
      title: "Ayudante",
      dataIndex: "collaboratorData",
      key: "collaboratorData",
    },
    {
      title: "Alumno",
      dataIndex: "studentInfo",
      key: "studentInfo",
    },
    {
      dataIndex: "essayNumber",
      title: "N° Ensayo",
      key: "essayNumber",
      filters: essayFilter,
      onFilter: (value, record: RecorrectionSummaryData) =>
        record.essayNumber === String(value),
    },

    {
      title: "Sección",
      dataIndex: "courseSection",
      key: "courseSection",
      sorter: (a: RecorrectionSummaryData, b: RecorrectionSummaryData) =>
        Number(a.courseSection) - Number(b.courseSection),
      filters: sectionFilter,
      onFilter: (value, record: RecorrectionSummaryData) =>
        record.courseSection.indexOf(String(value)) === 0,
    },
    {
      title: "Etapa",
      dataIndex: "stage",
      key: "stage",
      filters: [
        { text: "Segunda", value: "2" },
        { text: "Tercera", value: "3" },
        { text: "Todas", value: "Todas" },
      ],
      onFilter: (value, record: RecorrectionSummaryData) =>
        record.stage === String(value),
    },
    {
      title: "Estado",
      dataIndex: "state",
      key: "state",
      filters: [
        { text: "Corregido", value: "Corregido" },
        { text: "Pendiente", value: "Pendiente" },
      ],
      onFilter: (value, record: RecorrectionSummaryData) =>
        record.state === String(value),
      render: (state: string) => (
        <Tag color={mapName[state as keyof typeof mapName]}>{state}</Tag>
      ),
    },

    { title: "Razón", dataIndex: "reason", key: "reason" },
    // {
    //   title: "Ensayo",
    //   dataIndex: "essay",
    //   key: "essay",
    //   render: (essay: string) => (
    //     <a href={essay} target="_blank" rel="noreferrer">
    //       Ver ensayo
    //     </a>
    //   ),
    // },
    {
      title: "Puntaje Etapa",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Reajuste Etapa",
      dataIndex: "scoreDelta",
      key: "scoreDelta",
    },
    {
      title: "Puntaje Ensayo",
      dataIndex: "finalScore",
      key: "finalScore",
    },
    {
      title: "Comentario del ayudante",
      dataIndex: "collaboratorComment",
      key: "collaboratorComment",
      render: (collaboratorComment: string) => (
        <>
          <a
            onClick={() => showModal(collaboratorComment)}
            style={{ cursor: "pointer" }}
            className="text-decoration-underline"
          >
            Ver Comentario
          </a>
          <Modal
            title="Comentario ayudante"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>{comment}</p>
          </Modal>
        </>
      ),
    },
    {
      title: "Comentario del estudiante",
      dataIndex: "studentComment",
      key: "studentComment",
      render: (studentComment: string) => (
        <>
          <a
            onClick={() => showModal(studentComment)}
            style={{ cursor: "pointer" }}
            className="text-decoration-underline"
          >
            Ver Comentario
          </a>
          <Modal
            title="Comentario"
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <p>{comment}</p>
          </Modal>
        </>
      ),
    },
  ];

  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };

  return (
    <div className="Admin">
      <AdminNavbar />
      <div className="container">
      <div className="text-center my-4">
        <div className="title-text">Recorrecciones</div>
      </div>
      <div className="table-responsive">
        <ConfigProvider
          theme={{
            token: {
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif`,
              colorIcon: "white",
            },
            components: {
              Table: {
                /* here is your component tokens */
                headerBg: "#072f75",
                headerColor: "#f0f0f0",
                headerSortHoverBg: "#1165f6",
                headerSortActiveBg: "#072f75",
              },
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            bordered
            pagination={{
              pageSize: pageSize,
              total: data.length,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "50"],
              onChange: handleChangePageSize,
            }}
          />
        </ConfigProvider>
      </div>
</div>

    </div>
  );
};

export default TableRecorrectionsView;
