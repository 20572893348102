import React, { useState } from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../styles/recoverPassword.css";
import RoleNavBar from "../components/RoleNavbar";

type RecoverPasswordValues = {
  email: string;
  studentNumber: string;
};

export default function RecoverPassword() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const initialValues: RecoverPasswordValues = {
    email: "",
    studentNumber: ""
  };
  const passwordRecoverEndpoint = `${process.env.REACT_APP_API_URL}/users/restore/`;

  const setSpinners = (status: boolean) => {
    setIsDisabled(status);
    setLoading(status);
  }  

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Correo electrónico inválido")
      .test(
        "is-allowed-email",
        "Solo se permiten correos UC",
        (value) => {
          if (!value) return true;
          return value.endsWith("@example.com") || value.endsWith("@uc.cl") || value.endsWith("@gmail.com");
        }
      )
      .required("El correo electrónico es obligatorio"),
    studentNumber: Yup.string().required("El número de alumno es obligatorio")
  });

  const handleSubmit = async (
    values: RecoverPasswordValues,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<RecoverPasswordValues>
    ) => {
    setSpinners(true);
    try {
      const response = await axios.post(
        passwordRecoverEndpoint,
        values,
        { withCredentials: false }
      );
      setSpinners(false);
      toast.success("Contraseña enviada exitosamente, Revisa tu correo", {
        position: "bottom-right",
        onClose: () => {
          resetForm();
          navigate("/logOut");
        },
      });
    } catch (error) {
      setSpinners(false);
      setErrors({ email: "Error al recuperar contraseña" });
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 404) {
          setErrors({ email: "Usuario no encontrado, revisa los valores ingresados" });
        }
        if (status === 400) {
          setErrors({ email: "Error al recuperar contraseña" });
        }
      }
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className="App">
      <RoleNavBar />
      <div className="password-recover-container mt-5 px-2">
        <div className="password-recover-title-text1">Recuperación de contraseña</div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="password-recover-form">
              <div className="password-recover-form-group">
                <label htmlFor="email">Email:</label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="password-recover-form-control"
                  placeholder="Ingresa tu email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="password-recover-error-message"
                />
              </div>
              <div className="password-recover-form-group">
                <label htmlFor="email">Numero de Alumno:</label>
                <Field
                  type="text"
                  id="studentNumber"
                  name="studentNumber"
                  className="password-recover-form-control"
                  placeholder="Ingresa tu número de alumno"
                />
                <ErrorMessage
                  name="studentNumber"
                  component="div"
                  className="password-recover-error-message"
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#072f75",
                    border: "none",
                  }}
                  className="btn password-recover-btn"
                  disabled={isSubmitting}
                >
                  Solicitar contraseña
                  { loading ? (
                    <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                  ) : (
                    <></>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
