import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, ConfigProvider } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useAuthHeader } from "react-auth-kit";

type summaryData = {
  id: string;
  courseSectionId: string;
  essayNumber: string;
  deliveredE2: number;
  notDeliveredE2: number;
  linkE2: string;
  deliveredE3: number;
  notDeliveredE3: number;
  linkE3: string;
};
type filterData = {
  text: string;
  value: string | number;
};

const TableCorrectionsView: React.FC = () => {
  const [data, setData] = useState<summaryData[]>([]);
  const [sectionFilter, setSectionFilter] = useState<filterData[]>([]);
  const [essayFilter, setEssayFilter] = useState<filterData[]>([]);
  const backendUrl = process.env.REACT_APP_API_URL;
  const summaryEndpoint = "/stages/summarize/admin";
  const [pageSize, setPageSize] = useState<number>(10);
  const authHeader = useAuthHeader();

  const handleData = async () => {
    try {
      const response = await axios.get(`${backendUrl}${summaryEndpoint}`, {
        headers: {
          Authorization: authHeader(),
        },
      });
      const tableData: summaryData[] = response.data.data.map(
        (summary: any, index: number) => {
          return {
            id: `${index}`,
            courseSectionId: `${summary.section}`,
            essayNumber: `${summary.number}`,
            deliveredE2: summary.delivered_e2,
            notDeliveredE2: summary.notDelivered_e2,
            deliveredE3: summary.delivered_e3,
            notDeliveredE3: summary.notDelivered_e3,
            linkE3: `/correcciones/3/${summary.section}/${summary.number}`,
            linkE2: `/correcciones/2/${summary.section}/${summary.number}`,
          };
        }
      );
      const sectionDataFilter = Array.from(
        new Set(tableData.map((item) => item.courseSectionId))
      )
        .sort((a: string, b: string) => Number(a) - Number(b))
        .map((x) => {
          return { text: x.toString(), value: x.toString() };
        });
      const summaryDataFilter = Array.from(
        new Set(tableData.map((item) => item.essayNumber))
      )
        .sort((a: string, b: string) => Number(a) - Number(b))
        .map((x) => {
          return { text: x.toString(), value: x.toString() };
        });

      setEssayFilter(summaryDataFilter);
      setSectionFilter(sectionDataFilter);
      setData(tableData);
    } catch (error) {
      console.error("Ocurrió un error inesperado:", error);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const columns: ColumnsType<summaryData> = [
    {
      title: "Sección",
      dataIndex: "courseSectionId",
      key: "courseSectionId",
      sorter: (a: summaryData, b: summaryData) =>
        Number(a.courseSectionId) - Number(b.courseSectionId),
      filters: sectionFilter,
      onFilter: (value, record: summaryData) =>
        record.courseSectionId.indexOf(String(value)) === 0,
    },
    {
      title: "N° Ensayo",
      dataIndex: "essayNumber",
      key: "essayNumber",
      sorter: (a: summaryData, b: summaryData) =>
        Number(a.essayNumber) - Number(b.essayNumber),
      filters: essayFilter,
      onFilter: (value, record: summaryData) =>
        record.essayNumber === String(value),
    },
    {
      title: "Etapa 2",
      children: [
        {
          title: "Entregas realizadas",
          dataIndex: "deliveredE2",
          key: "deliveredE2",
        },
        {
          title: "Entregas pendientes",
          dataIndex: "notDeliveredE2",
          key: "notDeliveredE2",
        },
        {
          title: "Acciones",
          dataIndex: "linkE2",
          key: "linkE2",
          render: (linkE2: string) => (
            <a href={linkE2} target="_blank" rel="noreferrer">
              Ver correcciones
            </a>
          ),
        },
      ],
    },
    {
      title: "Etapa 3",
      children: [
        {
          title: "Entregas realizadas",
          dataIndex: "deliveredE3",
          key: "deliveredE3",
        },
        {
          title: "Entregas pendientes",
          dataIndex: "notDeliveredE3",
          key: "notDeliveredE3",
        },
        {
          title: "Acciones",
          dataIndex: "linkE3",
          key: "linkE3",
          render: (linkE3: string) => (
            <a href={linkE3} target="_blank" rel="noreferrer">
              Ver Reseñas
            </a>
          ),
        },
      ],
    },
  ];

  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };

  return (
    <div className="Admin">
      <AdminNavbar />
      <div className="container">
        <div className="text-center my-4">
          <div className="title-text">Resumen de las etapas 2 y 3</div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif`,
              colorIcon: "white",
            },
            components: {
              Table: {
                /* here is your component tokens */
                headerBg: "#072f75",
                headerColor: "#f0f0f0",
                headerSortHoverBg: "#1165f6",
                headerSortActiveBg: "#072f75",
              },
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            bordered
            pagination={{
              pageSize: pageSize,
              total: data.length,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "50"],
              onChange: handleChangePageSize,
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default TableCorrectionsView;
