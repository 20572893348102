import axios, { AxiosRequestConfig, AxiosPromise } from 'axios';

export class RequestBuilder {
  private readonly backApi: string;
  private readonly authToken: string | null;
  private readonly headers: Record<string, string>;

  constructor(authToken: string | null = null) {
    this.backApi = process.env.REACT_APP_API_URL || '';
    this.authToken = authToken;
    this.headers = {
        'Origin': process.env.REACT_APP_URL || ''
    };

    // Add Authorization header if authToken is provided
    if (this.authToken) {
      this.headers['Authorization'] = this.authToken;
    }
  }

  static new(authToken?: string): RequestBuilder {
    return new RequestBuilder(authToken);
  }

  setHeader(key: string, value: string): RequestBuilder {
    this.headers[key] = value;
    return this;
  }

  async get(path: string){
    return this.makeRequest('GET', path);
  }

  async post(path: string, data: any){
    return this.makeRequest('POST', path, data);
  }

  async put(path: string, data: any){
    return this.makeRequest('PUT', path, data);
  }

  private async makeRequest(method: string, path: string, data?: any){
    const url = `${this.backApi}${path}`;

    const axiosConfig: AxiosRequestConfig = {
      method: method,
      url: url,
      headers: { ...this.headers },
    };

    // Add data for POST or PUT requests
    if (['POST', 'PUT'].includes(method.toUpperCase()) && data) {
      axiosConfig.data = data;
    }

    // console.log(axiosConfig);

    return await axios(axiosConfig);
  }
}