import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CollaboratorNavbar from '../../components/CollaboratorNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { useParams } from "react-router-dom";
import FormRecorrectionModal from '../../components/FormRecorrectionModal';
import { Container } from 'react-bootstrap';
import { toast } from 'react-toastify';

type RecorrectionParams = {
  id?: string;
};

const RecorrectionSite: React.FC = () => {
    const siteParams = useParams<RecorrectionParams>();
    const [modelAssociated, setModelAssociated] = useState<any>()
    const authHeader = useAuthHeader();
    const token = authHeader().slice(7);
    const authUser = useAuthUser();
    const userId = authUser()?.id;

    const updateCorrectionData = async (collaboratorComment: string, scoreDelta: number) => {
        try {
            await axios.put(
                `${process.env.REACT_APP_API_URL}/stages/fourth/${siteParams.id}`,
                {
                comment: collaboratorComment,  // Use "comment" instead of "collaboratorComment"
                scoreDelta: scoreDelta,
                },
                {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                }
            );            
            toast.success('Corrección entregada', {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            modelAssociated.stage === "SECOND" ? 
            window.location.href = '/recorregir-etapa2' :
            window.location.href = '/recorregir-etapa3';
        } catch (error) {
            toast.error(`Error al corregir el ensayo`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
        }
    };

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/${userId}/${siteParams.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          const datas = response.data.data;
          setModelAssociated(datas);
        } catch (error) {
          // console.error('Error fetching data:', error);
        }
      };
      fetchData();
    }, [])

    return (
        <div className="Admin">
          <CollaboratorNavbar />
            <Container className="mt-3 col-lg-8 ">
                <div className="justify-content-center rounded custom-bg-gray">
                    <div className=" my-4 p-2">
                        <div className="title-text py-4 mb-0">Recorreccion Etapa {modelAssociated ? (modelAssociated.stage === "SECOND" ? '2' : '3') : ''} </div>
                        {
                          modelAssociated ? 
                            modelAssociated.stage === "SECOND" ?  
                              <FormRecorrectionModal
                                scoreOfStage={modelAssociated ? modelAssociated.correction.score : 0}
                                stage={2}
                                studentComment={modelAssociated ? modelAssociated.correction.justification : '--'}
                                requestComment={modelAssociated ? modelAssociated.studentComment : '--'}
                                collaboratorComment={modelAssociated ? modelAssociated.collaboratorComment : '--'}
                                scoreDelta={modelAssociated ? modelAssociated.scoreDelta : 0}
                                update={updateCorrectionData}
                                url={modelAssociated ? modelAssociated.correction.attachment.url : '-'}
                              />
                            :
                            <>
                              <FormRecorrectionModal
                                scoreOfStage={modelAssociated ? modelAssociated?.review?.correctionScore : 0}
                                stage={3}
                                studentComment={modelAssociated ? modelAssociated?.review?.studentComment || 'No existe comentario entregado, posiblemente sea un ensayo que quedo OUT' : '--'}
                                requestComment={modelAssociated ? modelAssociated?.studentComment || 'No existe comentario entregado, posiblemente sea un ensayo que quedo OUT' : '--'}
                                collaboratorComment={modelAssociated ? modelAssociated?.collaboratorComment : '--'}
                                stageTwoCorrection={modelAssociated ? modelAssociated?.review?.correction?.justification || 'No existe comentario entregado, posiblemente sea un ensayo que quedo OUT' : '--'}
                                scoreDelta={modelAssociated ? modelAssociated.scoreDelta : 0}
                                update={updateCorrectionData}
                                url={modelAssociated ? modelAssociated?.review?.correction?.originAssignmentUser?.attachments[0].url : '-'}
                              />                            
                            </>
                            
                            : 
                            <>
                              <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                            </>
                        }
                    </div>  
                </div>
            </Container>
        </div>
    );
};

export default RecorrectionSite;