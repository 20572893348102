import React, { useEffect, useState } from 'react'
import CollaboratorNavbar from '../../components/CollaboratorNavbar'
import { Button, Card, Col, Divider, Row } from 'antd'
import CheckIcon from '../../components/CheckIcon'
import axios from 'axios'
import { useAuthHeader, useAuthUser } from 'react-auth-kit'
import XIcon from '../../components/XIcon'

const SummaryCollaborator = () => {  
    const authHeader = useAuthHeader();
    const token = authHeader().slice(7);
    const authUser = useAuthUser();
    const userId = authUser()?.id;

    const [data, setData] = useState<any>();

    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/revisions/status/${userId}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            const responseData = response.data;
            setData(responseData.data);
          } catch (error) {
            // console.log(error);
            // console.error('Error fetching data:', error);
          }
        };
        
        fetchData();
    }, []); // Removed unused dependencies

  return (
    <>
    <div className="Admin">
        <CollaboratorNavbar />
        <div className="container">
            <div className="text-center my-4">
                <div className="title-text">Resumen</div>
            </div>
            <div className='container'>
                <Row className='justify-content-center'>
                    <Col xs={12} md={6} className='m-1'>
                        <Card 
                            title="Etapa 2 Irregular" 
                            bordered={true}     
                            className="w-100"  
                        >
                            { data ?
                               <>
                                <p>Pendientes: {data.irregularSecond}</p>
                                <p>Estado: {data.irregularSecond > 0 ? <XIcon /> : <CheckIcon /> }</p>
                               </>  
                            : <><span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span></>
                            }
                            <Divider></Divider>
                            <Button type="primary" href='/etapa2' size={'large'} className='w-100'>
                                Revisar
                            </Button>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} className='m-1'>
                        <Card 
                            title="Etapa 3 Irregular" 
                            bordered={true} 
                            className="w-100"     
                        >
                            { data ?
                               <>
                                <p>Pendientes: {data.irregularThird}</p>
                                <p>Estado: {data.irregularThird > 0 ? <XIcon /> : <CheckIcon /> }</p>
                               </>  
                            : <><span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span></>
                            }
                            <Divider></Divider>
                            <Button type="primary" href='/etapa3' size={'large'} className='w-100'>
                                Revisar
                            </Button>
                        </Card>
                    </Col>
                </Row>
                <Row className='justify-content-center mt-2'>
                    <Col xs={12} md={6} className='m-1'>
                        <Card 
                            title="Etapa 2 Regular" 
                            bordered={true} 
                            className="w-100"
                        >
                            { data ?
                               <>
                                <p>Pendientes: {data.regularSecond}</p>
                                <p>Estado: {data.regularSecond > 0 ? <XIcon /> : <CheckIcon /> }</p>
                               </>  
                            : <><span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span></>
                            }
                            <Divider></Divider>
                            <Button type="primary" href='/recorregir-etapa2' size={'large'} className='w-100'>
                                Revisar
                            </Button>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} className='m-1'>
                        <Card 
                            title="Etapa 3 Regular" 
                            bordered={true}  
                            className="w-100"   
                        >
                            { data ?
                               <>
                                <p>Pendientes: {data.regularThird}</p>
                                <p>Estado: {data.regularThird > 0 ? <XIcon /> : <CheckIcon /> }</p>
                               </>  
                            : <><span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span></>
                            }
                            <Divider></Divider>
                            <Button type="primary" href='/recorregir-etapa3' size={'large'} className='w-100'>
                                Revisar
                            </Button>
                        </Card>
                    </Col>
                </Row>
                
            </div>
          </div>
    </div>
    </>
  )
}

export default SummaryCollaborator