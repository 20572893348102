import React, { useState } from "react";
import { Table, Modal, Tag, ConfigProvider } from "antd";
import "../styles/formularios.css";

const { Column, ColumnGroup } = Table;

export type CorrectionData = {
  id: string;
  correctorName: string;
  correctorData: string;
  correctedName: string;
  correctedData: string;
  score: number | string;
  comment: string;
  essay: string;
  state: string;
};
type CorrectionOverallProps = {
  data: CorrectionData[];
};
export default function CorrectionsStage2(props: CorrectionOverallProps) {
  const mapName: { [key: string]: string } = {
    Pendiente: "volcano",
    Corregido: "green",
    OUT: "purple",
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [pageSize, setPageSize] = useState<number>(10);
  const showModal = (commentValue: string) => {
    setComment(commentValue);
    setIsModalOpen(true);
  };
  const getLastName = (fullName: string) => {
    const nameParts = fullName.split(" ");
    nameParts.shift();
    return nameParts.join(" ");
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif`,
          colorIcon: "white",
        },
        components: {
          Table: {
            /* here is your component tokens */
            headerBg: "#072f75",
            headerColor: "#f0f0f0",
            headerSortHoverBg: "#1165f6",
            headerSortActiveBg: "#072f75",
          },
        },
      }}
    >
      <Table
        dataSource={props.data}
        bordered
        rowKey={(record) => record.id}
        className="tabla"
        pagination={{
          pageSize: pageSize,
          total: props.data.length,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50"],
          onChange: handleChangePageSize,
        }}
      >
        <ColumnGroup title="Alumno Corrector">
          <Column
            title="Nombre"
            dataIndex="correctorName"
            key="correctorName"
            sorter={(a: any, b: any) =>
              getLastName(a.correctorName).localeCompare(
                getLastName(b.correctorName)
              )
            }
          />
          <Column title="Datos" dataIndex="correctorData" key="correctorData" />
        </ColumnGroup>
        <ColumnGroup title="Alumno Corregido">
          <Column
            title="Nombre"
            dataIndex="correctedName"
            key="correctedName"
            sorter={(a: any, b: any) =>
              getLastName(a.correctedName).localeCompare(
                getLastName(b.correctedName)
              )
            }
          />
          <Column title="Datos" dataIndex="correctedData" key="correctedData" />
        </ColumnGroup>
        <Column
          title="Estado"
          dataIndex="state"
          key="state"
          render={(state: string) => (
            <Tag color={mapName[state as keyof typeof mapName]}>{state}</Tag>
          )}
          filters={[
            { text: "Corregido", value: "Corregido" },
            { text: "Pendiente", value: "Pendiente" },
            { text: "OUT", value: "OUT" },
          ]}
          onFilter={(value, record: CorrectionData) =>
            record.state.indexOf(String(value)) === 0
          }
        />
        <Column title="Puntaje" dataIndex="score" key="score" />
        <Column
          title="Comentario"
          dataIndex="comment"
          key="comment"
          render={(c: string) => (
            <>
              <a
                onClick={() => showModal(c)}
                style={{ cursor: "pointer" }}
                className="text-decoration-underline"
              >
                Ver Comentario
              </a>
              <Modal
                title="Comentario"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <p>{comment}</p>
              </Modal>
            </>
          )}
        />
        <Column
          title="Ensayo"
          dataIndex="essay"
          key="essay"
          render={(e: string) => (
            <>
              <a href={e} target="_blank" rel="noopener noreferrer">
                Descargar ensayo
              </a>
            </>
          )}
        />
      </Table>
    </ConfigProvider>
  );
}
