import React, { useState, useEffect } from 'react';
import MyNavbar from "../../components/NavBar";
import { HomeworkRecorrection3, BoxPropsRecorrection3, Recorrection3 } from "../../components/SharedTypes";
import RecorrectionBoxEtapa3 from "../../components/RecorrectionBoxEtapa3";
import BlockedRecorrectionBoxEtapa3 from "../../components/BlockedRecorrectionBoxEtapa3";
import axios from 'axios';
import "../../styles/etapa3.css";
import "../../styles/recorreccion3.css";
import { useAuthHeader, useAuthUser } from "react-auth-kit";



type ApiDataItem = {
  number: number;
  assignmentSchedules: AssignmentSchedule[];
  assignmentUsers: AssignmentUser[];
};

type AssignmentUser = {
  reviewsRecieved?: any;
  currentAssignmentUser?: any;
  revisions?: any;
  id: number;
  status: string;
  correctionReviews: Reviews[];
};

type Reviews = {
  currentAssignmentUser?: any;
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  correctionScore: number;
  studentComment: string | null;
  correction: Correction;
  InversedAssignmentUserId?: number;
  InversedAssignmentUser?: any;
};

type Correction = {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  score: number;
  containsAuthorData: boolean;
  justification: string | null;
  comments: string | null;
};

type AssignmentSchedule = {
  id: number;
  stage: string;
  status: string;
  startDate: string;
  endDate: string;
};


const RecorreccionEtapa3: React.FC = () => {
  const [pendingHomework, setPendingHomework] = useState<HomeworkRecorrection3[]>([]);
  const [pastHomework, setPastHomework] = useState<HomeworkRecorrection3[]>([]);  

  const backendUrl = process.env.REACT_APP_API_URL;
  const authUser = useAuthUser();
  const userId = authUser()?.id;
  const recorrectionEndpoint = `/stages/fourth/data/third/${userId}?corrections=true&reviews=true`
  // const recorrectionEndpointSecond = `/stages/fourth/data/third/second/${userId}?corrections=true&reviews=true`
  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}${recorrectionEndpoint}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // const responseSecond = await axios.get(`${backendUrl}${recorrectionEndpointSecond}`, {
        //   headers: {
        //     Authorization: `Bearer ${token}`,
        //   },
        // });
        // const responseSecondData = responseSecond.data
        // console.log(responseSecondData)
        const responseData = response.data;
        // console.log(responseData);
        const apiData: ApiDataItem[] = responseData.data; 
        const homeworks2: HomeworkRecorrection3[] = [];

        for (const assignment of apiData) {
          const assignmentNumber = String(assignment.number);
          const schedules = assignment.assignmentSchedules[0];
          const startDate = schedules.startDate;
          const deadline = schedules.endDate;
          const assignmentUsers = assignment.assignmentUsers;
          const status = schedules.status;
        
          const recorrections: Recorrection3[] = [];
        
          for (const reviewAssignment of assignmentUsers) {
            const assignmentUserId = reviewAssignment.id;
            const review = reviewAssignment.correctionReviews[0];
            const correction = review.correction;
            const reviewText = correction.justification;
            const ratingGiven = correction.score;
            const correctedCorrection = correction.corrected;
            const ratingObtained = review.correctionScore;
            const correctedReview = review.corrected;

            // console.log(reviewAssignment.reviewsRecieved[0].InversedAssignmentUser.status)

            // console.log(reviewAssignment.reviewsRecieved[0].InversedAssignmentUser.status)
            recorrections.push({
                assignmentUserId: assignmentUserId,
                reviewText: reviewText,
                ratingGiven: ratingGiven,
                ratingObtained: ratingObtained,
                correctedReview: correctedReview,
                correctedCorrection: correctedCorrection,
                // flowStatus:reviewAssignment.reviewsRecieved[0].InversedAssignmentUser.status,
                flowStatus: review.InversedAssignmentUser.status,
                // flowStatus: reviewAssignment.status,
                instanceId: review.id,
                currentAssignmentUserId: review.InversedAssignmentUserId,
                currentAssignmentUser: review.InversedAssignmentUser ,
                revisions:  review?.InversedAssignmentUser?.revisions.filter((revision: { reviewId: number; }) => 
                  revision.reviewId === review.id 
                )
            });
            // console.log(recorrections)
          }

          if (recorrections.length !== 0){
            homeworks2.push({
              assignmentNumber: assignmentNumber,
              deadline: deadline,
              startDate: startDate,
              recorrections: recorrections,
              status: status,
            });
          }
          
        }

        return homeworks2;
      } catch (error) {
        return [];
      }
    };
    
    const currentDate = new Date();
    const pending: HomeworkRecorrection3[] = [];
    const past: HomeworkRecorrection3[] = [];

    fetchData()
      .then((homeworks2) => {
        homeworks2.forEach((homework) => {
          const deadlineString = homework.deadline;
          if (deadlineString !== null){
            const deadlineDate = new Date(deadlineString)
            
            if (currentDate < deadlineDate) {
              

              pending.push(homework);
            } else {
              past.push(homework);
            }
          }
        });

        setPendingHomework(pending);
        setPastHomework(past);
      })
      .catch((error) => {
        return;
      });
  }, [backendUrl, userId, recorrectionEndpoint, token]);

  const pendingBoxProps: BoxPropsRecorrection3 = {
    type: "pending",
    homeworkList: pendingHomework
  };

  const pastBoxProps: BoxPropsRecorrection3 = {
    type: "past",
    homeworkList: pastHomework
  }


  return (
    <div className="App3">
      <MyNavbar />
      <div className="title-text">Recorrección Etapa 3</div>
      <div className="alerta">
        <div
          className="alert alert-warning alert-dismissible fade show custom-alert"
          role="alert"
        >
          <strong>Recuerda:</strong> Debes recorregir según los criterios de la pauta de recorrección. Si mandas a recorregir es posible que se te baje el puntaje. Puedes enviar cuantas recorrecciones quieras, solo se contará la última que envíes. Si existe algún item que no te hayan evaluado, no es necesario que pidas recorrección, automaticamante se te asignará un ayudante.
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>

        </div>
        <div className="box3">
        <RecorrectionBoxEtapa3 {...pendingBoxProps} />
        <BlockedRecorrectionBoxEtapa3 {...pastBoxProps} />

      </div>
      </div>
      
    </div>
  );
};

export default RecorreccionEtapa3;