import React, { useState, useEffect } from 'react';    
import { Formik, Form, Field, FieldArray, ErrorMessage } from 'formik'; // borrar FieldArray si no se usa
import * as Yup from 'yup'; 
import axios from "axios"; 
import { Modal, Button, FormCheck } from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/formularios.css";
import AdminNavbar from '../../components/AdminNavbar';
import { ToastContainer} from 'react-toastify'; // borrar toast si no se usa
import 'react-toastify/dist/ReactToastify.css';
import {toast} from 'react-toastify'; 
import { useAuthHeader } from "react-auth-kit";

//Cambiar los nombres de las variables a ingles
type FormData = {
  ensayo: string;
  fechaInicio: string;
  secciones: number[];
  automatic: boolean | string;
  oneStartDate: string; // Fecha inicio etapa 1
  oneStartTime: string; // Hora inicio etapa 1
  secondEnd: string; // Fecha término etapa 2
  secondEndTime: string; // Hora término etapa 2
  thirdStart: string; // Fecha inicio etapa 3
  thirdStartTime: string; // Hora inicio etapa 3
  thirdEnd: string; // Fecha término etapa 3
  thirdEndTime: string; // Hora término etapa 3
  fourthStart: string; // Fecha inicio etapa 4
  fourthStartTime: string; // Hora inicio etapa 4
  fourthEnd: string; // Fecha término etapa 4
  fourthEndTime: string; // Hora término etapa 4
}

//automatica de horario de clases o horario manual (inicio de la primer, termino de la segunda y inicio y termino de la tercera y cuarta)
//usestate con buleano, si son manuales para tener una condicion para mostrar partes del form que no estaban (definirla como false al principio del form, cambia el valor a verdadero y ahi aparece el resto del form )
//como le mando la info a back con este form más grande
const initialValues: FormData = {
    ensayo: '',
    fechaInicio: '',
    secciones: [],
    automatic: '',

    oneStartDate: '',
    oneStartTime: '',

    secondEnd: '',
    secondEndTime: '',

    thirdStart: '',
    thirdStartTime: '',
    thirdEnd: '',
    thirdEndTime: '',

    fourthStart: '',
    fourthStartTime: '',
    fourthEnd: '',
    fourthEndTime: '',
    
  }

//validacion del formulario 
const validationSchema = Yup.object().shape({
    ensayo: Yup.number().required('El ensayo es requerido'), //arreglar esta validacion 
    fechaInicio: Yup.date().required('La fecha de inicio es requerida'),
    oneStartTime: Yup.string().required('La hora de inicio es requerida'),
    secciones: Yup.array().required('Se requieren al menos una sección'), //arreglar esta validacion
    automatic: Yup.mixed().required('Se debe seleccionar una opción'),
});

export default function FormAdmin() {
    const [data, setData] = useState<any[]>([]);

    const [showError, setShowError] = useState(false);  
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const handleCloseError = () => setShowError(false);
    const [errorData, setErrorData] = useState('');
    const backendUrl = process.env.REACT_APP_API_URL;
    const assignmnetBuilder = "/assignments/builder";
    const sections = "/sections";
    const authHeader = useAuthHeader();

    const setSpinners = (status: boolean) => {
      setIsDisabled(status);
      setLoading(status);
    }  

    const getsections = async () => {
      try {
        const response = await axios.get(`${backendUrl}${sections}`, { headers: { Authorization: authHeader() } });
        if (response.data) {
          setData(response.data.data);
        } else {
          console.error("La respuesta de la API está vacía.");
        }
      }
      catch (error) {
        console.error("Ocurrió un error inesperado:", error);
      }
    }

    useEffect(() => {
      getsections();
    }, []);

    
    const handleSubmit = async (values: FormData) => {
      try {
        setSpinners(true);
        values.automatic = values.automatic === 'automatico' ? true : false;

        const isValidDate = (dateString: string) => {
          // Función para validar el formato de la fecha
          const regex = /^\d{4}-\d{2}-\d{2}$/;
          return dateString.match(regex) !== null;
        };
    
        const formatDate = (dateString: string) => {
          // Función para formatear la fecha al formato esperado
          const parts = dateString.split("-");
          return new Date(Number(parts[0]), Number(parts[1]) - 1, Number(parts[2]));
        };

        const formattedData = {
          number: parseInt(values.ensayo),
          sections: values.secciones,
          startDate: isValidDate(values.fechaInicio) && values.oneStartTime
          ? `${values.fechaInicio}T${values.oneStartTime}:00`
          : null,
          automatic: values.automatic, 
          dates:{
            secondEnd: isValidDate(values.secondEnd) && values.secondEndTime
            ? `${values.secondEnd}T${values.secondEndTime}:00`
            : null,
          thirdStart: isValidDate(values.thirdStart) && values.thirdStartTime
            ? `${values.thirdStart}T${values.thirdStartTime}:00`
            : null,
          thirdEnd: isValidDate(values.thirdEnd) && values.thirdEndTime
            ? `${values.thirdEnd}T${values.thirdEndTime}:00`
            : null,
          fourthStart: isValidDate(values.fourthStart) && values.fourthStartTime
            ? `${values.fourthStart}T${values.fourthStartTime}:00`
            : null,
          fourthEnd: isValidDate(values.fourthEnd) && values.fourthEndTime
            ? `${values.fourthEnd}T${values.fourthEndTime}:00`
            : null,
          }    
        };
        
        const response = await axios.post(`${backendUrl}${assignmnetBuilder}`, formattedData, { headers: { Authorization: authHeader() } });
        
        if (response.status === 201) {
          setSpinners(false);
          toast.success('Formulario creado exitosamente',{
            position: "top-center",
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
          });
          handleRedirect();
        }
      } catch (error) {
        setSpinners(false);
        console.error('Ocurrió un error al enviar los datos:', error);
        // toast.error('Error en la Asignación de recorecciones');
        if (axios.isAxiosError(error) && error.response && error.response.status === 400) {
          if (error.response.data) {
            toast.error(`${error.response.data.error}`);
            // setErrorData(error.response.data.message);
            // setShowError(true);
          }
        }
      }
       
    };

    const handleRedirect = () => {
      setTimeout(() => {
        window.location.href = '/ensayos';
      }, 2000);
    };
  

    return (
      <div className="Admin">
      <AdminNavbar />
        <div className="container mt-3 col-lg-6">
        <div className="d-flex justify-content-center rounded pb-2 custom-bg-gray">
        <div className="col-lg-6 col-8">
        <div className="title-text py-4 mb-0">Crear Formulario</div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        
       
          >
            {({errors, touched, isSubmitting, values, setFieldValue}) => (
          <Form>
            <div className="form-group mb-3">
              <label htmlFor="ensayo">Número de Ensayo</label>
              <Field type='number' as="select" name="ensayo" className={`form-control ${touched.ensayo && errors.ensayo ? 'is-invalid' : ''}`}>
                  <option value="">Selecciona un ensayo</option>
                  {Array.from({ length: 25 }, (_, index) => (
                    <option key={index + 1} value={index + 1}>Ensayo {index + 1}</option>
                  ))}
              </Field>
              <ErrorMessage name="ensayo" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="fechaInicio">Fecha de Inicio Etapa 1</label>
              <Field type="date" name="fechaInicio" className={`form-control ${touched.fechaInicio && errors.fechaInicio ? 'is-invalid' : ''}`} />
              <ErrorMessage name="fechaInicio" component="div" className="invalid-feedback" />
            </div>

            <div className="form-group mb-3">
              <label htmlFor="oneStartTime">Hora de Inicio Etapa 1</label>
              <Field type="time" name="oneStartTime" className={`form-control ${touched.oneStartTime && errors.oneStartTime ? 'is-invalid' : ''}`} />
              <ErrorMessage name="oneStartTime" component="div" className="invalid-feedback" />
            </div>
            
            <div className="form-group mb-3">
              <label>Seleciona las secciones:</label>
                <div>
                  {data.map((seccion, index) => (
                    <FormCheck
                      key={index}
                      type="checkbox"
                      id={`seccion-${index}`}
                      label={`Sección ${seccion.id}`}
                      value={seccion.id}
                      name="secciones"
                      checked={values.secciones.includes(seccion.id)}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        if (checked) {
                          setFieldValue('secciones', [...values.secciones, seccion.id], true);
                        } else {
                          setFieldValue('secciones', values.secciones.filter((id) => id !== seccion.id), true);
                        }
                      }}
                    />
                  ))}
                </div>
                <ErrorMessage
                  name="secciones"
                  component="div"
                  className="invalid-feedback"
                />
            </div>
            <div className="form-group mb-3">
              <label>Seleciona una opción</label>
              <div role="group" aria-labelledby="my-radio-group" className="my-4">
              <div className="form-check">
                <Field type="radio" name="automatic" value="automatico" className={`form-check-input ${touched.automatic && errors.automatic ? 'is-invalid' : ''}`} id="automatico" />
                <label className="form-check-label" htmlFor="automatico">Horario Automático</label>
              </div>
              <div className="form-check">
                <Field type="radio" name="automatic" value="manual" className={`form-check-input ${touched.automatic && errors.automatic ? 'is-invalid' : ''}`} id="manual" />
                <label className="form-check-label" htmlFor="manual">Horario Manual</label>
              </div>
              </div>
              <ErrorMessage name="automatic" component="div" className="invalid-feedback" />
            </div>
            {values.automatic === 'manual' && (
              <div>

                <div className="form-group mb-3">
                  <label>Fecha Termino Etapa 2</label>
                  <Field type="date" name="secondEnd"
                    className={`form-control ${touched.secondEnd && errors.secondEnd ? 'is-invalid' : ''}`}/>
                  <ErrorMessage name="secondEnd" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3">
                  <label htmlFor="secondEndTime">Hora de Término Etapa 2</label>
                  <Field type="time" name="secondEndTime" className={`form-control ${touched.secondEndTime && errors.secondEndTime ? 'is-invalid' : ''}`} />
                  <ErrorMessage name="secondEndTime" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3">
                  <label>Fecha Inicio Etapa 3</label>
                  <Field type="date" name="thirdStart" className={`form-control ${touched.thirdStart && errors.thirdStart ? 'is-invalid' : ''}`} />
                  <ErrorMessage name="thirdStart" component="div" className="invalid-feedback" />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="thirdStartTime">Hora de Inicio Etapa 3</label>
                  <Field type="time" name="thirdStartTime" className={`form-control ${touched.thirdStartTime && errors.thirdStartTime ? 'is-invalid' : ''}`} />
                  <ErrorMessage name="thirdStartTime" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3">
                  <label>Fecha Termino Etapa 3</label>
                  <Field type="date" name="thirdEnd" className={`form-control ${touched.thirdEnd && errors.thirdEnd ? 'is-invalid' : ''}`} />
                  <ErrorMessage name="thirdEnd" component="div" className="invalid-feedback" />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="thirdEndTime">Hora de Término Etapa 3</label>
                  <Field type="time" name="thirdEndTime" className={`form-control ${touched.thirdEndTime && errors.thirdEndTime ? 'is-invalid' : ''}`} />
                  <ErrorMessage name="thirdEndTime" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3">
                  <label>Fecha Inicio Etapa 4</label>
                  <Field type="date" name="fourthStart" className={`form-control ${touched.fourthStart && errors.fourthStart ? 'is-invalid' : ''}`} />
                  <ErrorMessage name="fourthStart" component="div" className="invalid-feedback" />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="fourthStartTime">Hora de Inicio Etapa 4</label>
                  <Field type="time" name="fourthStartTime" className={`form-control ${touched.fourthStartTime && errors.fourthStartTime ? 'is-invalid' : ''}`} />
                  <ErrorMessage name="fourthStartTime" component="div" className="invalid-feedback" />
                </div>

                <div className="form-group mb-3">
                  <label>Fecha Termino Etapa 4</label>
                  <Field type="date" name="fourthEnd" className={`form-control ${touched.fourthEnd && errors.fourthEnd ? 'is-invalid' : ''}`} />
                  <ErrorMessage name="fourthEnd" component="div" className="invalid-feedback" />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="fourthEndTime">Hora de Término Etapa 4</label>
                  <Field type="time" name="fourthEndTime" className={`form-control ${touched.fourthEndTime && errors.fourthEndTime ? 'is-invalid' : ''}`} />
                  <ErrorMessage name="fourthEndTime" component="div" className="invalid-feedback" />
                </div>
                <div></div>

              </div>
            )}
            <div className='d-flex justify-content-center align-items-center'>
              <button
                type="submit"
                disabled={isDisabled}
                style={{
                  backgroundColor: "#072f75",
                  border: "none",
                }}
                className="btn login-btn d-flex justify-content-center align-items-center"
              >
                Crear
                { loading ? 
                  <p className='mb-0'>
                    <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                  </p>
                :
                  <></>
                }
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
    </div>
    </div>
    <Modal show={showError} onHide={handleCloseError}>
        <Modal.Header closeButton>
          <Modal.Title>Error ya esta creado</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorData}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseError}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
    
    );


    };

