import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/HomePage.css";
import { FAQS, FAQStype } from "../components/SharedTypes";
import FAQSBox from "../components/FAQSBox";
import "../styles/faqs.css";
import axios from "axios";
import RoleNavBar from "../components/RoleNavbar";
import { useAuthHeader } from "react-auth-kit";

const FAQSPage: React.FC = () => {
  const [Available, setAvailable] = useState<FAQS[]>([]);
  const authHeader = useAuthHeader();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const backendUrl = process.env.REACT_APP_API_URL;
        const loginEndpoint = "/faq";
        const response = await axios.get(`${backendUrl}${loginEndpoint}`, {
          headers: { Authorization: authHeader() },
        });
        const { data } = response.data;
        const availableItems = data.filter(
          (item: FAQS) => item.status === "CLOSED"
        );
        setAvailable(availableItems);
      } catch (error) {
        // console.log(error);
      }
    };
    fetchData();
  }, []);

  const AvailableFAQS: FAQStype = {
    type: "available",
    faqsList: Available,
  };

  return (
    <div className="App">
      <RoleNavBar />
      <div className="title-text">FAQS</div>
      <div className="faqs-box">
        <FAQSBox {...AvailableFAQS} />
      </div>
    </div>
  );
};

export default FAQSPage;
