import React from "react";
import "../styles/login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Formik, Field, Form, ErrorMessage, FormikHelpers } from "formik";
import { useSignIn } from "react-auth-kit";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import HomeNavbar from "../components/HomeNavbar";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

type LoginValues = {
  email: string;
  password: string;
};

export default function Login() {
  const signIn = useSignIn();
  const navigate = useNavigate();
  const roleToRouteMap = {
    STUDENT: "/etapa1A",
    ADMIN: "/formularios/1/10",
    COLLABORATOR: "/summary/collaborator",
  };

  const initialValues: LoginValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Correo electrónico inválido")
      .test(
        "is-allowed-email",
        "Solo se permiten correos example, UC o gmail",
        (value) => {
          if (!value) return true;
          return (
            value.endsWith("@uc.cl") ||
            value.endsWith("@gmail.com") ||
            value.endsWith("@estudiante.uc.cl") ||
            value.endsWith("@estudiante.uc.com")
          );
        }
      )
      .required("El correo electrónico es obligatorio"),
    password: Yup.string().required("Campo requerido"),
  });

  const handleSubmit = async (
    values: LoginValues,
    { setSubmitting, setErrors }: FormikHelpers<LoginValues>
  ) => {
    try {
      values.email = values.email.toLowerCase();
      const backendUrl = process.env.REACT_APP_API_URL;
      const loginEndpoint = "/session/login";
      const response = await axios.post(
        `${backendUrl}${loginEndpoint}`,
        values,
        { withCredentials: false }
      );
      const { data } = response.data;

      if (
        signIn({
          token: data.token.slice("Bearer ".length).trim(),
          expiresIn: data.expiresIn,
          tokenType: "Bearer",
          authState: {
            email: values.email,
            role: data.role,
            id: data.id,
          },
        })
      ) {
        const expiryTime = new Date().getTime() + data.expiresIn * 1000; // Convert to milliseconds
        localStorage?.setItem('tokenExpiry', expiryTime.toString());
        const route =
          roleToRouteMap[data.role as keyof typeof roleToRouteMap] || "/login";
        navigate(route);
      } else {
        setErrors({ email: "Error al iniciar sesión" });
      }
    } catch (error) {
      setErrors({ email: "Error al iniciar sesión" });

      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        const errorMessages: { [key: number]: string } = {
          401: "Credenciales inválidas",
          404: "Usuario no encontrado",
        };

        const errorMessage = errorMessages[status] || "Ocurrió un error";

        setErrors({ email: errorMessage });
        console.error(`HTTP Error ${status}: ${errorMessage}`);
      } else {
        console.error("Ocurrió un error inesperado:", error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="App">
      <HomeNavbar />
      <div className="login-container mt-5 px-2">
        <div className="login-title-text1">Iniciar Sesión</div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="login-form">
              <div className="login-form-group mb-2">
                <label htmlFor="email" className="col-sm-2 col-form-label">
                  Email:
                </label>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  className="login-form-control"
                  placeholder="Ingresa tu email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="login-error-message"
                />
              </div>

              <div className="login-form-group mb-2">
                <label htmlFor="password" className="col-sm-2 col-form-label">
                  Contraseña:
                </label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  className="login-form-control"
                  placeholder="Contraseña"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="login-error-message"
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#072f75",
                    border: "none",
                  }}
                  className="btn login-btn"
                  disabled={isSubmitting}
                >
                  Ingresar
                </button>
              </div>
            </Form>
          )}
        </Formik>
        <Nav.Link
          as={Link}
          to="/recuperar-contrasena"
          active
          className="navbar-link"
          style={{ marginRight: "13px" }}
        >
          ¿Se te olvidó tu contraseña? Haz click aquí
        </Nav.Link>
      </div>
      <div className="text-center"></div>
    </div>
  );
}
