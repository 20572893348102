import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import { toast } from "react-toastify";

interface User {
  name: string;
  lastName: string;
  email: string;
  studentNumber: string;
  identifier: {
    identifier: string;
  }
  role: string;
  passwordSentAt: boolean;
  passwordSent: string;
  // Add more properties as needed
}

interface EditUserProps {
  // You can also make user prop optional if you want to handle the case where user data is still loading
  user?: User;
}

type UsersParams = {
    id: string;
  };
  

const EditUser: React.FC<EditUserProps> = () => {
  const { id } = useParams<UsersParams>();
  const [user, setUser] = useState<User | undefined>();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/admin/${id}`, { headers: { Authorization: authHeader() } });
        const responseData = response.data;
        setUser(responseData.data);
      } catch (error) {
        toast.error(`Error al cargar datos: ${error || 'Error desconocido'}`, {
          position: "bottom-right",
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false
        });
      }
    };
    if (id) {
      fetchUserData();
    }
  }, [id]);

  const setSpinners = (status: boolean) => {
    setIsDisabled(status);
    setLoading(status);
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Por favor, ingresa el nombre."),
    lastName: Yup.string().required("Por favor, ingresa el apellido."),
    // Add other fields to the validation schema as needed
  });

  const formik = useFormik({
    initialValues: {
      name: user?.name || "",
      lastName: user?.lastName || "",
      studentNumber: user?.studentNumber || "",
      email: user?.email || "",
      role: user?.role || ""
      
      // Add other fields and their initial values as needed
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {

      const backendUrl = process.env.REACT_APP_API_URL;
      const controller = `/users/admin/${id}`;

      setSpinners(true);
      try {
        await axios.put(
            `${backendUrl}${controller}`,
            values,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
        );
        toast.success('Usuario editado exitosamente', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
        setSpinners(false);
        
      } catch (error: any) {
        toast.error(`Error al editar el usuario: ${error.response.data.message|| 'Error desconocido'}`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
        });
        setSpinners(false);
      }
    },
  });

  useEffect(() => {
    // Update form values when user data changes
    if (user) {
      formik.setValues({
        name: user?.name || "",
        lastName: user?.lastName || "",
        studentNumber: user?.studentNumber || "",
        email: user?.email || "",
        role: user?.role || ""
        // Add other fields and their values as needed
      });
    }
  }, [user]);

  
    return (
      <div className="Admin">
        <AdminNavbar />
        <Container className="mt-3 col-lg-8">
          <div className="card rounded py-2 px-lg-4 custom-bg-gray border-none">
            <div className="card-body">
              <h5 className="card-title title-text-left">Editar Usuario</h5>
              { user ?
                <Form className="col-lg-12" onSubmit={formik.handleSubmit}>
                    <Row>
                        {/* Existing fields */}
                        <Form.Group as={Col} xs={12} md={6} className="py-1" controlId="formName">
                            <Form.Label>Nombre</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Ingresa el nombre"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.name}
                            name="name"
                            isInvalid={formik.touched.name && !!formik.errors.name}
                            />
                            <Form.Control.Feedback type="invalid">
                            {formik.errors.name}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} md={6} className="py-1" controlId="formLastName">
                            <Form.Label>Apellido</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Ingresa el apellido"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            name="lastName"
                            isInvalid={formik.touched.lastName && !!formik.errors.lastName}
                            />
                            <Form.Control.Feedback type="invalid">
                            {formik.errors.lastName}
                            </Form.Control.Feedback>
                        </Form.Group>

                    </Row>

                    <Row >
                        {/* Add other form fields similar to the create form */}
                        <Form.Group as={Col} xs={12} md={6} className="py-1" controlId="formEmail">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                            type="email"
                            placeholder="Ingresa el correo electrónico"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            name="email"
                            isInvalid={formik.touched.email && !!formik.errors.email}
                            />
                            <Form.Control.Feedback type="invalid">
                            {formik.errors.email}
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group as={Col} xs={12} md={6} className="py-1" controlId="formStudentNumber">
                            <Form.Label>Número de Estudiante</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Ingresa el número de estudiante"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.studentNumber}
                            name="studentNumber"
                            isInvalid={formik.touched.studentNumber && !!formik.errors.studentNumber}
                            />
                            <Form.Control.Feedback type="invalid">
                            {formik.errors.studentNumber}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

                    <Row className="mb-2">
                        {/* Add other form fields similar to the create form */}
                        <Form.Group as={Col} xs={12} md={6} className="py-1" controlId="formRole">
                            <Form.Label>Rol</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.role}
                                name="role"
                                isInvalid={formik.touched.role && !!formik.errors.role}
                            >
                                <option value="">Selecciona un Rol</option>
                                <option value="STUDENT">Estudiante</option>
                                <option value="COLLABORATOR">Ayudante</option>
                                <option value="ADMIN">Admin</option>
                                {/* Add other role options if needed */}
                            </Form.Control>
                            <Form.Control.Feedback type="invalid">
                                {formik.errors.role}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>

    
                    {/* Add other form fields similar to the create form */}
                    
                    <Col xs={12} md={3}>
                        <Button
                            type="submit"
                            variant="primary" 
                            disabled={isDisabled}
                            style={{
                                backgroundColor: "#072f75",
                                border: "none",
                            }}
                            className="w-100 mb-2 mb-md-0 py-2 py-lg-1"
                            >
                            Guardar Cambios
                            {loading ? (
                                <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                            ) : (
                                <></>
                            )}
                        </Button>
                    </Col>
                </Form>
                : 
                <p>Cargando información...
                    <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                </p>
              }
            </div>
          </div>
        </Container>
      </div>
    );
  };
  
export default EditUser;
