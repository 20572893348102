import React, { useState } from "react";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { BoxProps } from "./SharedTypes";
import axios from "axios"; // Import axios
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { Spinner } from "react-bootstrap";

const HomeworkBox: React.FC<BoxProps> = ({ type, homeworkList }) => {
  const [openAccordion, setOpenAccordion] = useState<string | null>(null);
  const [selectedFiles, setSelectedFiles] = useState<(File | null)[]>([]);
  const [uploadStatus, setUploadStatus] = useState<{ [key: number]: boolean }>(
    {}
  );
  const navigate = useNavigate();
  const essaySubmitEndpoint = `${process.env.REACT_APP_API_URL}/attachments`;

  const authUser = useAuthUser();
  const userId = authUser()?.id;
  const authHeader = useAuthHeader();

  const handleAccordionClick = (accordionId: string) => {
    setOpenAccordion((prevOpenAccordion) =>
      prevOpenAccordion === accordionId ? null : accordionId
    );
  };

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFiles((prev) => {
        const newFiles = [...prev];
        newFiles[index] = event.target.files![0];
        return newFiles;
      });
    }
  };

  const handleUpload = async (index: number, title: string, deadline: Date) => {
    const selectedFile = selectedFiles[index];

    if (selectedFile) {
      if (!selectedFile.name.toLowerCase().endsWith(".pdf")) {
        // Si el archivo no es un PDF, muestra una alerta con el mensaje deseado
        alert("Solo se pueden subir archivos PDF.");
        return;
      }
    }

    if (!selectedFile) return;

    setUploadStatus({ ...uploadStatus, [index]: true });
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("essayNumber", title);
    //const userId = localStorage.getItem('userId');

    formData.append("userId", userId);
    const currentDate = new Date();
    const assignmentEndDate = deadline;
    if (currentDate > assignmentEndDate) {
      window.alert("Se acabó el tiempo de entrega.");
      window.location.reload();
    } else {
      try {
        await axios.post(essaySubmitEndpoint, formData, {
          headers: { Authorization: authHeader() },
        });
        setUploadStatus({ ...uploadStatus, [index]: false });
        window.alert("Archivo subido exitosamente.");
        window.location.reload();

        // Refresh the page after a successful upload
        // window.location.reload();
      } catch (error) {
        toast.error("Error al subir el archivo", {
          position: "bottom-right",
        });
        setUploadStatus({ ...uploadStatus, [index]: false });
      }
    }
  };

  return (
    <div className={`homework-box ${type} container `}>
      <h2 style={{ color: "#072f75" }}>
        {type === "pending" ? "Próximas Tareas" : "Tareas Anteriores"}
      </h2>

      <div className="alert alert-warning text-sm" role="alert">
        <b>Recuerda: </b>cambiar el nombre del archivo y que no contenga datos personales. - Tampoco incluyas puntajes.
      </div>
      {homeworkList.map((hw, index) => {
        const accordionId = `${type}${index}`;
        const now = new Date();
        const isOpen = openAccordion === accordionId;
        const isNotAvailableYet = hw.startDate > now;

        return (
          <div key={index} className="accordion" id={accordionId}>
            <div className="accordion-item">
              <h2 className="accordion-header" id={`heading${type}${index}`}>
                <button
                  className={`accordion-button ${isOpen ? "" : "collapsed"}`}
                  type="button"
                  onClick={() => handleAccordionClick(accordionId)}
                >
                  <span className="hw-title" style={{ width: "200px" }}>
                    {" "}
                    Ensayo {hw.title}{" "}
                  </span>
                  <div className="fecha-title">
                    {isNotAvailableYet
                      ? `No disponible hasta: ${hw.startDate.toLocaleString()}`
                      : `Cierre: ${hw.deadline.toLocaleString()}`}
                  </div>
                </button>
              </h2>
              <div
                id={`collapse${type}${index}`}
                className={`accordion-collapse collapse ${
                  isOpen ? "show" : ""
                }`}
                aria-labelledby={`heading${type}${index}`}
              >
                <div className="accordion-body d-flex">
                  <input
                    type="file"
                    className="form-control mb-2"
                    style={{ width: "300px", marginRight: "20px" }}
                    onChange={(e) => handleFileChange(e, index)}
                  />
                  <button
                    type="button"
                    className="btn btn-primary mb-2"
                    onClick={() => handleUpload(index, hw.title, hw.deadline)}
                    disabled={!!uploadStatus[index]}
                  >
                    {uploadStatus[index] ? 
                          <> 
                            Subiendo...
                            <Spinner animation="border" size='sm' role="status">
                            </Spinner>
                          </> 
                          : 
                          "Subir"
                    }
                  </button>
                  {hw.details && (
                    <a
                      href={hw.details}
                      className="file-link"
                      target="_blank" // Cambia "_blank" por "_self"
                    >
                      Ver Archivo Subido
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default HomeworkBox;
