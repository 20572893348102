import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import { useAuthHeader } from "react-auth-kit";
import CorrectionsStage2 from "../../components/CorrectionStage2Admin";
import type { CorrectionData } from "../../components/CorrectionStage2Admin";
import { useParams } from "react-router-dom";

type RouteCorrectionsParams = {
  stageParam?: string;
  sectionParam?: string;
  assignmentNumberParam?: string;
};

export default function CorrectionsPage() {
  const backendUrl = process.env.REACT_APP_API_URL;
  const authHeader = useAuthHeader();
  const { stageParam, sectionParam, assignmentNumberParam } =
    useParams<RouteCorrectionsParams>();
  const section = sectionParam ? +sectionParam : 1;
  const assignmentNumber = assignmentNumberParam ? +assignmentNumberParam : 1;
  const stage = stageParam ? +stageParam : 2;
  const [correctionsData, setCorrectionsData] = useState<CorrectionData[]>([]);

  const handleData = async () => {
    try {

      if (stage === 2) {
        const response = await axios.get(
          `${backendUrl}/corrections?number=${assignmentNumber}&section=${section}&stage2=true`,
          {
            headers: {
              Authorization: authHeader(),
            },
          }
        );
        const data: CorrectionData[] = response.data.data.map(
          (correction: any, index: number) => {
            const correctionState = correction.corrected;
            const corrected = correction.correctedUser;
            const corrector = correction.correctorUser;

            return {
              id: `${index}`,
              correctorName: `${corrector.name} ${corrector.lastName}`,
              correctorData: `${corrector.email} ${corrector.studentNumber}`,
              correctedName: `${corrected.name} ${corrected.lastName}`,
              correctedData: `${corrected.email} ${corrected.studentNumber}`,
              score: correctionState ? correction.score : "--",
              comment: correction.justification
                ? correction.justification
                : "Por hacer", //correction.comment
              essay: correction.attachment.url,
              state: correctionState ? "Corregido" : "Pendiente",
            };
          }
        );
        setCorrectionsData(data);
      } else {
        const response = await axios.get(
          `${backendUrl}/reviews?number=${assignmentNumber}&sectionNumber=${section}&stage3=true`,
          {
            headers: {
              Authorization: authHeader(),
            },
          }
        );

        const data: CorrectionData[] = response.data.data.map(
          (correction: any, index: number) => {
            const correctionState = correction.corrected;
            const corrected = correction.correctedUser;
            const corrector = correction.assigmentUser.user;
            return {
              id: `${index}`,
              correctorName: `${corrector.name} ${corrector.lastName}`,
              correctorData: `${corrector.email} ${corrector.studentNumber}`,
              correctedName: `${corrected.name} ${corrected.lastName}`,
              correctedData: `${corrected.email} ${corrected.studentNumber}`,
              score: correctionState ? correction.correctionScore : "--",
              comment: correction.correction.justification, //correction.comment ""
              essay: correction.correction.attachment.url, //correction.attachment.url, ""
              state: correction?.assigmentUser?.status != 'OUT' ? (correctionState ? "Corregido" : "Pendiente") : 'OUT',
            };
          }
        );
        setCorrectionsData(data);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    handleData(); // Call handleData when the component mounts
  }, []);

  return (
    <div className="Admin">
      <AdminNavbar />
      <div className="title-text">
        Etapa {stage} ensayo {assignmentNumber} sección {section}
      </div>
      <CorrectionsStage2 data={correctionsData} />
    </div>
  );
}
