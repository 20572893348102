import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, ConfigProvider } from "antd";
import type { ColumnsType } from "antd/es/table";
import { useAuthHeader } from "react-auth-kit";
import { table } from "console";

type essayData = {
  id: string;
  courseSectionId: string;
  essayNumber: string;
  deliveredItems: number;
  notDeliveredItems: number;
  link: string;
};
type filterData = {
  text: string;
  value: string | number;
};

const TableView: React.FC = () => {
  const [data, setData] = useState<essayData[]>([]);
  const [sectionFilter, setSectionFilter] = useState<filterData[]>([]);
  const [essayFilter, setEssayFilter] = useState<filterData[]>([]);
  const backendUrl = process.env.REACT_APP_API_URL;
  const summaryEndpoint = "/assignments/summary";
  const [pageSize, setPageSize] = useState<number>(10);
  const authHeader = useAuthHeader();

  const handleData = async () => {
    try {
      const response = await axios.get(`${backendUrl}${summaryEndpoint}`, {
        headers: {
          Authorization: authHeader(),
        },
      });

      // console.log(response)

      const tableData: essayData[] = response.data.data.map(
        (essay: any, index: number) => {
          return {
            id: `${index}`,
            courseSectionId: `${essay.courseSectionId}`,
            essayNumber: `${essay.number}`,
            deliveredItems: essay.deliveredItems,
            notDeliveredItems: essay.notDeliveredItems,
            link: `/ensayos/${essay.courseSectionId}/${essay.number}`,
          };
        }
      );
      const sectionDataFilter = Array.from(
        new Set(tableData.map((item) => item.courseSectionId))
      )
        .sort((a: string, b: string) => Number(a) - Number(b))
        .map((x) => {
          return { text: x.toString(), value: x.toString() };
        });
      const essayDataFilter = Array.from(
        new Set(tableData.map((item) => item.essayNumber))
      )
        .sort((a: string, b: string) => Number(a) - Number(b))
        .map((x) => {
          return { text: x.toString(), value: x.toString() };
        });

      setEssayFilter(essayDataFilter);
      setSectionFilter(sectionDataFilter);
      setData(tableData);
    } catch (error) {
      console.error("Ocurrió un error inesperado:", error);
    }
  };

  useEffect(() => {
    handleData();
  }, []);

  const columns: ColumnsType<essayData> = [
    {
      title: "Sección",
      dataIndex: "courseSectionId",
      key: "courseSectionId",
      sorter: (a: essayData, b: essayData) =>
        Number(a.courseSectionId) - Number(b.courseSectionId),
      filters: sectionFilter,
      onFilter: (value, record: essayData) =>
        record.courseSectionId.indexOf(String(value)) === 0,
    },
    {
      title: "N° Ensayo",
      dataIndex: "essayNumber",
      key: "essayNumber",
      sorter: (a: essayData, b: essayData) =>
        Number(a.essayNumber) - Number(b.essayNumber),
      filters: essayFilter,
      onFilter: (value, record: essayData) =>
        record.essayNumber === String(value),
    },
    {
      title: "Entregas realizadas",
      dataIndex: "deliveredItems",
      key: "deliveredItems",
    },
    {
      title: "Entregas pendientes",
      dataIndex: "notDeliveredItems",
      key: "notDeliveredItems",
    },

    {
      title: "Visualizar ensayos",
      dataIndex: "link",
      key: "link",
      render: (link: string) => (
        <a href={link} target="_blank" rel="noreferrer">
          Ver Ensayos
        </a>
      ),
    },
  ];

  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };

  return (
    <div className="Admin">
      <AdminNavbar />
      <div className="container">
        <div className="text-center my-4">
          <div className="title-text">Resumen etapa 1</div>
        </div>
        <ConfigProvider
          theme={{
            token: {
              fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif`,
              colorIcon: "white",
            },
            components: {
              Table: {
                /* here is your component tokens */
                headerBg: "#072f75",
                headerColor: "#f0f0f0",
                headerSortHoverBg: "#1165f6",
                headerSortActiveBg: "#072f75",
              },
            },
          }}
        >
          <Table
            columns={columns}
            dataSource={data}
            rowKey={(record) => record.id}
            bordered
            pagination={{
              pageSize: pageSize,
              total: data.length,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "50"],
              onChange: handleChangePageSize,
            }}
          />
        </ConfigProvider>
      </div>
    </div>
  );
};

export default TableView;
