import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import "../../styles/customForms.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { useParams } from "react-router-dom";
import { ConfigProvider, DatePicker, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Button, Col, Container, Row } from "react-bootstrap";
import MyNavbar from "../../components/NavBar";

const RevisionShow: React.FC = () => {
    const { id } = useParams<any>();
    const authHeader = useAuthHeader();
    const authUser = useAuthUser();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState<any>();

    const setSpinners = (status: boolean) => {
        setLoading(status);
    }

    const setRevision = async () => {
        setSpinners(true);
        const revisionEndpoint = `/revisions/students/${id}`;
        try {
        const response = await axios.get(
            `${process.env.REACT_APP_API_URL}${revisionEndpoint}`,
            { headers: { Authorization: authHeader() } }
        );
        const revisionData = response.data.data;
        setData(revisionData);
        setSpinners(false);
        } catch (error) {
            setSpinners(false);
            toast.error(`Error al cargar datos`, {
                position: "bottom-right",
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: false,
            });
        }
    }

    useEffect(() => {
        setLoading(true);
        setRevision();
    }, []);
  

  return (
    <div className="Admin">
      <MyNavbar />
      <Container className="mt-3  col-lg-11 overflow-auto">
        <div className="card rounded p-2 custom-bg-gray border-none overflow-auto">
          <div className="card-body">
            <h5 className="card-title title-text-left">Recorreccion</h5>
            <div className="alerta px-0 mx-0">
            <div
                className="alert alert-warning alert-dismissible fade show custom-alert"
                role="alert"
                >
                <strong>Recuerda:</strong> El puntaje de una recorrección es la variación sobre el puntaje que tuviste en el item que enviaste a recorregir.
                <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                ></button>
                </div>
            </div>
            <div className="card-text">
              { data ? 
                <>
                <Row>
                    <Col xs={12} md={3}>
                        <strong>Ensayo:</strong>
                    </Col>
                    <Col xs={12} md={3}>
                        {data?.assigmentUser?.assignment?.number || '--'}
                    </Col>
                    <Col xs={12} md={3}>
                        <strong>Corregido:</strong>
                    </Col>
                    <Col xs={12} md={3}>
                        {data?.corrected != null ? data?.corrected === true ? 'Si' : "No" : "No"}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <strong>Puntaje:</strong>
                    </Col>
                    <Col xs={12} md={3}>
                        {data?.scoreDelta || '--'}
                    </Col>
                    <Col xs={12} md={3}>
                        <strong>Etapa:</strong>
                    </Col>
                    <Col xs={12} md={3}>
                        {(data?.stage === "SECOND" ? "2" : "3") || '--'}
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <strong>Comentario para recorrección:</strong>
                    </Col>
                    <Col xs={12} md={9}>
                        <div className="border border-secondary-subtle rounded p-1">
                            {data?.studentComment || '--'}
                        </div>
                    </Col>
                </Row>
                <Row className="mt-2">
                    <Col xs={12} md={3}>
                        <strong>Comentario Ayudante:</strong>
                    </Col>
                    <Col xs={12} md={9}>
                        <div className="border border-secondary-subtle rounded p-1">
                            {data?.collaboratorComment || '--'}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={3}>
                        <Button variant="secondary" size="sm" href={`/revisions`}  className="mt-2 w-100 py-1">
                            Volver
                        </Button>
                    </Col>
                </Row>
                </>
                : loading ? <><span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span></> : <>No hay recorrecciones hechas.</> 
              }

            </div>
            
          </div>
        </div>
        
      </Container>
    </div>
  );
};

export default RevisionShow;