import React, { useEffect } from "react";
import { Navbar, Nav, NavDropdown, NavItem } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../App.css";
import CurrentUserInfo from "./CurrentUserInfo";
import { useIsAuthenticated } from "react-auth-kit";

export default function CollaboratorNavbar() {

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated()) {
      validateSessionValidity(navigate);
    }
  }, [isAuthenticated, navigate]);

  const validateSessionValidity = (navigate: ReturnType<typeof useNavigate>): void => {
    if (isTokenExpired()) {
      navigate('/login');
    }
  };
  
  const isTokenExpired = (): boolean => {
    try {
      const expiryTimeString = localStorage.getItem('tokenExpiry');
  
      if (!expiryTimeString) {
        return true;
      }
  
      const expiryTimeNumber = Number(expiryTimeString);
  
      if (isNaN(expiryTimeNumber)) {
        return true;
      }
  
      const expiryDate = new Date(expiryTimeNumber);
  
      return new Date() >= expiryDate;
    } catch (error) {
      return true;
    }
  };


  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="shadow p-3">
      <Navbar.Brand as={Link} to="/etapa1" className="navbar-link">
        Limerick
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="navbar-nav-dropdown"
        className="custom-toggler"
      />
      <Navbar.Collapse id="navbar-nav-dropdown">
        <Nav className="ms-auto">
          <NavItem>
            <CurrentUserInfo />
          </NavItem>
          <Nav.Link
            as={Link}
            to="/summary/collaborator"
            active
            className="navbar-link"
            style={{ marginRight: "13px" }}
          >
            Resumen
          </Nav.Link>
          <NavDropdown
            title={
              <span style={{ color: "#072f75", fontWeight: "bold" }}>
                Ensayos
              </span>
            }
            id="recorregir-dropdown"
            active
          >
            <NavDropdown.Item as={Link} to="/etapa2">
              Etapa 2
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/etapa3">
              Etapa 3
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title={
              <span style={{ color: "#072f75", fontWeight: "bold" }}>
                Recorregir
              </span>
            }
            id="recorregir-dropdown"
            active
          >
            <NavDropdown.Item as={Link} to="/recorregir-etapa2">
              Etapa 2
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/recorregir-etapa3">
              Etapa 3
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            title={
              <span style={{ color: "#072f75", fontWeight: "bold" }}>
                Mi cuenta
              </span>
            }
            id="cuenta-dropdown"
            active
            align={{ lg: "end" }}
          >
           
            <NavDropdown.Item as={Link} to="/change-password">
              Cambiar contraseña
            </NavDropdown.Item>
            <NavDropdown.Item as={Link} to="/logOut">
              Cerrar sesión
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}