import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import HomeNavbar from "../components/HomeNavbar";
import LoginButton from "../components/LoginButton"; // Import the new component
import "../styles/HomePage.css";

const HomePage: React.FC = () => {
  return (
    <div className="App">
      <HomeNavbar />
      <div className="content-wrapper">
        <div className="content-box">
          <p>Bienvenidos a Limerick 2.0.1</p>
          <p>¿Tienes algún problema?</p>
          <p>Contáctate con <strong>ics2813.limerick@gmail.com</strong></p>
          <LoginButton />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
