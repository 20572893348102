import React, { useState, useEffect } from "react";
import HomeworkBox from "../../components/HomeworkBox";
import BlockedHomeworkBox from "../../components/BlockedHomeworkBox";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../styles/etapa1.css";
import axios from "axios";
import { Homework, BoxProps, Schedule } from "../../components/SharedTypes";
import MyNavbar from "../../components/NavBar";
import { useAuthHeader, useAuthUser } from "react-auth-kit";

const Etapa1: React.FC = () => {
  const [pendingHomework, setPendingHomework] = useState<Homework[]>([]);
  const [pastHomework, setPastHomework] = useState<Homework[]>([]);
  const [homeworkItems, setHomeworkItems] = useState<Homework[]>([]);
  const authHeader = useAuthHeader();
  const essayEndpoint = `${process.env.REACT_APP_API_URL}/assignmentUser?user=`;
  const authUser = useAuthUser();
  const userId = authUser()?.id;

  const fetchData = async () => {
    try {
      const essayResponse = await axios.get(
        `${essayEndpoint}${userId}`, 
        { headers: { Authorization: authHeader() }}
      );
      const essayData = essayResponse.data.data;

      const combinedAssignmentData: Homework[] = [];

      for (let essayItem of essayData) {
        let schedule = essayItem.assignment.assignmentSchedules.find((sch: Schedule) => sch.stage === "FIRST");
        let sum = schedule.status == 'OPEN' ? 365 : 0;
        combinedAssignmentData.push({
          title: essayItem.assignment.number.toString(),
          details:
            essayItem.attachments.length > 0
              ? essayItem.attachments[0].url
              : "",
          deadline: new Date(
            schedule.endDate 
            ? schedule.endDate 
            : new Date().setDate(new Date().getDate() + sum)
          ),
          startDate: new Date(
            schedule.startDate
          ),
          corrections: undefined,
        });
      }

      setHomeworkItems(combinedAssignmentData);

      const currentDate = new Date().getTime();
      const pending: Homework[] = [];
      const past: Homework[] = [];

      combinedAssignmentData.forEach((homework) => {
        const deadlineDate = new Date(homework.deadline).getTime();
        if (!isNaN(deadlineDate)) {
          if (currentDate < deadlineDate) {
            pending.push(homework);
          } else {
            past.push(homework);
          }
        } else {
          console.error("Invalid date format:", homework.deadline);
        }
      });

      setPendingHomework(pending);
      setPastHomework(past);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const pendingBoxProps: BoxProps = {
    type: "pending",
    homeworkList: pendingHomework,
  };

  const pastBoxProps: BoxProps = {
    type: "past",
    homeworkList: pastHomework,
  };

  return (
    <div className="App1">
      <MyNavbar />
      <div className="title-text">Etapa 1: Subir Ensayo</div>
      <div className="alerta">
        <div
          className="alert alert-warning alert-dismissible fade show custom-alert"
          role="alert"
        >
          <strong>Recuerda:</strong> Eliminar los datos de autor y verificar que
          hayas subido correctamente tu archivo. Ante dudas puedes escribir al correo <strong>ics2813.limerick@gmail.com</strong> 
           {/* Ante cualquier duda revisar las
          preguntas frecuentes (FAQ). */}
          {/* <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button> */}
        </div>
      </div>
      <div className="box px-0">
        <HomeworkBox {...pendingBoxProps} />
        <BlockedHomeworkBox {...pastBoxProps} />
      </div>
    </div>
  );
};

export default Etapa1;