import React, { ChangeEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Button, Form, Col, Row, Alert } from "react-bootstrap";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import "../../styles/customForms.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuthHeader } from "react-auth-kit";

const UserForm: React.FC = () => {
  // ... (existing code)

  const authHeader = useAuthHeader();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sections, setSections] = useState<any[]>([]);    
  const backendUrl = process.env.REACT_APP_API_URL;
  const sectionsURL = "/sections";

  const validationSchema = Yup.object().shape({
    // ... (existing validation)
    name: Yup.string().required("Por favor, ingresa el nombre."),
    lastName: Yup.string().required("Por favor, ingresa el apellido."),
    secondLastName: Yup.string(),
    email: Yup.string().email("Por favor, ingresa un correo electrónico válido.").required("Por favor, ingresa el correo electrónico."),
    role: Yup.string().required("Por favor, selecciona un rol."),
    dateOfBirth: Yup.date().required("Por favor, ingresa la fecha de nacimiento."),
    studentNumber: Yup.string().required("Por favor, ingresa el número de estudiante."),
    section: Yup.number().notRequired()
  });


  const setSpinners = (status: boolean) => {
    setIsDisabled(status);
    setLoading(status);
  }

  const getsections = async () => {
    try {
      const response = await axios.get(`${backendUrl}${sectionsURL}`, { headers: { Authorization: authHeader() } });
      if (response.data) {
        const data = response.data;
        setSections(data.data);
      }
    }
    catch (error) {
      toast.error(`Error al buscar secciones`, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      // console.error("Ocurrió un error inesperado:", error);
    }
  }

  useEffect(() => {
    getsections();
  },[]);


  const formik = useFormik({
    initialValues: {
      // ... (existing initialValues)
      name: "",
      lastName: "",
      secondLastName: "",
      email: "",
      role: "",
      dateOfBirth: "",
      studentNumber: "",
      section: "",
      sendPasswordImmediately: false
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSpinners(true);
      try {
        // Additional logic for creating a user
        const createUserEndpoint = "/users";
        const userData = {
          name: values.name,
          lastName: values.lastName,
          email: values.email,
          role: values.role,
          dateOfBirth: values.dateOfBirth,
          studentNumber: values.studentNumber,
          courseSection: values.role === "STUDENT" ? values.section : null ,
          sendPasswordImmediately: values.sendPasswordImmediately,
        };
  
        // Make a request to create a user
        try {
          await axios.post(`${process.env.REACT_APP_API_URL}${createUserEndpoint}`, userData, {
            headers: {
              Authorization: authHeader(),
            },
          });
          toast.success('Usuario creado exitosamente', {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setSpinners(false);
        } catch (error: any) {
          // Handle error
          toast.error(`Error al crear el usuario: ${error.response.data.message|| 'Error desconocido'}`, {
            position: "bottom-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
          });
          setSpinners(false);
        }
  
      } catch (error) {
        // console.error('Error in form submission:', error);
      }
    },
  });
  

  return (
    <div className="Admin">
      <AdminNavbar />
      <Container className="mt-3  col-lg-8 overflow-auto">
        <div className="card rounded p-2 custom-bg-gray border-none overflow-auto">
          <div className="card-body">
            <h5 className="card-title title-text-left">Alumnos</h5>
            <Form className="col-lg-12" onSubmit={formik.handleSubmit}>
            <Row className="mb-3">
              <Form.Group as={Col} xs={12} md={6} controlId="formName">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingresa el nombre"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                  name="name"
                  isInvalid={formik.touched.name && !!formik.errors.name}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.name}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="formLastName">
                <Form.Label>Apellido</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingresa el apellido"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  name="lastName"
                  isInvalid={formik.touched.lastName && !!formik.errors.lastName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.lastName}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} xs={12} md={6} controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Ingresa el correo electrónico"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name="email"
                  isInvalid={formik.touched.email && !!formik.errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="formStudentNumber">
                <Form.Label>Número de Estudiante</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Ingresa el número de estudiante"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.studentNumber}
                  name="studentNumber"
                  isInvalid={formik.touched.studentNumber && !!formik.errors.studentNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.studentNumber}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            <Row className="mb-3">
              <Form.Group as={Col} xs={12} md={6} controlId="formRole">
                <Form.Label>Rol</Form.Label>
                <Form.Control
                  as="select"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.role}
                  name="role"
                  isInvalid={formik.touched.role && !!formik.errors.role}
                >
                  <option value="">Selecciona un Rol</option>
                  <option value="STUDENT">Estudiante</option>
                  <option value="COLLABORATOR">Ayudante</option>
                  <option value="ADMIN">Admin</option>
                  {/* Add other role options if needed */}
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {formik.errors.role}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group as={Col} xs={12} md={6} controlId="formDateOfBirth">
                <Form.Label>Fecha de Nacimiento</Form.Label>
                <Form.Control
                  type="date"
                  placeholder="Ingresa la fecha de nacimiento"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.dateOfBirth}
                  name="dateOfBirth"
                  isInvalid={formik.touched.dateOfBirth && !!formik.errors.dateOfBirth}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.dateOfBirth}
                </Form.Control.Feedback>
              </Form.Group>
            </Row>

            { formik.values.role === "STUDENT" ? 
              <Row className="mb-3">
                        <Form.Group as={Col} xs={12} md={6} controlId="formRole">
                          <Form.Label>Sección - Para alumnos</Form.Label>
                          <Form.Control
                            as="select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.section}
                            name="section"
                            isInvalid={formik.touched.section && !!formik.errors.section}
                          >
                            <option value="">Selecciona una sección</option>
                            {sections && sections.length > 0 ? (
                              sections.map((section) => (
                                <option key={section.id} value={section.id}>
                                  Sección {section.number}
                                </option>
                              ))
                            ) : (
                              <></>
                            )}
                            {/* Add other role options if needed */}
                          </Form.Control>
                          <Form.Control.Feedback type="invalid">
                            {formik.errors.role}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
            : 
              <></>                
            }

            

            <Form.Group as={Col} xs={12} md={6} controlId="sendPasswordCheckbox" className="mt-2">
                <Form.Check
                  type="checkbox"
                  label="Enviar contraseña inmediatamente"
                  checked={formik.values.sendPasswordImmediately}
                  onChange={formik.handleChange}
                  name="sendPasswordImmediately" />

            </Form.Group>
            <Alert variant="warning" dismissible className="custom-alert">
              <strong>Recuerda:</strong> La contraseña es generada automaticamente y será enviada altiro si seleccionaste el check-box, de lo contrario debes enviarsela
              <Button variant="close" aria-label="Close" />
            </Alert>
            <Button
                type="submit"
                disabled={isDisabled}
                style={{
                  backgroundColor: "#072f75",
                  border: "none",
                }}
                className="btn rounded login-btn"
              >
                  Crear Usuario
                  { loading ? (
                    <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                  ) : (
                    <></>
                  )}
                
              </Button>
            </Form>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default UserForm;

