import React, { useEffect, useState, useCallback } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import { useAuthHeader } from "react-auth-kit";
import axios from "axios";
import {
  Table,
  DatePicker,
  Form,
  Checkbox,
  Button,
  Modal,
  ConfigProvider,
  Popconfirm,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import "../../styles/formularios.css";
import dayjs from "dayjs";
import { useForm } from "antd/lib/form/Form";
import { RequestBuilder } from "../../utils/RequestBuilder";
import { toast } from "react-toastify";

const { RangePicker } = DatePicker;
type DataItem = {
  id: string;
  essayNumber: number;
  sectionNumber: number;
  startDate: string;
  endDate: string;
  stage: string;
  status: string;
};
type filterData = {
  text: string;
  value: string | number;
};

export default function Formularios2() {
  const backendUrl = process.env.REACT_APP_API_URL;
  const schedulesEndpoint = "/schedules";
  const secondStageEndpoint = "/stages/second/";

  const authHeader = useAuthHeader();
  const mapNames: { [key: string]: string } = {
    FIRST: "1",
    SECOND: "2",
    THIRD: "3",
    FOURTH: "4",
    OPEN: "Abierta",
    CLOSED: "Cerrada",
  };

  const [data, setData] = useState<DataItem[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [sectionFilter, setSectionFilter] = useState<filterData[]>([]);
  const [essayFilter, setEssayFilter] = useState<filterData[]>([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalId, setModalId] = useState<string>("");
  const [form] = useForm();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const formatDateTime = (dateTimeString: string): string => {
    const dateTime = new Date(dateTimeString);

    const day = dateTime.getDate().toString().padStart(2, "0");
    const month = (dateTime.getMonth() + 1).toString().padStart(2, "0");
    const year = dateTime.getFullYear();
    const formattedDate = `${year}-${month}-${day}`;

    const hours = dateTime.getHours().toString().padStart(2, "0");
    const minutes = dateTime.getMinutes().toString().padStart(2, "0");
    const formattedTime = `${hours}:${minutes}`;

    return `${formattedDate} ${formattedTime}`;
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `${backendUrl}${schedulesEndpoint}?limit=200&page=1`,
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );
      const data: DataItem[] = response.data.data.schedules.map(
        (schedule: any) => {
          return {
            id: `${schedule.id}`,
            essayNumber: schedule.assignment.number,
            sectionNumber: schedule.assignment.courseSection.number,
            startDate: schedule.startDate
              ? formatDateTime(schedule.startDate)
              : "Sin fecha",
            endDate: schedule.endDate
              ? formatDateTime(schedule.endDate)
              : "Sin fecha",
            stage: mapNames[schedule.stage as keyof typeof mapNames],
            status: mapNames[schedule.status as keyof typeof mapNames],
          };
        }
      );
      const sectionDataFilter = Array.from(
        new Set(data.map((item) => item.sectionNumber))
      )
        .sort((a: number, b: number) => a - b)
        .map((x) => {
          return { text: x.toString(), value: x };
        });
      const essayDataFilter = Array.from(
        new Set(data.map((item) => item.essayNumber))
      )
        .sort((a: number, b: number) => a - b)
        .map((x) => {
          return { text: x.toString(), value: x };
        });
      setEssayFilter(essayDataFilter);
      setSectionFilter(sectionDataFilter);
      setData(data);
      setLoading(false);
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };
  const showModal1 = (recordId: string) => {
    setModalId(recordId);
    setModalVisible(true);
  };

  const closePopup = useCallback(() => {
    form.resetFields();
    setModalVisible(false);
  }, [form]);

  const onSubmit = useCallback(
    async (values: any) => {
      try {
        await axios.put(
          `${backendUrl}${schedulesEndpoint}/${modalId}`,
          {
            startDate: dayjs(values.DateRange[0]).toISOString().slice(0, 19),
            endDate: dayjs(values.DateRange[1]).toISOString().slice(0, 19),
            cascade: values.isAccepted,
          },
          {
            headers: {
              Authorization: authHeader(),
            },
          }
        );
      } catch (error) {
        // console.log(error);
      } finally {
        closePopup();
        window.location.reload();
      }
    },
    [modalId, authHeader, closePopup]
  );

  const handleStatusSecondStage = async (item: DataItem) => {
    try {
      await axios.post(
        `${backendUrl}${secondStageEndpoint}`,
        {
          number: Number(item.essayNumber),
          section: Number(item.sectionNumber),
        },
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );
      window.location.reload();
    } catch (error) {
      // console.log(error);
    }
  };
  const handleStatusFirstStage = async (item: DataItem) => {
    try {
      await axios.put(
        `${backendUrl}${schedulesEndpoint}/${item.id}`,
        {
          status: "CLOSED",
        },
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );

      window.location.reload();
    } catch (error) {
      // console.log(error);
    }
  };

  const columns: ColumnsType<DataItem> = [
    {
      title: "Número de ensayo",
      dataIndex: "essayNumber",
      key: "essayNumber",
      sorter: (a: DataItem, b: DataItem) => a.essayNumber - b.essayNumber,
      filters: essayFilter,
      onFilter: (value, record: DataItem) =>
        record.essayNumber === Number(value),
      width: "15%",
    },
    {
      title: "Sección",
      dataIndex: "sectionNumber",
      key: "sectionNumber",
      filters: sectionFilter,
      onFilter: (value, record: DataItem) =>
        record.sectionNumber === Number(value),
    },
    {
      title: "Etapa",
      dataIndex: "stage",
      key: "stage",
      filters: [
        { text: "1", value: "1" },
        { text: "2", value: "2" },
        { text: "3", value: "3" },
        { text: "4", value: "4" },
      ],
      sorter: (a: DataItem, b: DataItem) =>
        Number(a.stage) - Number(b.essayNumber),
      onFilter: (value, record: DataItem) => record.stage === String(value),
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      render: (_: string, record: DataItem) => (
        <>
          {record.status === "Abierta" && record.stage === "1" ? (
            <Button onClick={() => handleStatusFirstStage(record)}>
              Abierta
            </Button>
          ) : record.status === "Cerrada" && record.stage === "2" ? (
            <Button onClick={() => handleStatusSecondStage(record)}>
              Cerrada
            </Button>
          ) : (
            <p>{record.status}</p>
          )}
        </>
      ),
    },
    {
      title: "Fecha de Inicio",
      dataIndex: "startDate",
      key: "startDate",
    },
    {
      title: "Fecha de Término",
      dataIndex: "endDate",
      key: "endDate",
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      render: (_: string, record: DataItem) => (
        <>
          <Button type="link" onClick={() => showModal1(record.id)}>
            Editar fechas
          </Button>
          <Modal
            title="Basic Modal"
            open={modalVisible}
            onCancel={closePopup}
            onOk={form.submit}
          >
            <Form form={form} onFinish={onSubmit}>
              <Form.Item
                label="Seleccionar fechas"
                name="DateRange"
                rules={[
                  {
                    type: "array" as const,
                    required: true,
                    message: "Ponga las fechas",
                  },
                ]}
              >
                <RangePicker showTime />
              </Form.Item>
              <Form.Item name="isAccepted" valuePropName="checked">
                <Checkbox defaultChecked={false}>
                  Cambiar también fechas de etapas siguientes del ensayo
                </Checkbox>
              </Form.Item>
            </Form>
          </Modal>
        </>
      ),
    },
  ];

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleRunLambda = async (e: any) => {
    e.preventDefault();
    setConfirmLoading(true);

    //  endpoint para activar lambdas
    // /caller/cron
    try {
      const response = await RequestBuilder.new(authHeader()).post(`/stages/caller/cron`, {})
      toast.success("Lambda llamada correctamente", {
        position: "bottom-right",
      });
    } catch (err) {
      toast.error("Hubo un problema al llamar a lambda, revisar Rollbar", {
        position: "bottom-right",
      });
    }

    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };


  return (
    <div className="Admin">
      <AdminNavbar />
      <div className="title-text">Formularios</div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 mx-auto mt-0">
            <div className="px-2">
              <div className="rounded bg-white p-2 border border-1">
              <Popconfirm
                  title="¿Activar Lambdas?"
                  description="Activalas si necesitas cerrar u activar alguna etapa y aún no ocurre"
                  open={open}
                  onConfirm={(e) => handleRunLambda(e)}
                  okButtonProps={{ loading: confirmLoading }}
                  onCancel={handleCancel}
                >
                  <Button type="primary" onClick={showPopconfirm} className="col-12 col-md-2">
                    Activar Lambda
                  </Button>
                </Popconfirm>
              </div>
            </div>
            <div className="tabla px-2 mt-2">
                <ConfigProvider
                  theme={{
                    token: {
                      fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
              'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
              sans-serif`,
                      colorIcon: "white",
                    },
                    components: {
                      Table: {
                        /* here is your component tokens */
                        headerBg: "#072f75",
                        headerColor: "#f0f0f0",
                        headerSortHoverBg: "#1165f6",
                        headerSortActiveBg: "#072f75",
                      },
                    },
                  }}
                >
                  <Table
                    rowKey={(record) => record.id}
                    bordered
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    pagination={{
                      pageSize: pageSize,
                      total: data.length,
                      showSizeChanger: true,
                      pageSizeOptions: ["5", "10", "20", "50"],
                      onChange: handleChangePageSize,
                    }}
                  />
                </ConfigProvider>
            </div>
      </div>
      </div>
      </div>
    </div>
  );
}
