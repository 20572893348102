// Import necessary modules from React and Ant Design
import { Alert } from 'antd';
import React, { useEffect, useState } from 'react';
import { Form, Button, InputGroup, Row, Col } from 'react-bootstrap';

// Define the interface for Data2 if not already done
interface Data2 {
  id: any;
  scoreDelta: number;
  collaboratorComment: string | null | undefined;
}

// Define the interface for the props of this component
interface CorrectionModalProps {
    scoreOfStage: number;
    studentComment: string;
    collaboratorComment: string | null | undefined;
    scoreDelta: number;
    stage: number;
    url: string;
    update: any;
    requestComment: string;
    stageTwoCorrection?: string;
}

// Define the CorrectionModal component
const FormRecorrectionModal: React.FC<CorrectionModalProps> = ({
    scoreOfStage,
    studentComment,
    collaboratorComment,
    scoreDelta,
    stage,
    url,
    update,
    requestComment,
    stageTwoCorrection
}) => {
    
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const [editingRevision, setEditingRevision] = useState<Data2 | null>({
    scoreDelta: scoreDelta,
    collaboratorComment: collaboratorComment,
    id: null
  });

  const handleUpdate = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSpinners(true);

    update(editingRevision?.collaboratorComment, editingRevision?.scoreDelta);
  };

  const setSpinners = (status: boolean) => {
    setIsDisabled(status);
    setLoading(status);
  }


  useEffect(() => {
    setEditingRevision({
      scoreDelta: scoreDelta,
      collaboratorComment: collaboratorComment,
      id: null
      }
    )
  }, [scoreDelta, collaboratorComment])
  

  return (
    <>
      <Alert
        message="Estas correcciones son generadas por estudiantes. No por sistema, no son casos MU ni NC. Si algún comentario llega vacío es porque fue enviado así."
        type="warning"
        closable
        className='px-2 mx-4 mb-1'
      />
      {
        stage === 2 ? 
        <>
              <Form onSubmit={handleUpdate} className="px-2 px-lg-4">
                <Form.Group controlId="url">
                  <Form.Label>Url del ensayo:</Form.Label>
                  <InputGroup>
                    <InputGroup>
                      <Button variant="primary" size="sm" href={url} target="_blank">
                        Link Ensayo
                      </Button>
                    </InputGroup>
                  </InputGroup>
                </Form.Group>

                <Row>
                  <Col md={6}>
                    <Form.Group controlId="score" className='mt-2'>
                      <Form.Label>
                        Puntaje de corrección Asignado:
                      </Form.Label>
                      <Form.Control
                        type="number"
                        size="sm"
                        value={scoreOfStage}
                        disabled={true}
                      />
                    </Form.Group>
                  </Col>

                  <Col md={6}>
                    <Form.Group controlId="scoreDelta" className='mt-2'>
                      <Form.Label>
                        Delta por asignar a corrección:
                      </Form.Label>
                      <Form.Control
                        type="number"
                        size="sm"
                        min={0.0- scoreOfStage}
                        max={10.0 - scoreOfStage}
                        step={0.5}
                        value={editingRevision?.scoreDelta}
                        onChange={(e) => {
                          setEditingRevision((prevEditingRevision) => {
                            if (prevEditingRevision) {
                              return {
                                ...prevEditingRevision,
                                scoreDelta: parseFloat( e.target.value),
                              };
                            }
                            return null;
                          });
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="studentComment" className='mt-2'>
                  <Form.Label>Comentario del Corrector de ensayo:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={studentComment || ''}
                    disabled
                    style={{
                      backgroundColor: '#f0f0f0',
                      color: 'black',
                    }}
                  />
                </Form.Group>

                <Form.Group controlId="studentComment" className='mt-2'>
                  <Form.Label>Comentario de petición de recorrección:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={requestComment || ''}
                    disabled
                    style={{
                      backgroundColor: '#f0f0f0',
                      color: 'black',
                    }}
                  />
                </Form.Group>

                <Form.Group controlId="collaboratorComment" className='mt-2'>
                  <Form.Label>Comentario del Ayudante:</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    defaultValue={collaboratorComment || ''}
                    onChange={(e) => {
                      setEditingRevision((prevEditingRevision) => {
                        if (prevEditingRevision) {
                          return {
                            ...prevEditingRevision,
                            collaboratorComment: e.target.value,
                          };
                        }
                        return null;
                      });
                    }}
                  />
                </Form.Group>

                <Button type="submit" variant="primary"  className='mt-2' disabled={isDisabled}>
                  Enviar corrección
                  {loading ? (
                            <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                          ) : (
                            <></>
                          )}
                </Button>
              </Form>
        
        </> 
        : 
        <>
        <Form onSubmit={handleUpdate} className="px-2 px-lg-4">
          <Form.Group controlId="url">
            <Form.Label>Url del ensayo:</Form.Label>
            <InputGroup>
              <InputGroup>
                <Button variant="primary" size="sm" href={url} target="_blank">
                  Link Ensayo
                </Button>
              </InputGroup>
            </InputGroup>
          </Form.Group>

          <Row>
            <Col md={6}>
              <Form.Group controlId="score" className='mt-2'>
                <Form.Label>
                  Puntaje de etapa 3 Asignado:
                </Form.Label>
                <Form.Control
                  type="number"
                  size="sm"
                  value={scoreOfStage}
                  disabled={true}
                />
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="scoreDelta" className='mt-2'>
                <Form.Label>
                  Delta por asignar a corrección:
                </Form.Label>
                <Form.Control
                  type="number"
                  size="sm"
                  min={0 - scoreOfStage}
                  max={3 - scoreOfStage}
                  step={1}
                  value={editingRevision?.scoreDelta}
                  onChange={(e) => {
                    setEditingRevision((prevEditingRevision) => {
                      if (prevEditingRevision) {
                        return {
                          ...prevEditingRevision,
                          scoreDelta: parseInt( e.target.value,10),
                        };
                      }
                      return null;
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          {/* <Form.Group controlId="studentComment" className='mt-2'>
            <Form.Label>Comentario corrección etapa 3:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={studentComment || ''}
              disabled
              style={{
                backgroundColor: '#f0f0f0',
                color: 'black',
              }}
            />
          </Form.Group> */}


          <Form.Group controlId="studentComment" className='mt-2'>
            <Form.Label>Comentario corrección etapa 2 hecho:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={stageTwoCorrection || ''}
              disabled
              style={{
                backgroundColor: '#f0f0f0',
                color: 'black',
              }}
            />
          </Form.Group>

          <Form.Group controlId="studentComment" className='mt-2'>
            <Form.Label>Comentario de petición de recorrección:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              value={requestComment || ''}
              disabled
              style={{
                backgroundColor: '#f0f0f0',
                color: 'black',
              }}
            />
          </Form.Group>

          <Form.Group controlId="collaboratorComment" className='mt-2'>
            <Form.Label>Comentario del Ayudante:</Form.Label>
            <Form.Control
              as="textarea"
              rows={3}
              defaultValue={collaboratorComment || ''}
              onChange={(e) => {
                setEditingRevision((prevEditingRevision) => {
                  if (prevEditingRevision) {
                    return {
                      ...prevEditingRevision,
                      collaboratorComment: e.target.value,
                    };
                  }
                  return null;
                });
              }}
            />
          </Form.Group>

          <Button type="submit" variant="primary"  className='mt-2' disabled={isDisabled}>
            Enviar corrección
            {loading ? (
                      <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    ) : (
                      <></>
                    )}
          </Button>
    </Form>

        </>

      }
    </>
  );
};

export default FormRecorrectionModal;
