import React, { ChangeEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Container, Button, Form, Col, Table, Pagination } from "react-bootstrap";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import "../../styles/customForms.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAuthHeader } from "react-auth-kit";
import { useParams } from "react-router-dom";

const LoadUsers: React.FC = () => {
  const authHeader = useAuthHeader();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("Por favor, selecciona un archivo CSV.")
      .test(
        "fileType",
        "El archivo debe ser un archivo CSV.",
        (value) => value instanceof File && value.name.endsWith(".csv")
      ),      
  });

  const formik = useFormik({
    initialValues: {
      file: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setSpinners(true);
      try {
        if (values.file) {
          const formData = new FormData();
          const backendUrl = process.env.REACT_APP_API_URL;
          const loadUsers = "/users/loadUsers"
          formData.append("file", values.file);
          

          try {
            const response = await axios.post(`${backendUrl}${loadUsers}`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: authHeader(),
              },
            });
            toast.success('Archivo subido exitosamente', {
              position: "bottom-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
            });
            setSpinners(false);
          } catch (error) {

            toast.error(`Error al subir el archivo: ${ error|| 'Error desconocido'}`, {
                position: "bottom-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
            });
            setSpinners(false);
          }
        }
      } catch (error) {
      }
    },
  });


  const setSpinners = (status: boolean) => {
    setIsDisabled(status);
    setLoading(status);
  }

  return (
    <div className="Admin">
      <AdminNavbar />
      <Container className="mt-3  col-lg-6">
        <div
          className="alert alert-warning alert-dismissible fade show custom-alert"
          role="alert"
        >
          <strong>Recuerda:</strong> Esta sección es para cargar los alumnos al inicio del semestre junto con enviar sus respectivas contraseñas.
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
        <div className="d-flex justify-content-center rounded pb-2 custom-bg-gray">

          <Form className="col-lg-6 col-10" onSubmit={formik.handleSubmit}>
            <div className="title-text py-4 mb-0">Ingresar Alumnos</div>
            <div className="alerta">
            </div>
            <Form.Group as={Col} controlId="formFile" className="mb-3">
              <Form.Label>Selecciona un archivo CSV</Form.Label>
              <Form.Control
                type="file"
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue("file", e.currentTarget.files?.[0]);
                }}
                isInvalid={!!formik.errors.file}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.file}
              </Form.Control.Feedback>
            </Form.Group>
            <div className="btn-group">
              <Button
                type="submit"
                disabled={isDisabled}
                style={{
                  backgroundColor: "#072f75",
                  border: "none",
                }}
                className="btn rounded login-btn"
              >
                Cargar personas
                { loading ? 
                  <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                  
                  :
                    <></>
                }
              </Button>
              
            </div>
            
          </Form>
          
        </div>
        
      </Container>

      <ToastContainer />
    </div>
  );
};

export default LoadUsers;