import React, { useState, useEffect } from "react";
import axios from 'axios'; // 
import RubricE3Modal from './RubricE3';
import { Evaluation, Homework3, BoxProps3 } from "./SharedTypes";
import { useAuthHeader } from "react-auth-kit";
import EvaluationModal from "./EvaluationModal";



function formatDate(dateString: string | null) {
  if (dateString === null) {
    return null;
  }
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1; 
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
  const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  return `${formattedDate}, ${formattedTime}`;
}


const EvaluationBox: React.FC<BoxProps3> = ({
  type,
  homeworkList: initialHomeworkList,
}) => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const stageEndpoint = "/stages/third";
  const [homeworks, setHomeworks] = useState<Homework3[]>(initialHomeworkList);
  const [showModal, setShowModal] = useState(false);
  const [ratingGiven, setRatingGiven] = useState<number>(0.0);
  const [currentEvaluation, setCurrentEvaluation] = useState<Evaluation | null>(null);
  const [currentHW, setCurrentHW] = useState<Homework3 | null>(null);
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);
  const [isToggleOn, setIsToggleOn] = useState(false); 
  const [delivered, setDelivered] = useState(false);
  const [showRubricModal, setShowRubricModal] = useState(false);

  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);


  useEffect(() => {
    const initializedHomeworks = initialHomeworkList.map((hw) => ({
      ...hw,
      evaluations: hw.evaluations 
    }));
    setHomeworks(initializedHomeworks);

  }, [initialHomeworkList]);

  const sendData = async (correctionScore: number, reviewId: number) => {
    try {
      const response = await axios.put(`${backendUrl}${stageEndpoint}/${reviewId}`, {
        correctionScore: correctionScore
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      window.location.reload();
      

    } catch (error) {
      // console.error('Error al realizar la solicitud PUT:', error);
    }
  };

  const handleOpenModal = (evaluation: Evaluation, index: number, hw: Homework3) => {
    setCurrentEvaluation(evaluation);
    setCurrentHW(hw);
    setActiveAccordion(index);
    if (evaluation.reviewText) {
      setDelivered(true);
      
      
    } else {
      setDelivered(false);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleAccordionClick = (index: number) => {
    const hw = homeworks[index];
    if (activeAccordion === index || hw.status === "CLOSED") {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(index);
    }
  };

  return (
    <div className={`homework-box ${type} container mt-3`}>
      <h2 style={{ color: "#072f75" }}>
        {type === "pending" ? "Próximas Tareas" : "Tareas Anteriores"}
      </h2>
      {homeworks.map((hw: Homework3, index: number) => (
        <div key={index} className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === index ? "active" : ""}`}
                type="button"
                onClick={() => handleAccordionClick(index)}
              >
                <span className="hw-title" style={{ width: "200px" }}> Ensayo {hw.assignmentNumber} </span>
                <span className="ml-auto fecha-title">
                  {new Date(hw.startDate) > new Date() ? 
                    `No disponible hasta: ${formatDate(hw.startDate)}` : 
                    hw.status === "CLOSED" ?
                      "Evaluación cerrada" :
                      `Cierre: ${formatDate(hw.deadline)}`
                  }
                </span>
              </button>
            </h2>
            <div
               className={`accordion-collapse collapse ${activeAccordion === index && new Date(hw.startDate) <= new Date() ? "show" : ""}`}
            >
              <div className="accordion-body">
              {hw.evaluations && hw.evaluations
                .slice()  
                .sort((a, b) => a.reviewId - b.reviewId)  
                .map((evaluation, evaluationIndex) => (
                  <div
                    key={evaluationIndex}
                    className={`correction-row mt-3 clickable ${evaluation.flowStatus === "OUT" ? "disabled" : ""}`}
                      onClick={() => evaluation.flowStatus !== "OUT" && handleOpenModal(evaluation, index, hw)}
                  >
                    Evaluación {evaluationIndex + 1}
                    {evaluation.flowStatus === "OUT" ? (
                      <div className="points1">
                        Quedaste fuera del flujo
                      </div>
                    ) : (
                      <>
                        {evaluation.correctedCorrection === true ? (
                          <div className="points1">
                            {evaluation.correctedReview ? (
                              <span>Evaluación a corrector: {evaluation.ratingGiven}/3</span>
                            ) : (
                              <span>Entregar evaluación</span>
                            )}
                          </div>
                        ) : (
                          <div className="points1">
                            <span>Aún no te han corregido</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))
              }
              </div>
            </div>
          </div>
        </div>
      ))}


      <EvaluationModal  
        currentEvaluationSent={currentEvaluation} 
        handleCloseModal={handleCloseModal} 
        showModal={showModal} 
        setShowRubricModal={setShowRubricModal}
        currentHW={currentHW}
        /> 
      <RubricE3Modal showRubricModal={showRubricModal} setShowRubricModal={setShowRubricModal} />

    </div>
  );
};

export default EvaluationBox;