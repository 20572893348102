import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CollaboratorNavbar from '../../components/CollaboratorNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../styles/popUpRecorrecion.css';
import { Table, Modal, Button, Form, Input, InputNumber, Tag, Alert, Divider } from 'antd';
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import TextArea from 'antd/lib/input/TextArea';
import * as Yup from "yup";
import { toast } from 'react-toastify';
import { Formik, useFormik } from 'formik';

interface Attachment2 {
  essayFileName: string;
  url: string;
}

interface Assignment {
  number: number;
}

interface Correction2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number;
  attachment: Attachment2;
}

interface CorrectionsMade2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number;
  attachment: Attachment2;
}

interface AssignmentUser2 {
  assignment: Assignment
  id: number;
  status: string;
  user: {
    name: string;
    lastName: string;
    email: string;
    studentNumber: string;
  };
  corrections: Correction2[];
  correctionsMade: CorrectionsMade2[];
}

interface CorrectionData2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number | null;
  attachment: Attachment2;
}

interface Data2 {
  id: number;
  collaboratorId: number;
  requesterId: null | number;
  scoreDelta: null | number;
  studentComment: string;
  collaboratorComment: null | string;
  corrected: boolean;
  stage: string;
  assigmentUser: AssignmentUser2;
  correction: CorrectionData2;
}

interface ApiResponse {
  data: Data2[];
}

const AyudanteEtapa2: React.FC = () => {
  const [revisions, setRevisions] = useState<Data2[]>([]);
  const [editingRevision, setEditingRevision] = useState<Data2 | null>(null);
  const [isCorrectionModalOpen, setIsCorrectionModalOpen] = useState(false);
  const [editingCorrection, setEditingCorrection] = useState<CorrectionData2 | null>(null);
  const [modalFormValues, setModalFormValues] = useState<CorrectionData2 | null>(null);
  const [editedJustification, setEditedJustification] = useState<string>("");
  const [editedScore, setEditedScore] = useState<number | null>(null);
  const [saving, setSaving] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isDisabled, setIsDisabled] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);
  const authUser = useAuthUser();
  const userId = authUser()?.id;

  const fetchData = async () => {
    formik.values.SearchType = 'false';
    setFilterLoading(true);
    try {
      const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/${userId}?stage=SECOND`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response2.data && response2.data.data) {
        const filteredData = response2.data.data.filter((item: Data2) =>
        (item?.assigmentUser && item?.stage === "SECOND" && item?.correction?.corrected === false) || 
        (item?.assigmentUser && item?.stage === "SECOND" && item?.correction?.corrected === true && (item?.correction?.score === -1 || item?.correction?.score === null))
        );
        setRevisions(filteredData);
      }
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
    setFilterLoading(false);
  };

  useEffect(() => {    
    fetchData();
  }, []); // Removed unused dependencies


    const handleEditClick = (revision: Data2) => {
      setEditingRevision(revision);
      setEditedJustification(revision.correction.justification);
      setEditedScore(revision.correction.score);
      setIsCorrectionModalOpen(true);
    };

    const handleModalSave = async () => {
      setSaving(true);
      try {
        // Make PUT request to update the data
        await axios.put(`${process.env.REACT_APP_API_URL}/stages/collaborator/second/${editingRevision?.correction.id}`, {
          justification: editedJustification,
          score: editedScore,
          containsAuthorData: false, 
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // update revision
        await UpdateRevision(editingRevision)

        const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/${userId}?stage=SECOND`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
    
        if (response2.data && response2.data.data) {
          const filteredData = response2.data.data.filter((item: Data2) =>
            (item?.assigmentUser && item?.stage === "SECOND" && item?.correction?.corrected === false) || 
            (item?.assigmentUser && item?.stage === "SECOND" && item?.correction?.corrected === true && (item?.correction?.score === -1 || item?.correction?.score === null))
          );
          setRevisions(filteredData);
        }
    
        // Close the modal and update the local state
        setIsCorrectionModalOpen(false);
        setEditingRevision(null);
        setEditedJustification("");
        setEditedScore(null);
        setSaving(false);

        // window.location.reload();
    
        // Refetch the data or update the local state as needed
        // ...
      } catch (error) {
        setSaving(false);
        // console.error('Error updating data:', error);
      }
    };

    const UpdateRevision = async (editingRevision: any) => {
      try {
        // Make PUT request to update the data
        await axios.put(`${process.env.REACT_APP_API_URL}/revisions/${editingRevision?.id}`, {
          scoreDelta: 0,
          corrected: true, 
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }); 
      } catch (error) {
        setSaving(false);
        // console.log(error)
      }
    }

    const validationSchema = Yup.object({
      SearchType: Yup.string().required('Option is required'),
    });

    const handleSubmitForm = async (values: any) => {
      try {
        setFilterLoading(true);
        const extraValue = values.SearchType != '' ? `&corrected=${values.SearchType === 'true' ? true : false}` : '';
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/${userId}?stage=SECOND${extraValue}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data && response.data.data) {
          const filteredData = response.data.data.filter((item: Data2) =>
          (item?.assigmentUser && item?.stage === "SECOND" && item?.correction?.corrected === false) || 
          (item?.assigmentUser && item?.stage === "SECOND" && item?.correction?.corrected === true && (item?.correction?.score === -1 || item?.correction?.score === null))
          );
          setRevisions(filteredData);
        }
        setFilterLoading(false);
      } catch (error) {
        setFilterLoading(false);
        toast.error(`Error al cargar datos`, {
          position: "top-center",
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
        });
      }
    };

    const formik = useFormik({
      initialValues: {
        SearchType: '',
      },
      validationSchema: validationSchema,
      onSubmit: handleSubmitForm,
    });
  
    const handleChangePageSize = (current: number, newSize: number) => {
      setPageSize(newSize);
    };
  

    return (
        <div className="Admin">
          <CollaboratorNavbar />
          <div className="container">
            <div className="text-center my-4">
              <div className="title-text">Recorreccion Etapa 2 Irregular </div>
            </div>
            <Alert
                message="Si la segunda columna es no 'No', implica que no fue corregido por alumnos, es decir, deben corregirlo como alumnos. En caso de que sea un 'Si', implica que es un caso de datos de autor, deben revisar si posee datos de autor y asignar el puntaje correspondiente. Si consideran que posee datos de autor, entonces asignar puntaje -1."
                type="warning"
                closable
            />

            <div className="rounded bg-white p-2 my-2">
              <Formik
                    initialValues={{
                      SearchType: 'false',
                    }}
                    onSubmit={(e) => { handleSubmitForm(formik.values); }}
                  >
                    <Form className="row g-2 align-items-center">
                      {/* Dropdown */}
                      <div className="col-auto">
                          <select
                            id="SearchType"
                            name="SearchType"
                            className="form-select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.SearchType}
                          >
                            <option value="false">No corregidos</option>
                            <option value="true">Corregidos</option>
                          </select>
                      </div>

                      {/* Submit button */}
                      <div className="col-auto">
                        <Button type='primary' onClick={(e) => handleSubmitForm(formik.values)}>
                          Filtrar
                          {filterLoading && (
                            <span className="spinner-border spinner-border-sm ms-2" aria-hidden="true"></span>
                          )  
                          }
                        </Button>
                      </div>
                      <Button
                        disabled={isDisabled}
                        className="w-auto px-4 py-auto btn-secondary"
                        onClick={fetchData}
                      >
                        Resetear filtros
                        {filterLoading && (
                         <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        )  
                        }
                      </Button>
                    </Form>
              </Formik>
            </div>
            <Table
              dataSource={revisions}
              rowKey="id"
              onRow={(record) => ({
                onClick: () => handleEditClick(record),
              })}
            >
              <Table.Column
                          title="Numero de Ensayo"
                          dataIndex={["assigmentUser", "assignment", "number"]}
                          key="assigmentUser.assignment.number"
                        />
                        <Table.Column
                          title="Corregida Etapa 2"
                          key="corregido"
                          dataIndex={['correction', 'corrected']}
                          render={(corrected: boolean) => (
                            <span>{corrected ? 'Sí' : 'No'}</span>
                          )}
                        />
                        <Table.Column
                          title="Corregido por ayudante"
                          key="corrected"
                          dataIndex={['corrected']}
                          render={(corrected: boolean) => (
                            <span>{corrected ? 'Sí' : 'No'}</span>
                          )}
                        />
                        <Table.Column
                          title="Email Estudiante"
                          dataIndex={["assigmentUser", "user", "email"]}
                          key="assigmentUser.user.email"
                        />
                        <Table.Column
                          title="Puntaje de Corrección"
                          dataIndex={['correction', 'score']}
                          key="correction.score"
                          render={(score: number, record: Data2) => (
                            <span>{record.correction.corrected ? score : 'No corregido aún'}</span>
                          )}
                        />
                        <Table.Column
                          title="URL Ensayo"
                          dataIndex={["assigmentUser", "corrections", 0, "attachment", "url"]}
                          key="assigmentUser.corrections.0.attachment.url"
                          render={(url) => <a href={url} target="_blank" rel="noopener noreferrer">Link</a>}
                        />
                        <Table.Column
                          title="Recorregir"
                          key="recorregir"
                          render={(_, record: Data2) => (
                            <Button type="primary" onClick={() => handleEditClick(record)}>
                              Recorregir
                            </Button>
                          )}
                          />
              </Table>
              <Modal
                title="Editar Corrección"
                visible={isCorrectionModalOpen}
                onOk={handleModalSave}
                width={800} 
                className="custom-modal"
                onCancel={() => setIsCorrectionModalOpen(false)}                  
                footer={
                  <>
                    <Button 
                      onClick={handleModalSave}
                      type='primary'
                      key={ Math.floor(Math.random() * 1000)}
                      >
                        Guardar
                        { saving ? <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span> : <></>}
                    </Button>
                  </>
                
                }
              >
                <Form>

                       {/* <Button variant="primary" size="sm" href={url} target="_blank">
                        Link Ensayo
                      </Button> */}
                    <Divider orientation="left">Datos</Divider>
                    <p>
                      <strong>Corregido:</strong> { editingRevision && editingRevision.correction.corrected === true ? 'Esta corrección esta corregida por alumnos': 'Esta corrección no fue corregida por alumnos'}
                    </p>                    
                    <p>
                      <strong>Comentario extra:</strong> { editingRevision && editingRevision.studentComment ? editingRevision.studentComment : '--'}
                    </p>
                    
                  <Form.Item>
                    <p><strong>Justificación:</strong></p>
                    <TextArea
                      className='h-40vw'
                      value={editedJustification}
                      onChange={(e) => setEditedJustification(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item label="Score">
                    <InputNumber
                      value={editedScore}
                      max={10}
                      min={-1}
                      step={0.5}
                      onChange={(value) => setEditedScore(value)}
                    />
                  </Form.Item>
                </Form>
                <Divider orientation="left" >Información</Divider>
                <p>Si contiene datos de autor (-1), pueden dejarlo como está o pueden cambiarlo, al ser etapa 2, pueden asignarles hasta 10 puntos (dejar -1 solamente si posee datos de autor).</p>
                <p>La justificacón puede venir vácia por el hecho de que no fue corregido por un alumno, usen ese mismo box para rellenar con comentarios. Si viene justificacion es posiblemente por ser un caso de datos de autor.</p>
                <p>Tienen una oportunidad para corregir la corrección, en caso de error pedir a Joaquin que se los habilite de nuevo.</p>
                <Divider />
              </Modal>
    </div>
  </div>
  );
};

export default AyudanteEtapa2; 
