import React, { useState, useEffect} from 'react';    
import { Formik, Form, Field, ErrorMessage } from 'formik'; // borrar FieldArray si no se usa
import * as Yup from 'yup'; 
import axios from "axios"; 
import { Modal, Button} from 'react-bootstrap';
import "bootstrap/dist/css/bootstrap.min.css";
import AdminNavbar from '../../components/AdminNavbar';
import { ToastContainer} from 'react-toastify'; // borrar toast si no se usa
import 'react-toastify/dist/ReactToastify.css';
import "../../styles/customForms.css";
import { useAuthHeader } from "react-auth-kit";

//Cambiar los nombres de las variables a ingles
type FormData = {
  ensayo: string;
  seccion: string;
}

const initialValues: FormData = {
    ensayo: '',
    seccion: '',
  }

//validacion del formulario 
const validationSchema = Yup.object().shape({
    ensayo: Yup.number().required('El ensayo es requerido'), //arreglar esta validacion 
    seccion: Yup.number().required('La sección es requerida'),
});

export default function Downloads() {
    const [showError, setShowError] = useState(false);  
    const handleCloseError = () => setShowError(false);
    const [errorData, setErrorData] = useState('');
    const [isDisabled, setIsDisabled] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sections, setSections] = useState<any[]>([]);
    const [files, setFiles] = useState<any[]>([]);
    const [successfulDownload, setSuccessfulDownload] = useState(false);
    const backendUrl = process.env.REACT_APP_API_URL;
    const sectionsURL = "/sections";
    const filesURL = "/files";

    const authHeader = useAuthHeader();
    
    const getsections = async () => {
      try {
        const response = await axios.get(`${backendUrl}${sectionsURL}`, { headers: { Authorization: authHeader() } });
        if (response.data) {
          setSections(response.data.data);
        } else {
          console.error("La respuesta de la API está vacía.");
        }
      }
      catch (error) {
        console.error("Ocurrió un error inesperado:", error);
      }
    }

    useEffect(() => {
      getsections();
    },[]);

    const getfiles = async () => {
      try {
        const response = await axios.get(`${backendUrl}${filesURL}`, { headers: { Authorization: authHeader() } });
        if (response.data) {
          setFiles(response.data.data);
        } else {
          console.error("La respuesta de la API está vacía.");
        }
      }
      catch (error) {
        console.error("Ocurrió un error inesperado:", error);
      }
    }
    
    const handleSubmit = async (values: FormData) => {
        setIsDisabled(true);
        setLoading(true);
        try {
          const response = await axios.post(`${backendUrl}/files/scores/generate?number=${values.ensayo}&sectionNumber=${values.seccion}`,
             values, 
             { headers: { Authorization: authHeader() } 
            }
          );
          if (response.status === 201) {
            setIsDisabled(false);
            setLoading(false);
            setSuccessfulDownload(true); // Indicar que la descarga fue exitosa
          }              
        } catch (error) {
          setIsDisabled(false);
          setLoading(false);
          if (axios.isAxiosError(error) && error.response && error.response.status === 400) {
            if (error.response.data) {
              setErrorData(error.response.data.message);
              setShowError(true);
            }
          }
      }
    }; 

    useEffect(() => {
      if (successfulDownload) {
        getfiles(); // Ejecutar getfiles() si la descarga fue exitosa
        setSuccessfulDownload(false); // Reiniciar el estado para futuras descargas
      }
    }, [successfulDownload]);
    
    useEffect(() => {
      if (files.length > 0) {
        const fileWithMaxId = files.reduce((max, file) => (file.id > max.id ? file : max), files[0]);
        if (fileWithMaxId) {
          window.open(fileWithMaxId.url, '_blank');
        }
      }
    }, [files]);

    return (
      <div className="Admin">
      <AdminNavbar />
        <div className="container mt-3 col-lg-6">
        <div className="d-flex justify-content-center rounded pb-2 custom-bg-gray">
        <div className="col-lg-6 col-8">
        <div className="title-text py-4 mb-0">Descargas</div>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}       
          >
            {({errors, touched, isSubmitting, values, setFieldValue}) => (
          <Form>
            <div className="form-group mb-3">
              <label htmlFor="ensayo">Seleccionar un ensayo para descargar los puntajes</label>
              <Field type='number' as="select" name="ensayo" className={`form-control ${touched.ensayo && errors.ensayo ? 'is-invalid' : ''}`}>
                <option value="">Selecciona un ensayo</option>
                <option value= {1}>Ensayo 1</option>
                <option value= {2}>Ensayo 2</option>
                <option value= {3}>Ensayo 3</option>
                <option value= {4}>Ensayo 4</option>
                <option value= {5}>Ensayo 5</option>
                <option value= {6}>Ensayo 6</option>
                <option value= {7}>Ensayo 7</option>
                <option value= {8}>Ensayo 8</option>
                <option value= {9}>Ensayo 9</option>
                <option value= {10}>Ensayo 10</option>
                <option value= {11}>Ensayo 11</option>
                <option value= {12}>Ensayo 12</option>
              </Field>
              <ErrorMessage name="ensayo" component="div" className="invalid-feedback" />
            </div>
            <div className="form-group mb-3">
              <label>Seleciona una sección</label>
                <div>
                {sections.map((seccion, index) => (
                <div key={index}>
                    <input
                    type="radio"
                    id={`seccion-${index}`}
                    value={seccion.id}
                    name="seccion"
                    checked={values.seccion === seccion.id}
                    onChange={() => {
                        setFieldValue('seccion', seccion.id, true);
                    }}
                    />
                    <label htmlFor={`seccion-${index}`}>{`Sección ${seccion.id}`}</label>
                </div>
                ))}
                </div>
                <ErrorMessage
                  name="seccion"
                  component="div"
                  className="invalid-feedback"
                />
            </div>
            <div className='d-flex justify-content-center align-items-center'>
              <button
                type="submit"
                disabled={isDisabled}
                style={{
                  backgroundColor: "#072f75",
                  border: "none",
                }}
                className="btn login-btn d-flex justify-content-center align-items-center"
              >
                Descargar Puntajes
                { loading ? 
                  <div className="spinner-border mx-2" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                :
                  <></>
                }
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
    </div>
    </div>
    <Modal show={showError} onHide={handleCloseError}>
        <Modal.Header closeButton>
          <Modal.Title>Hubo un problema con la descarga</Modal.Title>
        </Modal.Header>
        <Modal.Body>{errorData}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseError}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
    
    );
};

