import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import "../../styles/customForms.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthHeader } from "react-auth-kit";
import { useParams } from "react-router-dom";
import { ConfigProvider, DatePicker, Table, Popconfirm } from "antd";
import { ColumnsType } from "antd/es/table";
import { Button, Col, Container, Row } from "react-bootstrap";
import { RequestBuilder } from "../../utils/RequestBuilder";

const Files: React.FC = () => {
  const authHeader = useAuthHeader();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [sectionFilter, setSectionFilter] = useState<any>();
  const { page, limit } = useParams();
  const [pageSize, setPageSize] = useState<number>(10);
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const setSpinners = (status: boolean) => {
    setIsDisabled(status);
    setLoading(status);
  }

  const columns: ColumnsType<any> = [
    // {
    //   title: "Sección",
    //   dataIndex: "section",
    //   key: "section",
    //   sorter: (a: any, b: any) =>
    //     Number(a.section) - Number(b.section),
    //   filters: sectionFilter,
    //   onFilter: (value, record: any) =>
    //     record.section.indexOf(String(value)) === 0,
    // },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      filters: [
        { text: 'Reporte', value: 'REPORT' },
        { text: 'Recorrecciones', value: 'RECORRECTIONS' },
        { text: 'Resumen', value: 'SUMMARY' },
      ],
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
    {
      title: "Estado",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: 'OK', value: 'OK' },
        { text: 'Fallido', value: 'FAILED' },
        { text: 'Pendiente', value: 'PENDING' },
      ],
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
    {
      title: "Fecha",
      dataIndex: "date",
      key: "date"
    },
    {
      title: "Accion",
      dataIndex: "link",
      key: "link",
      render:(_, record) => (
        <Button variant="primary" size="sm" target="_blank" href={`${record.link}`} >
          Descargar
        </Button>
      ),
    },
  ];


  const setFilesList = async () => {
    setSpinners(true);
    const usersEndpoint = "/files";
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${usersEndpoint}?limit=${limit}&page=${page}`,
        { headers: { Authorization: authHeader() } }
      );
      const files_data = response.data.data;
      const tableData: any[] = files_data.map(
        (item: any, index: number) => {
          return {
            id: `${item.id}`,
            // section: `${item.courseSection?.number || '-'}`,
            name: `${item.filename}`,
            type: `${item.file_type}`,
            status: `${item.status}`,
            link: `${item.url}`,
            date: `${item.createdAt}`
          };
        }
      );
    //   const sectionDataFilter = Array.from(
    //     new Set(tableData.map((item) => item.section))
    //   )
    //     .sort((a: string, b: string) => Number(a) - Number(b))
    //     .map((x) => {
    //       return { text: x.toString(), value: x.toString() };
    //   });
    //   setSectionFilter(sectionDataFilter);
      setData(tableData);
      setSpinners(false);
    } catch (error) {
      setSpinners(false);
      toast.error(`Error al cargar datos: ${error || 'Error desconocido'}`, {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
      });
    }
  }

  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };

  const showPopconfirm = () => {
    setOpen(true);
  };

  const handleRunGenericFile = async (e: any) => {
    e.preventDefault();
    setConfirmLoading(true);

    //  endpoint para activar lambdas
    // /caller/cron
    try {
      const response = await RequestBuilder.new(authHeader()).post(`/files/scores/summarize`, {})
      toast.success("Archivo pedido correctamente", {
        position: "bottom-right",
      });
    } catch (err) {
      toast.error("Hubo un problema al pedir el archivo, revisar Rollbar", {
        position: "bottom-right",
      });
    }

    setOpen(false);
    setConfirmLoading(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFilesList();
  }, []);
  

  return (
    <div className="Admin">
      <AdminNavbar />
      <Container className="mt-3  col-lg-11 overflow-auto">
        <div className="card rounded p-2 custom-bg-gray border-none overflow-auto">
          <div className="card-body">
            <h5 className="card-title title-text-left">Archivos</h5>
            <div className="py-1">
              <Popconfirm
                  title="¿Generar resumen?"
                  description="Generaras un resumen completo de las 3 secciones y los puntajes de un alumno por cada ensayo. 
                  Esto puede demorar unos minutos"
                  open={open}
                  onConfirm={(e) => handleRunGenericFile(e)}
                  okButtonProps={{ loading: confirmLoading }}
                  onCancel={handleCancel}
                >
                <Button variant="primary" onClick={showPopconfirm} size="sm" >
                  Generar resumen Completo
                </Button>
              </Popconfirm>
            </div>
            <div
              className="alert alert-warning alert-dismissible fade show custom-alert text-xs p-2 mb-1"
              role="alert"
              style={{ fontSize: '14px' }}
            >
              <strong>Recuerda:</strong> Generar el resumen completo puede demorar un poco, si no te aparece altiro, recarga la página
      
            </div>
          
            <div className="card-text">
              { data && data.length > 0 ? 
                <>
                  <ConfigProvider
                    theme={{
                      token: {
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif`,
                        colorIcon: "white",
                      },
                      components: {
                        Table: {
                          /* here is your component tokens */
                          headerBg: "#072f75",
                          headerColor: "#f0f0f0",
                          headerSortHoverBg: "#1165f6",
                          headerSortActiveBg: "#072f75",
                        },
                      },
                    }}
                  >
                    <Table
                      columns={columns}
                      dataSource={data}
                      rowKey={(record) => record.id}
                      bordered
                      pagination={{
                        pageSize: pageSize,
                        total: data.length,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "10", "20", "50"],
                        onChange: handleChangePageSize,
                      }}
                    />
                  </ConfigProvider>
                </>
                : <>No hay datos</>
              }

            </div>
            
          </div>
        </div>
        
      </Container>

      <ToastContainer />
    </div>
  );
};

export default Files;