import React from 'react';

type RubricModalProps = {
  showRubricModal: boolean;
  setShowRubricModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const RubricE2Modal: React.FC<RubricModalProps> = ({ showRubricModal, setShowRubricModal }) => {
  return (
    <div>
      {showRubricModal && (
        <div className="modal fade show d-block" tabIndex={-1}>
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Rúbrica de Corrección</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowRubricModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <h5>Introducción</h5>
                <div className='paragraph'>
                  <h6><strong>Requisitos:</strong></h6>
                  <p>1. Plantear claramente las ideas que surjan como respuesta al enunciado.</p>
                  <p>2. Contextualizar las ideas a desarrollar durante el ensayo.</p>
                  <h6><strong>Puntajes:</strong></h6>
                  <p><strong>0 puntos:</strong> No logra cumplir con todos los puntos requeridos. </p>
                  <p><strong>1 punto:</strong> Se plantean las ideas para responder el enunciado, pero no se contextualizan. </p>
                  <p><strong>1.5 puntos:</strong> Logra correctamente todos los requisitos del ítem. </p>
                </div>

                <h5>Argumentación y fundamentación</h5>
                <div className='paragraph'>
                  <h6><strong>Requisitos:</strong></h6>
                  <p>1. La respuesta planteada debe justificarse con argumentos. Los argumentos están bien planteados y son de calidad, es decir, tienen sustento, y demuestran un análisis profundo de los contenidos a estudiar. </p>
                  <p>2. Los argumentos son relacionados entre sí para mantener un flujo cohesivo en el ensayo. </p>
                  <h6><strong>Puntajes:</strong></h6>
                  <p><strong>0 puntos:</strong> No logra cumplir con todos los puntos requeridos. </p>
                  <p><strong>2 puntos:</strong> Se cumple de manera vaga 1 de los requisitos. </p>
                  <p><strong>4 puntos:</strong> Se cumple correctamente sólo con 1 de los 2 requisitos, o de manera vaga con los 2. </p>
                  <p><strong>7 puntos:</strong> Logra correctamente todos los requisitos del ítem. </p>
                </div>

                <h5>Conclusión</h5>
                <div className='paragraph'>
                  <h6><strong>Requisitos:</strong></h6>
                  <p>1. Resumir las ideas planteadas para entender, en general, el ensayo.</p>
                  <p>2. Proyectar el tema o realizar comparaciones para cerrar ideas. </p>
                  <h6><strong>Puntajes:</strong></h6>
                  <p><strong>0 puntos:</strong> No logra cumplir con todos los puntos requeridos. </p>
                  <p><strong>1 punto:</strong> Se cumple correctamente sólo con 1 de los 2 requisitos o de manera vaga los 2. </p>
                  <p><strong>1.5 puntos:</strong> Logra correctamente todos los requisitos del ítem. </p>
                </div>

                <h5>Descuentos</h5>
                <div className='paragraph'>
                  <h6><strong>Ortografía, redacción, puntuación y léxico:</strong></h6>
                  <p>1. Hacer un uso inadecuado del lenguaje, con expresiones inapropiadas y/o groseras.</p>
                  <p>2. Más de 4 errores de ortografía. </p>
                  <p>3. Más de 4 errores de puntuación.</p>
                  <p>4. Dos o más frases con problemas de redacción. </p>
                  <h6><strong>Descuento:</strong></h6>
                  <p> -1 punto por cada falta. </p>

                  <h6><strong>Caligrafía</strong></h6>
                  <p>1. La letra dificulta en gran medida o imposibilita la lectura del ensayo.</p>
                  <h6><strong>Descuento:</strong></h6>
                  <p> -2 puntos. </p>
                </div>

                <h5>¿Cuándo poner puntaje -1?</h5>
                <div className='paragraph'>
                  <p>1. Por no cumplir con el mínimo de extensión del ensayo exigido por enunciado.</p>
                  <p>2. Por no tratarse del tema que propone el enunciado. </p>
                  <p>3. Cuando el alumno redactor o creador del ensayo deja identificadores en el archivo (marcar celda de datos de autor).</p>
                  <p>4. Por ser un ensayo en blanco o vacío. </p>
                </div>



              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RubricE2Modal;