import React, { useState, useEffect } from "react";
import { Recorrection2, HomeworkRecorrection2, BoxPropsRecorrection2 } from "./SharedTypes";

function formatDate(dateString: string | null) {
  if (dateString === null) {
    return null;
  }
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1; 
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;

  const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  return `${formattedDate}, ${formattedTime}`;
}


const RecorrectionBoxEtapa2: React.FC<BoxPropsRecorrection2> = ({
  type,
  homeworkList: initialHomeworkList,
}) => {
  const [homeworks, setHomeworks] = useState<HomeworkRecorrection2[]>(initialHomeworkList);
  const [showModal, setShowModal] = useState(false);
  const [currentRecorrection, setCurrentRecorrection] = useState<Recorrection2 | null>(
    null
  );
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);
 
  

  useEffect(() => {
    const initializedHomeworks = initialHomeworkList.map((hw) => ({
      ...hw,
      recorrections: hw.recorrections 
    }));
    setHomeworks(initializedHomeworks);
  }, [initialHomeworkList]);

  const handleOpenModal = (recorrection: Recorrection2, index: number) => {
    setCurrentRecorrection(recorrection);
    setActiveAccordion(index);

    
    
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);

};

 


  

  const handleAccordionClick = (index: number) => {
    if (activeAccordion === index) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(index);
    }
  };

  return (
    <div className={`homework-box ${type} container mt-3`}>
      <h2 style={{ color: "#072f75" }}>
        {type === "pending" ? "Próximas Tareas" : "Tareas Anteriores"}
      </h2>
      {homeworks.map((hw: HomeworkRecorrection2, index: number) => (
        <div key={index} className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === index ? "active" : ""}`}
                type="button"
                onClick={() => handleAccordionClick(index)}
              >
                <span className="hw-title" style={{ width: "200px" }}> Ensayo {hw.assignmentNumber} </span>
                <span className="endDate">
                    {new Date(hw.startDate) > new Date() ? `No disponible hasta: ${formatDate(hw.startDate)}` : `Cerrado: ${formatDate(hw.deadline)}`}
                </span>
              </button>
            </h2>
            <div
               className={`accordion-collapse collapse ${activeAccordion === index && new Date(hw.startDate) <= new Date() ? "show" : ""}`}
            >
              <div className="accordion-body">
                {hw.recorrections && hw.recorrections.map(
                  (recorrection: Recorrection2, recorrectionIndex: number) => (
                
                    <div
                      key={recorrectionIndex}
                      className={`correction-row mt-3 clickable ${recorrection.flowStatus === "OUT" ? "disabled" : ""}`}
                      onClick={() => recorrection.flowStatus !== "OUT" && handleOpenModal(recorrection, index)}
                    >
                      Recorreccón {recorrectionIndex + 1} 
                    

                      {recorrection.flowStatus === "OUT" ? (
                        <div className="points4">
                          Quedaste fuera del flujo
                        </div>
                      ) : (
                        <>
                          {recorrection.corrected ? (
                            <div className="points4">
                              <span>
                                Puntaje asignado: {recorrection.ratingObtained}/10
                              </span>
                            </div>
                          ) : (
                            <span className="points4">
                              Corrección no entregada
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  ) 
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

  
  
  {showModal && (
      <div className="modal fade show d-block" tabIndex={-1}> 
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Recorregir Corrección</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body">

                <div className="modal-body">
                <p><strong>Corrección a mi ensayo:</strong> {currentRecorrection?.reviewText}</p>
                <p><strong>Puntaje que se me asignó por mi ensayo:</strong> {currentRecorrection?.ratingObtained}/10</p>
              </div>
            </div>
            <div className="modal-footer">

              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseModal}
              >
                Cerrar
              </button>
              
            </div>
          </div>
        </div>

      </div>
  )}
  

    </div>
  );
};

export default RecorrectionBoxEtapa2;