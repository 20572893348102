import React, { useState, useEffect } from "react";
import { Recorrection3, HomeworkRecorrection3, BoxPropsRecorrection3 } from "./SharedTypes";


function formatDate(dateString: string | null) {
  if (dateString === null) {
    return null;
  }
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1; 
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;

  const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  return `${formattedDate}, ${formattedTime}`;
}


const BlockedRecorrectionBoxEtapa3: React.FC<BoxPropsRecorrection3> = ({
  type,
  homeworkList: initialHomeworkList,
}) => {
  const [homeworks, setHomeworks] = useState<HomeworkRecorrection3[]>(initialHomeworkList);
  const [showModal, setShowModal] = useState(false);
  const [currentRecorrection, setCurrentRecorrection] = useState<Recorrection3 | null>(
    null
  );
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null); 

  useEffect(() => {
    const initializedHomeworks = initialHomeworkList.map((hw) => ({
      ...hw,
      recorrections: hw.recorrections 
    }));
    setHomeworks(initializedHomeworks);
  }, [initialHomeworkList]);

  const handleOpenModal = (recorrection: Recorrection3, index: number) => {
    setCurrentRecorrection(recorrection);
    setActiveAccordion(index);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);

};


  const handleAccordionClick = (index: number) => {
    if (activeAccordion === index) {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(index);
    }
  };

  return (
    <div className={`homework-box ${type} container mt-3`}>
      <h2 style={{ color: "#072f75" }}>
        {type === "pending" ? "Próximas Tareas" : "Tareas Anteriores"}
      </h2>
      {homeworks.map((hw: HomeworkRecorrection3, index: number) => (
        <div key={index} className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === index ? "active" : ""}`}
                type="button"
                onClick={() => handleAccordionClick(index)}
              >
                <span className="hw-title" style={{ width: "200px" }}> Ensayo {hw.assignmentNumber} </span>
                <span className="endDate">
                    {`Cerrado: ${formatDate(hw.deadline)}`}
                </span>
              </button>
            </h2>
            <div
               className={`accordion-collapse collapse ${activeAccordion === index ? "show" : ""}`}
            >
              <div className="accordion-body">
                {hw.recorrections && hw.recorrections.map(
                  (recorrection: Recorrection3, recorrectionIndex: number) => (
                
                    <div
                      key={recorrectionIndex}
                      className={`correction-row mt-3 clickable ${recorrection.flowStatus === "OUT" ? "disabled" : ""}`}
                      onClick={() => recorrection.flowStatus !== "OUT" && handleOpenModal(recorrection, index)}
                    >
                      Recorrección {recorrectionIndex + 1}
                      {recorrection.flowStatus === "OUT" ? (
                      <div className="points4">
                        Quedaste fuera del flujo
                      </div>
                    ) : (
                      <>
                        {recorrection.correctedCorrection ? (
                          <div className="points4">
                            <span>
                              Puntaje entregado por mí: {recorrection.ratingGiven}/10
                            </span> 
                          </div>
                        )  : (
                          <span className="points4">
                            No entregaste corrección
                          </span>
                        )}
                        {recorrection.correctedReview ? (
                          <div className="points-given">
                            <span>
                              Puntaje asignado: {recorrection.ratingObtained}/3
                            </span> 
                          </div>
                        )  : (
                          <span className="not-delivered">
                            Aún no te han evaluado
                          </span>
                        )}
                      </>
                    )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

  
  
  {showModal && (
      <div className="modal fade show d-block" tabIndex={-1}> 
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Detalles de Recorrección</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body">

                <div className="modal-body">
                <p><strong>Corrección entregada por mí:</strong> {currentRecorrection?.reviewText}</p>
                <p><strong>Puntaje entregado por mí:</strong> {currentRecorrection?.ratingGiven}/10</p>
                <p><strong>Puntaje que se me asignó por mi corrección:</strong> {currentRecorrection?.ratingObtained}/3</p>
              </div>

              
            </div>
            <div className="modal-footer">

              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseModal}
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>

      </div>
  )}
  
    </div>
  );
};

export default BlockedRecorrectionBoxEtapa3;