import React, { useState } from 'react';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { FAQStype } from './SharedTypes';
import '../styles/faqs.css'

const FAQSBox: React.FC<FAQStype> = ({ type, faqsList }) => {
  const [openAccordion, setOpenAccordion] = useState<string | null>(null);

  const handleAccordionClick = (accordionId: string) => {
      // Si el acordeón está abierto, lo cerramos; de lo contrario, lo abrimos
      setOpenAccordion((prevOpenAccordion) =>
          prevOpenAccordion === accordionId ? null : accordionId
      );
  };

  const title = type === "available" ? "Preguntas respondidas" : "Preguntas pendientes";

    // Devuelve el JSX del componente
    return (
      <div className={`faq-box ${type} container`}>
        <h3 className="titlefaqs">{title}</h3>
        {faqsList.map((faq, index) => {
          const accordionId = `${type}${index}`;
          const isOpen = openAccordion === accordionId;
    
          // Condición para renderizar el botón y el contenido solo si el type es "available"
          if (type === "available") {
            return (
              <div key={index} className="accordion" id={accordionId}>
                <div className="accordion-item">
                  <h3 className="accordion-header" id={`heading${type}${index}`}>
                    <button
                      className={`accordion-button ${isOpen ? '' : 'collapsed'}`}
                      type="button"
                      onClick={() => handleAccordionClick(accordionId)}
                    >
                      <div className="faq-content">
                        <span className="hw-title">{faq.question}</span>
                        <span className="faq-date">
                          {new Date(faq.createdAt).toLocaleDateString('es-ES')}
                        </span>
                      </div>
                    </button>
                  </h3>
                  <div
                    id={`collapse${type}${index}`}
                    className={`accordion-collapse collapse ${isOpen ? 'show' : ''}`}
                    aria-labelledby={`heading${type}${index}`}
                  >
                    <div className="accordion-body d-flex">
                      <p>{faq.answer}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          } else {
            // Renderiza solo el título si el type no es "available"
            return (
              <div key={index} className="accordion" id={accordionId}>
                <div className="accordion-item">
                  <h3 className="accordion-header" id={`heading${type}${index}`}>
                    <button
                      className={`faqs-button`}
                      type="button">
                      <div className="faq-content">
                        <span className="hw-title">{faq.question}</span>
                        <span className="faq-date">
                          {new Date(faq.createdAt).toLocaleDateString('es-ES')}
                        </span>
                      </div>
                    </button>
                  </h3>
                </div>
              </div>
            );
          }
        })}
      </div>
    );
};

export default FAQSBox;