import React, { useEffect, useState } from "react";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from 'axios';
import * as Yup from "yup";
import "../../styles/customForms.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuthHeader } from "react-auth-kit";
import { useParams } from "react-router-dom";
import { ConfigProvider, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Form, Formik, useFormik } from "formik";

const Students: React.FC = () => {
  const authHeader = useAuthHeader();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);
  const usersEndpoint = "/users";
  const [data, setData] = useState<any>();
  const [sectionFilter, setSectionFilter] = useState<any>();
  const { page, limit } = useParams();
  const [pageSize, setPageSize] = useState<number>(10);

  const handleSendPassword = async () => {
    setSpinners(true);
    const sendPassword = "/users/send/password"
    try{
      await axios.post(`${process.env.REACT_APP_API_URL}${sendPassword}`, {} ,{ headers: { Authorization: authHeader() } })

      toast.success('Contraseñas enviadas exitosamente', {
        position: "bottom-right",
        //autoClose: 3000, // Cierra automáticamente después de 3 segundos
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
      });
      setSpinners(false);
    } catch (error) {
      setSpinners(false);
      const read_error = error as any;

      toast.error(`Error al enviar las contraseñas: ${ read_error.response.data.error || 'Error desconocido'}`, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
      });
    }
  };

  const setSpinners = (status: boolean) => {
    setIsDisabled(status);
    setLoading(status);
    setFilterLoading(status);
  }

  const columns: ColumnsType<any> = [
    {
      title: "Sección",
      dataIndex: "section",
      key: "section",
      sorter: (a: any, b: any) =>
        Number(a.section) - Number(b.section),
      filters: sectionFilter,
      onFilter: (value, record: any) =>
        record.section.indexOf(String(value)) === 0,
    },
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Rol",
      dataIndex: "role",
      key: "role",
      filters: [
        { text: 'Estudiante', value: 'Estudiante' },
        { text: 'Ayudante', value: 'Ayudante' },
        { text: 'Admin', value: 'Admin' },
      ],
      onFilter: (value, record) => record.role.indexOf(value) === 0,
    },
    {
      title: "CE",
      dataIndex: "passwordSent",
      key: "passwordSent"
    },
    {
      title: "CE_at",
      dataIndex: "passwordSentAt",
      key: "passwordSentAt",
      render: (_, record) => {
        return <>{record?.passwordSentAt.split('T')[0] || '-'}</>;
      }
    },
    {
      title: "Accion",
      dataIndex: "linkUser",
      key: "linkUser",
      render:(_, record) => (
        <Button variant="primary" size="sm" href={`/users/${record.id}`} >
          Ver
        </Button>
      ),
    },
  ];


  const setUserList = async () => {
    setSpinners(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${usersEndpoint}?includeAss=false&limit=${limit}&page=${page}`,
        { headers: { Authorization: authHeader() } }
      );
      const users_data = response.data.data;
      const tableData: any[] = users_data.map(
        (item: any, index: number) => {
          return {
            id: `${item.id}`,
            section: `${item.courseSection?.number || '-'}`,
            name: `${item.name+' '+item.lastName}`,
            email: `${item.email}`,
            role: `${item.role  === 'STUDENT' ? 'Estudiante' : item.role === 'COLLABORATOR' ? 'Ayudante' : 'Admin'}`,
            passwordSent: `${item.passwordSent ? "Si" : 'No'}`,
            passwordSentAt: `${item.passwordSentAt ? item.passwordSentAt : '-'}`,
            linkUser: `/users/${item.id}`,
          };
        }
      );
      const sectionDataFilter = Array.from(
        new Set(tableData.map((item) => item.section))
      )
        .sort((a: string, b: string) => Number(a) - Number(b))
        .map((x) => {
          return { text: x.toString(), value: x.toString() };
      });
      setSectionFilter(sectionDataFilter);
      setData(tableData);
      setSpinners(false);
    } catch (error) {
      setSpinners(false);
      toast.error(`Error al cargar datos: ${error || 'Error desconocido'}`, {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
      });
    }
  }

  const validationSchema = Yup.object({
    inputValue: Yup.string().required('Input is required'),
    SearchType: Yup.string().required('Option is required'),
  });

  const handleSubmit = async (values: any) => {
    setFilterLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${usersEndpoint}?includeAss=false&${values.SearchType}=${values.inputString}`,
        { headers: { Authorization: authHeader() } }
      );
      const users_data = response.data.data;
      const tableData: any[] = users_data.map(
        (item: any, index: number) => {
          return {
            id: `${item.id}`,
            section: `${item.courseSection?.number || '-'}`,
            name: `${item.name+' '+item.lastName}`,
            email: `${item.email}`,
            role: `${item.role  === 'STUDENT' ? 'Estudiante' : item.role === 'COLLABORATOR' ? 'Ayudante' : 'Admin'}`,
            passwordSent: `${item.passwordSent ? "Si" : 'No'}`,
            passwordSentAt: `${item.passwordSentAt ? item.passwordSentAt : '-'}`,
            linkUser: `/users/${item.id}`,
          };
        }
      );
      const sectionDataFilter = Array.from(
        new Set(tableData.map((item) => item.section))
      )
        .sort((a: string, b: string) => Number(a) - Number(b))
        .map((x) => {
          return { text: x.toString(), value: x.toString() };
      });
      setSectionFilter(sectionDataFilter);
      setData(tableData);
      setFilterLoading(false);
    } catch (error) {
      setFilterLoading(false);
      toast.error(`Error al cargar datos: ${error || 'Error desconocido'}`, {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
      });
    }
  };

  const formik = useFormik({
    initialValues: {
      inputString: '',
      SearchType: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };

  useEffect(() => {
    setUserList();
  }, []);
  

  return (
    <div className="Admin">
      <AdminNavbar />
      <Container className="mt-3  col-lg-11 overflow-auto">
        <div className="card rounded p-2 custom-bg-gray border-none overflow-auto">
          <div className="card-body">
            <h5 className="card-title title-text-left">Usuarios</h5>
            <div className="card-text">
              <Row className="mb-1">
                <Col xs={12}>
                  <Button
                    type="button"
                    disabled={isDisabled}
                    className="btn-small mb-2"
                    onClick={handleSendPassword}
                  >
                    Enviar contraseñas
                    {loading ? (
                      <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                    ) : (
                      <></>
                    )}
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    href={`/usersForm`}
                    type="button"
                    variant="outline-success"
                    className="btn-small"
                  >
                    Crear Usuario
                  </Button>
                </Col>

              </Row>
              <div className="py-1">
                <div className="rounded bg-white p-2 border border-1 p-2">
                  <Formik
                    initialValues={{
                      inputString: '',
                      SearchType: 'email',
                    }}
                    onSubmit={(e) => { handleSubmit(formik.values); }}
                  >
                    <Form className="row g-2 align-items-center">

                      <div className="col-auto">
                        <input
                          type="text"
                          id="inputString"
                          name="inputString"
                          className="form-control"
                          placeholder="Ingresa busqueda..."
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.inputString}
                        />
                        {formik.touched.inputString && formik.errors.inputString ? (
                          <div>{formik.errors.inputString}</div>
                        ) : null}
                      </div>

                      {/* Dropdown */}
                      <div className="col-auto">
                          <select
                            id="SearchType"
                            name="SearchType"
                            className="form-select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.SearchType}
                          >
                            <option value="">Selecciona filtro</option>
                            <option value="email">Email</option>
                            <option value="studentNumber">Número Alumno</option>
                          </select>
                      </div>

                      {/* Submit button */}
                      <div className="col-auto">
                        <button type="submit" className="btn btn-primary">
                          Filtrar
                          {filterLoading && (
                          <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                          )  
                          }
                        </button>
                      </div>
                      <Button
                        type="button"
                        disabled={isDisabled}
                        className="btn-small btn-secondary col-auto"
                        onClick={setUserList}
                      >
                        Resetear filtros
                        {filterLoading && (
                        <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        )  
                        }
                      </Button>
                    </Form>
                  </Formik>
                </div>
              </div>
              { data ? 
                <>
                  <ConfigProvider
                    theme={{
                      token: {
                        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
                'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
                sans-serif`,
                        colorIcon: "white",
                      },
                      components: {
                        Table: {
                          /* here is your component tokens */
                          headerBg: "#072f75",
                          headerColor: "#f0f0f0",
                          headerSortHoverBg: "#1165f6",
                          headerSortActiveBg: "#072f75",
                        },
                      },
                    }}
                  >
                    <Table
                      columns={columns}
                      dataSource={data}
                      rowKey={(record) => record.id}
                      bordered
                      pagination={{
                        pageSize: pageSize,
                        total: data.length,
                        showSizeChanger: true,
                        pageSizeOptions: ["5", "10", "20", "50"],
                        onChange: handleChangePageSize,
                      }}
                    />
                  </ConfigProvider>
                </>
                : <></>
              }

            </div>
            
          </div>
        </div>
        
      </Container>

      <ToastContainer />
    </div>
  );
};

export default Students;