import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuthUser, useIsAuthenticated } from "react-auth-kit";
import { useAuthHeader } from "react-auth-kit";

type ProtectedRouteProps = {
  role: string;
  redirectPath: string;
};

export default function PrivateRouteWithRole(
  props: ProtectedRouteProps
): JSX.Element {
  const auth = useAuthUser();
  const userRole = auth()?.role;
  const isAuthenticated = useIsAuthenticated();
  const validUser = isAuthenticated() && userRole === props.role && !isTokenExpired() ;

  return validUser ? <Outlet /> : <Navigate to={props.redirectPath} />;
}

const isTokenExpired = (): boolean => {
  try {
    const expiryTimeString = localStorage.getItem('tokenExpiry');

    if (!expiryTimeString) {
      return true;
    }

    const expiryTimeNumber = Number(expiryTimeString);

    if (isNaN(expiryTimeNumber)) {
      return true;
    }

    const expiryDate = new Date(expiryTimeNumber);

    return new Date() >= expiryDate;
  } catch (error) {
    return true;
  }
};
