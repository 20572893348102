import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CollaboratorNavbar from '../../components/CollaboratorNavbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Table, Modal, Button, Form, Input, InputNumber, Alert, Divider } from 'antd';
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { Formik, useFormik } from 'formik';
import { toast } from 'react-toastify';
import * as Yup from "yup";

interface Attachment2 {
  essayFileName: string;
  url: string;
}

interface Assignment {
  number: number;
}

interface CorrectionReview {
  id: null | number;
  correctedId: number;
  assigmentUser: {
    user: {
      name: string;
      lastName: string;
      email: string;
      studentNumber: string;
    };
  };
  correctionScore: number;
  corrected: boolean;
  studentComment: null | string;
  correction?: {
    justification?: any;
    comments?: null | string;
  };
}

interface ReviewsRecieved {
  correctedId: number;
  assigmentUser: {
    user: {
      name: string;
      lastName: string;
      email: string;
      studentNumber: string;
    };
  };
  correctionScore: number;
  corrected: boolean;
  studentComment: null | string;
  correction: {
    comments: null | string;
  };
}

interface Correction2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number;
  attachment: Attachment2;
}

interface CorrectionsMade2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number;
  attachment: Attachment2;
}

interface AssignmentUser2 {
  assignment: Assignment;
  id: number;
  status: string;
  user: {
    name: string;
    lastName: string;
    email: string;
    studentNumber: string;
  };
  corrections: Correction2[];
  correctionsMade: CorrectionsMade2[];
  correctionReviews: CorrectionReview[];
  reviewsRecieved: ReviewsRecieved[];
}

interface CorrectionData2 {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  comments: null | string;
  justification: string;
  score: number | null;  // Allow null for the score property
  attachment: Attachment2;
}

interface Data2 {
  id: number;
  collaboratorId: number;
  requesterId: null | number;
  scoreDelta: null | number;
  studentComment: string;
  collaboratorComment: null | string;
  corrected: boolean;
  stage: string;
  assigmentUser: AssignmentUser2;
  correction: CorrectionData2 & { score: number | null };
  correctionReviews: CorrectionReview[];
  reviewsRecieved: ReviewsRecieved[];
  review: CorrectionReview;
}

interface ApiResponse {
  data: Data2[];
}

const AyudanteEtapa3: React.FC = () => {
  const [revisions, setRevisions] = useState<Data2[]>([]);
  const [editingRevision, setEditingRevision] = useState<Data2 | null>(null);
  const [isCorrectionModalOpen, setIsCorrectionModalOpen] = useState(false);
  const [editingCorrection, setEditingCorrection] = useState<CorrectionData2 | null>(null);
  const [modalFormValues, setModalFormValues] = useState<CorrectionData2 | null>(null);
  const [editedJustification, setEditedJustification] = useState<string>("");
  const [pageLoading, setpageLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [editedScore, setEditedScore] = useState<number | null>(null);
  const [filterLoading, setFilterLoading] = useState(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [isDisabled, setIsDisabled] = useState(false);

  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);
  const authUser = useAuthUser();
  const userId = authUser()?.id;

  const fetchData = async () => {
    formik.values.SearchType = 'false';
    setFilterLoading(true);
    try {
      const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/lite/third/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response2.data && response2.data.data) {
        const filteredData = response2.data.data.filter((item: Data2) =>
          (item?.assigmentUser && item?.review?.corrected === false)
        );
        setRevisions(filteredData);
      }
    } catch (error) {
    }
    setpageLoading(false);
    setFilterLoading(false);
  };

  useEffect(() => {
    setpageLoading(true);
    fetchData();
  }, []);


  const handleEditClick = (revision: Data2) => {
    setEditingRevision(revision);
    if (revision.review.correction) {
      // setEditingCorrection(revision.review.correction);
      setEditedJustification(revision.review.correction.justification);
      setEditedScore(editingRevision?.review?.correctionScore || 0);
      setIsCorrectionModalOpen(true);
    } else {
      // Handle the case when revision.correction is null
      setEditingCorrection(null);
      setEditedJustification("");
      setEditedScore(null);
    }
  };

  const UpdateRevision = async (editingRevision: any) => {
    try {
      // Make PUT request to update the data
      await axios.put(`${process.env.REACT_APP_API_URL}/revisions/${editingRevision?.id}`, {
        scoreDelta: 0,
        corrected: true, 
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }); 
    } catch (error) {
      // console.log(error)
    }
  }

  const handleModalSave = async () => {
    setSaving(true);
    try {
      // Make PUT request to update the data
      await axios.put(`${process.env.REACT_APP_API_URL}/stages/third/${editingRevision?.review.id}`, {
        correctionScore: editedScore,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      await UpdateRevision(editingRevision);
            
      // Close the modal and update the local state
      setIsCorrectionModalOpen(false);
      setEditingRevision(null);
      setEditedJustification("");
      setEditedScore(null);
      setSaving(false);

      setpageLoading(true);

      const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/lite/third/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response2.data && response2.data.data) {
        const filteredData = response2.data.data.filter((item: Data2) =>
        (item?.assigmentUser && item?.stage === "THIRD" && item?.review?.corrected === false)
        );
        setRevisions(filteredData);
        setpageLoading(false);
      }

      // Refetch the data or update the local state as needed
      // ...
    } catch (error) {
      setSaving(false);
    }
  };

  const handleSubmitForm = async (values: any) => {
    try {
      setFilterLoading(true);
      const extraValue = values.SearchType != '' ? `&corrected=${values.SearchType === 'true' ? true : false}` : '';
      // const response = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/${userId}?stage=THIRD${extraValue}`, {
      //   headers: {
      //     Authorization: `Bearer ${token}`,
      //   },
      // });
      const response2 = await axios.get(`${process.env.REACT_APP_API_URL}/stages/fourth/lite/third/${userId}?${extraValue}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
  
      if (response2.data && response2.data.data) {
        const filteredData = response2.data.data.filter((item: Data2) =>
          (item?.assigmentUser) && (values.SearchType === 'true' ? true : item?.review?.corrected === false)
        );
        setRevisions(filteredData);
      }
    } catch (error) {
      toast.error(`Error al cargar datos`, {
        position: "top-center",
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
      });
    }
    setFilterLoading(false);
  };  
  
  const validationSchema = Yup.object({
    SearchType: Yup.string().required('Option is required'),
  });

  const formik = useFormik({
    initialValues: {
      SearchType: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmitForm,
  });

  const handleChangePageSize = (current: number, newSize: number) => {
    setPageSize(newSize);
  };

            
            
  return (
    <div className="Admin">
      <CollaboratorNavbar />
      <div className="container">
        <div className="text-center my-4">
              <div className="title-text">Recorreccion Etapa 3 Irregular </div>
        </div>
        <Alert
          message="Estas correcciones al ser irregulares, suelen ser casos MU o NC, principalmente la segunda, donde no fueron revisados por alumnos. En muchos casos deberan evaluar la corrección que hizo el alumno a un ensayo."
          type="warning"
          closable
          className='px-2 mx-4 mb-1'
        />
        <div className="rounded bg-white p-2 border border-1 my-2">
              <Formik
                    initialValues={{
                      SearchType: 'false',
                    }}
                    onSubmit={(e) => { handleSubmitForm(formik.values); }}
                  >
                    <Form className="row g-2 align-items-center">
                      {/* Dropdown */}
                      <div className="col-auto">
                          <select
                            id="SearchType"
                            name="SearchType"
                            className="form-select"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.SearchType}
                          >
                            <option value="false">No corregidos</option>
                            <option value="true">Corregidos</option>
                          </select>
                      </div>

                      {/* Submit button */}
                      <div className="col-auto">
                        <Button type='primary' onClick={(e) => handleSubmitForm(formik.values)}>
                          Filtrar
                          {filterLoading && (
                            <span className="spinner-border spinner-border-sm ms-2" aria-hidden="true"></span>
                          )  
                          }
                        </Button>
                      </div>
                      <Button
                        disabled={isDisabled}
                        className="w-auto px-4 py-auto btn-secondary"
                        onClick={fetchData}
                      >
                        Resetear filtros
                        {filterLoading && (
                         <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
                        )  
                        }
                      </Button>
                    </Form>
              </Formik>
        </div>
        { revisions && !pageLoading ?
          <>
            <Table
                dataSource={revisions}
                rowKey="id"
              >
                <Table.Column
                    title="Numero de Ensayo"
                    dataIndex={["assigmentUser", "assignment", "number"]}
                    key="assigmentUser.assignment.number"
                  />
                <Table.Column
                    title="Corregido?"
                    key="corregido"
                    dataIndex={'corrected'}
                    render={(corrected: boolean) => (
                      <span>{corrected ? 'Sí' : 'No'}</span>
                    )}
                  />
                <Table.Column
                  title="Email Estudiante"
                  dataIndex={["assigmentUser", "user", "email"]}
                  key="assigmentUser.user.email"
                />
                <Table.Column 
                  title="Puntaje de Corrección" 
                  dataIndex={['correction', 'score']} 
                  key="correction.score" 
                  render={(score: number) => (
                    <span>{score || '--'}</span>
                  )}
                />
                <Table.Column
                    title="URL Ensayo"
                    dataIndex={["review", "correction", "assigmentUser", "attachments", 0, "url"]}
                    key="assigmentUser.corrections.0.attachment.url"
                    render={(url) => <a href={url} target="_blank" rel="noopener noreferrer">Link</a>}
                />

                <Table.Column
                    title="Recorregir"
                    key="recorregir"
                    render={(_, record: Data2) => (
                      <Button type="primary" onClick={() => handleEditClick(record)}>
                        Recorregir
                      </Button>
                    )}
                />
            </Table>
            <Modal
                  title="Edit Correction"
                  visible={isCorrectionModalOpen}
                  onOk={handleModalSave}
                  width={800} 
                  onCancel={() => setIsCorrectionModalOpen(false)}
                  footer={[
                    <Button 
                      onClick={handleModalSave}
                      type='primary'
                      key={editingRevision?.id}
                      >
                        Guardar
                        { saving ? <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span> : <></>}
                    </Button>
                  ]}
                >
                  <Form layout="vertical">
                    <Divider orientation='left'>Datos</Divider>
                    <p>
                      <strong>Corregido:</strong> { editingRevision && editingRevision.review.corrected === true ? 'Este item esta corregido por alumnos': 'Este item no fue corregido por alumnos'}
                    </p>                    
                    <p>
                      <strong>Comentario extra:</strong> { editingRevision && editingRevision.studentComment ? editingRevision.studentComment : '--'}
                    </p>
                
                    <Form.Item label="Corrección 2 alumno:">
                      <Input.TextArea
                        value={editingRevision?.review?.correction?.justification || ''}
                        disabled
                        style={{ backgroundColor: '#f0f0f0', color: 'black', /* Add any other styles you need */ }}
                        onChange={(e) => {
                        }}
                      />
                    </Form.Item>

                    <Form.Item label="Score:">
                      <InputNumber
                                min={0}
                                max={3}
                                step={1}
                        value={editedScore}
                        onChange={(value) => setEditedScore(value)}
                      />
                    </Form.Item>
                    <Divider orientation="left" >Información</Divider>
                    <p>Corrección 2 de alumno, es el comentario que el alumno hizo sobre el ensayo en la etapa 2, el cual, nunca fue evaluado en la etapa 3. Si la correccion 2 alumno viene vacía es porque el alumno no puso un comentario.</p>
                    <p>En este caso se debe poner el puntaje que el alumno se merece por la corrección que hizo, con puntajes de 0 a 3.</p>
                    <p>Tienen una oportunidad para corregir la corrección, en caso de error pedir a Joaquin que se los habilite de nuevo.</p>
                    <Divider />
                      
                    {/* ...other form fields if needed... */}
                  </Form>   
            </Modal>
          </>
        : 
        <>
          <div className='container border rounded border-1 bg-white'>
            <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
          </div>          
        </>
        }
      </div>
    </div>
  );
};

export default AyudanteEtapa3; 
