import React, { useEffect, useState } from 'react'
import { Evaluation, Homework3, BoxProps3 } from "./SharedTypes";
import axios from 'axios';
import { useAuthHeader } from 'react-auth-kit';

interface EvaluationModalProps {
    currentEvaluationSent: any;
    handleCloseModal: any;
    showModal: boolean;
    setShowRubricModal: any;
    currentHW: Homework3 | any; 
  }
  

const EvaluationModal: React.FC<EvaluationModalProps> = ({
    currentEvaluationSent,
    handleCloseModal,
    showModal,
    setShowRubricModal,
    currentHW
}) => {

    const backendUrl = process.env.REACT_APP_API_URL;
    const stageEndpoint = "/stages/third";
    const [delivered, setDelivered] = useState(false);
    const [ratingGiven, setRatingGiven] = useState<number | null>();
    const [currentEvaluation, setCurrentEvaluation] = useState<any>(currentEvaluationSent);

    const authHeader = useAuthHeader();
    const token = authHeader().slice(7);

    const sendData = async (correctionScore: number | null | undefined, reviewId: number) => {
        try {
          await axios.put(`${backendUrl}${stageEndpoint}/${reviewId}`, {
            correctionScore: correctionScore
          }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          setDelivered(true);
          window.location.reload();
        
        } catch (error) {
          setDelivered(true);
        }
      };

    useEffect(() => {
      if (currentEvaluationSent && currentEvaluationSent.ratingGiven != null) {
        const val = currentEvaluationSent.ratingGiven;
        setRatingGiven(parseFloat(val.toFixed(1)));
      }
      if (currentEvaluationSent && currentEvaluationSent.ratingGiven == null) {
        setRatingGiven(0);
      }
      if (currentEvaluationSent) {
        setCurrentEvaluation(currentEvaluation);
      }
      setDelivered(true);
      
    }, [currentEvaluationSent])
      

    const handleReviewSubmission = () => {
        if (currentEvaluationSent) {
            setDelivered(false);
            const reviewId = currentEvaluationSent.reviewId; 
            const correctionScore = ratingGiven;
              
            const currentDate = new Date();
            const assignmentEndDate = new Date(currentHW?.deadline);
    
            if (currentDate > assignmentEndDate) {
              window.alert("Se acabó el tiempo de entrega.");
              window.location.reload();
            }else if (currentHW?.status === "CLOSED"){
              window.alert("Se ha cerrado la evaluación.");
              window.location.reload();
            } else {
              sendData(correctionScore, reviewId);
            }
            handleCloseModal();
        }
      };

  return (
    <>
    {showModal && (
        <div className="modal fade show d-block" tabIndex={-1}> 
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Evaluar Corrección</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseModal}
                ></button>
              </div>
              { currentEvaluationSent.correctedCorrection === true ?
              
              <>
                <div className="modal-body">
                  <div className="modal-body px-0 py-1">
                  <p><strong>Corrección:</strong> {currentEvaluationSent?.reviewText ? currentEvaluationSent?.reviewText : "--" }</p>

                </div>

                {ratingGiven != null && (
                  <>
                    <label htmlFor="customRange">
                      <strong>Evalúa la corrección:</strong> {ratingGiven.toFixed(1)}/3
                    </label>
                    <input
                      type="range"
                      className="form-range"
                      id="customRange"
                      min="0"
                      max="3"
                      step="1"
                      value={ratingGiven}
                      onChange={(e) => setRatingGiven(Number(e.target.value))}
                    />
                  </>
                )}

                </div>
                { currentEvaluationSent.correctedReview === true ? 
                  <>
                    <div className='px-2'>
                      <div
                        className="alert alert-success alert-dismissible fade show custom-alert px-2"
                        role="alert"
                      >
                        Ya lo corregiste
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                        ></button>
                      </div>
                    </div>
                  </>
                  : <></> 
                }
                <div className="modal-footer">
                <div className="rubric-link" onClick={() => setShowRubricModal(true)}>
                  <u style={{ cursor: 'pointer', color: 'blue' }}>Ver Rúbrica</u>
                </div>


                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Cerrar
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleReviewSubmission}
                  disabled={!delivered} 
                >
                  Enviar Evaluación
                </button>
                </div>
              </>
              
              : <>
                <div className="modal-footer">
                  <span>
                    Dado que no te han corregido, un ayudante se encargará de hacerlo, es automático. No quedarás fuera de flujo por esto.
                  </span>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleCloseModal}
                  >
                    Cerrar
                  </button>
                </div>
              </>

              }
            </div>
          </div>
  
        </div>
    )}
    </>
  )
}

export default EvaluationModal