import React, { useEffect } from "react";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import MyNavbar from "../components/NavBar";

export default function Logout() {
  const signOut = useSignOut();
  const navigate = useNavigate();

  useEffect(() => {
    if (!signOut()) {
      console.error("Error during logout");
      return;
    }
    navigate("/login");
  }, [signOut, navigate]);

  return (
    <div className="App">
      <MyNavbar />
      <p>Logging out...</p>
    </div>
  );
}
