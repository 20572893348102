import React from 'react';

type RubricModalProps = {
  showRubricModal: boolean;
  setShowRubricModal: React.Dispatch<React.SetStateAction<boolean>>;
};

const RubricE3Modal: React.FC<RubricModalProps> = ({ showRubricModal, setShowRubricModal }) => {
  return (
    <div>
      {showRubricModal && (
        <div className="modal fade show d-block" tabIndex={-1}>
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Rúbrica de Evaluación</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => setShowRubricModal(false)}
                ></button>
              </div>
              <div className="modal-body">
                <h5>Requisitos</h5>
                <p>1. Hay una crítica constructiva en los comentarios del ensayo que motiva la mejora.</p>
                <p>2. Se especifican los errores de ortografía y/o redacción que generan descuentos (si los hay).</p>
                <p>3. Los comentarios respecto del ensayo son fundamentados y no sólo opiniones personales.</p>
                <p>4. Se realizan al menos dos comentarios sobre lo bueno y al menos dos sobre lo que se puede mejorar en el ensayo.</p>
                <h5>Puntajes</h5>
                <p><strong>0 puntos:</strong> No justifica la corrección según los requisitos de esta pauta y/o solo cumple con uno de los puntos requeridos y/o el comentario entrega explícitamente el puntaje otorgado. </p>
                <p><strong>1 punto:</strong> Cumple con solo dos de los puntos requeridos para la corrección del ensayo. </p>
                <p><strong>2 puntos:</strong> Cumple con tres de los requisitos exigidos por la pauta de evaluación del corrector.</p>
                <p><strong>3 puntos:</strong> Cumple con tres de los requisitos exigidos por la pauta de evaluación del corrector.</p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default RubricE3Modal;