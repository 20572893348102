import React, { useState } from "react";
import { Homework, BoxProps, Correction } from "./SharedTypes";

const BlockedCorrectionBox: React.FC<BoxProps> = ({
  type,
  homeworkList: initialHomeworkList,
}) => {
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedCorrection, setSelectedCorrection] = useState<Correction | null>(null);

  const handleAccordionClick = (index: number) => {
    setActiveAccordion(prevIndex => prevIndex === index ? null : index);
  };

  const handleOpenDetailsModal = (
    e: React.MouseEvent,
    correction: Correction
  ) => {
    e.stopPropagation();
    setSelectedCorrection(correction);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
    setSelectedCorrection(null); // Clear the selected correction when closing the modal
  };

  return (
    <div className={`homework-box ${type} container mt-3`}>
      <h2 style={{ color: "#072f75" }}>
        {type === "pending" ? "Próximas Tareas" : "Tareas Anteriores"}
      </h2>
      {initialHomeworkList.map((hw: Homework, index: number) => (
        <div key={index} className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === index ? "active" : ""}`}
                type="button"
                onClick={() => handleAccordionClick(index)}
              >
                <span className="hw-title" style={{ width: "200px" }}>
                  Ensayo {hw.title}
                </span>
                <span className="ml-auto fecha-title">Cerrado: {hw.deadline.toLocaleString()}</span>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${activeAccordion === index ? "show" : ""}`}
            >
              <div className="accordion-body">
                {hw.corrections && hw.corrections.length > 0 ? (
                  hw.corrections.map((correction, correctionIndex) => (
                    <div
                      key={correctionIndex}
                      className="correction-row mt-3"
                    >
                      {correction.justification ? (
                        <span
                          className={`details-link ${correction.score === -1 ? "red-text" : ""}`}
                          onClick={(e) => handleOpenDetailsModal(e, correction)}
                        >
                          Ver detalles (Rating: {correction.score.toFixed(1)}/10)
                        </span>
                      ) : (
                        <span className="no-details">Corrección no entregada</span>
                      )}
                    </div>
                  ))
                ) : (
                  <div className="correction-row mt-3">Aún no entregado</div>
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

      {showDetailsModal && selectedCorrection && (
        <div className="modal fade show d-block" tabIndex={-1}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Detalles de Corrección</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseDetailsModal}
                ></button>
              </div>
              <div className="modal-body">
                <p>Justificación: {selectedCorrection.justification}</p>
                <p>Calificación: {selectedCorrection.score.toFixed(1)}/10</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseDetailsModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlockedCorrectionBox;
