import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/HomePage.css";
import RoleNavBar from "../components/RoleNavbar";
const NotFoundPage: React.FC = () => {
  return (
    <div className="App">
      <RoleNavBar />
      <div className="content-wrapper">
        <div className="content-box">
          <p className="not-found-title">404</p>
          <p className="not-found-text">Oops! Página no encontrada</p>
          <p>
            Es posible que la página que estás buscando se haya eliminado, haya
            cambiado de nombre o no esté disponible temporalmente.
          </p>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
