import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import AdminNavbar from "../../components/AdminNavbar";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useAuthHeader } from "react-auth-kit";
import { toast } from "react-toastify";

interface User {
  name: string;
  lastName: string;
  email: string;
  studentNumber: string;
  identifier: {
    identifier: string;
  }
  role: string;
  passwordSentAt: boolean;
  passwordSent: string;
  courseSection?: {
    number: string;
  }
  // Add more properties as needed
}

interface UserShowProps {
  // You can also make user prop optional if you want to handle the case where user data is still loading
  user?: User;
}

type UsersParams = {
    id: string;
  };
  

const UserShow: React.FC<UserShowProps> = () => {
  const { id } = useParams<UsersParams>();
  const authHeader = useAuthHeader();
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState<User | undefined>();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/admin/${id}`, { headers: { Authorization: authHeader() } });
        const responseData = response.data;
        setUser(responseData.data);
      } catch (error) {
        toast.error(`Error al cargar datos: ${error || 'Error desconocido'}`, {
          position: "bottom-right",
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false
        });
        // Handle error, e.g., set an error state
      }
    };
    if (id) {
      fetchUserData();
    }
  }, [id]);

  const setSpinners = (status: boolean) => {
    setIsDisabled(status);
    setLoading(status);
  }

  const handleSendPassword = async (e: any, user: any) => {
    e.preventDefault();
    setSpinners(true);
    try {
      await axios.post(
         `${process.env.REACT_APP_API_URL}/users/email/${user.id}`,
        {},{ headers: { Authorization: authHeader() } }
      );
      toast.success("Contraseña sera enviada", {
        position: "bottom-right",
      });
      setSpinners(false);
    } catch (error) {
      toast.error("Hubo un problema al reestablecer su contraseña, reivsar Rollbar", {
        position: "bottom-right",
      });
      setSpinners(false);
    }
  }

  const handleNewPassword = async (e: any, user: any) => {
    e.preventDefault();
    setSpinners(true);
    try {
      await axios.post(
         `${process.env.REACT_APP_API_URL}/users/restore/`,
        { 
          email: user.email,
          studentNumber: user.studentNumber
        },
        { withCredentials: false }
      );
      toast.success("Contraseña enviada exitosamente", {
        position: "bottom-right",
      });
      setSpinners(false);
    } catch (error) {
      toast.error("Hubo un problema al reestablecer su contraseña, reivsar Rollbar", {
        position: "bottom-right",
      });
      setSpinners(false);
    }
  }

  // Display user details, you can replace this with your actual user object structure
  const userDetails = user ? (
    <>
      <Row >
        <Col xs={12} md={3}>
          <strong>Nombre:</strong>
        </Col>
        <Col xs={12} md={3}>
          {user.name}
        </Col>
        <Col xs={12} md={3}>
          <strong>Apellido:</strong>
        </Col>
        <Col xs={12} md={3}>
          {user.lastName}
        </Col>
      </Row>
      <Row >
        <Col xs={12} md={3}>
          <strong>Email:</strong>
        </Col>
        <Col xs={12} md={3}>
          {user.email}
        </Col>
        <Col xs={12} md={3}>
          <strong>Número de Estudiante:</strong>
        </Col>
        <Col xs={12} md={3}>
          {user.studentNumber}
        </Col>
      </Row>
      <Row >
        <Col xs={12} md={3}>
          <strong>Rol:</strong>
        </Col>
        <Col xs={12} md={3}>
          {user.role}
        </Col>
        <Col xs={12} md={3}>
          <strong>Identificador:</strong>
        </Col>
        <Col xs={12} md={3}>
          {user?.identifier?.identifier || '-'}
        </Col>
      </Row>

      { user.role === 'STUDENT' ? 
        <Row >
          <Col xs={12} md={3}>
            <strong>Seccion:</strong>
          </Col>
          <Col xs={12} md={3}>
            {user?.courseSection?.number}
          </Col>
        </Row>
      : <></>}

      {
        user.passwordSent || user.passwordSentAt ? 
          <Row >
            <Col xs={12} md={3}>
              <strong>Contraseña enviada:</strong>
            </Col>
            <Col xs={12} md={3}>
              {user.passwordSent ? 'Si' : 'No' }
            </Col>
            <Col xs={12} md={3}>
              <strong>Contraseña enviada el:</strong>
            </Col>
            <Col xs={12} md={3}>
              {user.passwordSentAt ? user.passwordSentAt : '--'}
            </Col>
          </Row>
          : null
      }
      
      {/* Add more user details as needed */}
    </>
  ) : (
    // Optionally, you can show a loading state or an error message here
    <p>Cargando información...
        <span className="spinner-border spinner-border-sm ms-2" role="status" aria-hidden="true"></span>
    </p>
  );

  return (
    <div className="Admin">
      <AdminNavbar />
      <Container className="mt-3 col-lg-8">
        <div className="card rounded p-2 custom-bg-gray border-none">
          <div className="card-body">
            <h5 className="card-title title-text-left">Detalles del Usuario</h5>
            {userDetails}

            <div className="mt-4">
              { user ? 
                <Row>
                  <Col xs={12} md={3}>
                    <Button variant="primary" className="w-100 mb-2 mb-md-0 py-2 py-lg-1" href={`${id}/edit`}>Editar</Button>
                  </Col>
                  { user.role === 'STUDENT' ? 
                      <Col xs={12} md={3}>
                          <Button variant="success" className="w-100 mb-2 mb-md-0 py-2 py-lg-1" href={`${id}/data`}>Ver Ensayos</Button>
                      </Col>
                      :
                    user.role === 'COLLABORATOR' ? 
                      <Col xs={12} md={3}>
                          <Button variant="success" className="w-100 mb-2 mb-md-0 py-2 py-lg-1" href={`${id}/data`}>Ver Correcciones</Button>
                      </Col>
                    : null
                  }
                  <Col xs={12} md={3}>
                    <Button variant="warning" title="Reestablecer Contraseña" className="w-100 mb-2 mb-md-0 py-2 py-lg-1" onClick={(e) => handleNewPassword(e, user)}>
                      Renovar Contraseña
                      { loading ? (
                        <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                      ) : (
                        <></>
                      )}
                    </Button>
                  </Col>
                  { user && !user.passwordSent ?  
                   <Col xs={12} md={3}>
                    <Button variant="warning" title="Reestablecer Contraseña" className="w-100 mb-2 mb-md-0 py-2 py-lg-1" onClick={(e) => handleSendPassword(e, user)}>
                      Enviar Contraseña
                      { loading  ? (
                        <span className="spinner-border spinner-border-sm ms-1" role="status" aria-hidden="true"></span>
                      ) : (
                        <></>
                      )}
                    </Button>
                  </Col>
                  : null}
                  {/* <Col xs={12} md={3}>
                    <Button variant="outline-danger" className="w-100 mb-2 mb-md-0 py-2 py-lg-1" >Desactivar</Button>
                  </Col> */}
                </Row>
              : 
              <></>
              }
            </div>

          </div>
        </div>
      </Container>
    </div>
  );
};

export default UserShow;
