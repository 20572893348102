import React, { useState, useEffect } from "react";
import { Correction, Homework, BoxProps } from "./SharedTypes";
import RubricE2Modal from "./RubricE2";
import axios from 'axios'; // <-- Add this import
import { useAuthHeader } from "react-auth-kit";

// Function to create a default Correction
const createDefaultCorrection = (): Correction => ({
  attachmentId: 4,
  assignmentScheduleId: 1,
  correctorId: 3,
  correctedId: 1,
  score: 5,
  containsAuthorData: false,
  justification: "",
});

const CorrectionBox: React.FC<BoxProps> = ({
  type,
  homeworkList: initialHomeworkList,
}) => {
  const [homeworks, setHomeworks] = useState<Homework[]>(initialHomeworkList);
  const [showModal, setShowModal] = useState(false);
  const [rating, setRating] = useState<number>(5.0);
  const [reviewText, setReviewText] = useState<string>("");
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [currentCorrection, setCurrentCorrection] = useState<Correction | null>(
    null
  );
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);
  const [isToggleOn, setIsToggleOn] = useState(false); // Toggle state
  const [error, setError] = useState<string | null>(null);
  const [showRubricModal, setShowRubricModal] = useState(false);

  const authHeader = useAuthHeader();

  useEffect(() => {
    const initializedHomeworks = initialHomeworkList.map((hw) => {
      // Don't create default corrections if none exist
      return hw.corrections && hw.corrections.length ? hw : { ...hw, corrections: [] };
    });
    setHomeworks(initializedHomeworks);
  }, [initialHomeworkList]);

  const handleOpenModal = (correction: Correction, index: number) => {
    setError(null);

    if (correction.status === "OUT") {
      return;
    }

    setCurrentCorrection(correction);
    setActiveAccordion(index);
    if (correction.justification) {
      setReviewText(correction.justification);
      setRating(correction.score || 5.0); 
      setIsToggleOn(correction.containsAuthorData); 
    } else {
      const defaultCorrection = createDefaultCorrection();
      setReviewText("");
      setRating(defaultCorrection.score);
      setIsToggleOn(false);
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleOpenDetailsModal = (
    e: React.MouseEvent,
    correction: Correction
  ) => {
    e.stopPropagation();
    if (correction.justification) {
      setCurrentCorrection(correction);
      setShowDetailsModal(true);
    }
  };

  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
  };

  const handleReviewSubmission = () => {
    setError(null);
    if (currentCorrection) {
      const updatedScore = isToggleOn ? -1 : parseFloat(rating.toFixed(1)); // Ensure it has one decimal place
      const putUrl = `${process.env.REACT_APP_API_URL}/stages/second/${currentCorrection.id}`; // URL with the correction id
      const newHomeworks = [...homeworks];
      const hwWithCurrentEvaluation = newHomeworks.find((hw) =>
        hw.corrections?.includes(currentCorrection)
      );
      if (reviewText === "" && (isToggleOn === false)){
        setError("Debes incluir comentarios para enviar una corrección.");
      } else if (hwWithCurrentEvaluation){
        const currentDate = new Date();
        const assignmentEndDate = new Date(hwWithCurrentEvaluation.deadline);
        if (currentDate > assignmentEndDate) {
          window.alert("Se acabó el tiempo de entrega.");
          window.location.reload();
        }
      }

      if (hwWithCurrentEvaluation && (isToggleOn || reviewText != "")){
        const currentDate = new Date();
        const assignmentEndDate = new Date(hwWithCurrentEvaluation.deadline);
        if (currentDate > assignmentEndDate) {
          window.alert("Se acabó el tiempo de entrega.");
          window.location.reload();
        } else {
          axios.put(putUrl, {
          score: updatedScore,
          justification: reviewText,
          containsAuthorData: isToggleOn
          },
          { headers: { Authorization: authHeader() } }
          )
          .then(response => {
            window.location.reload();
            // Update local state to reflect the changes made
            const newHomeworks = homeworks.map(hw => {
              if (hw.corrections) {
                hw.corrections = hw.corrections.map(corr => {
                  if (corr.id === currentCorrection.id) {
                    return { ...corr, score: updatedScore, justification: reviewText };
                  }
                  return corr;
                });
              }
              return hw;
            });
            setHomeworks(newHomeworks);
            handleCloseModal();
          })
        .catch(error => {
          console.error('Error updating correction');
        });

        }
      }
     
      
    }
  };

  const handleToggleChange = () => {
    setIsToggleOn((prevToggle) => !prevToggle);
    setRating(
      parseFloat(
        (!isToggleOn
          ? -1.0
          : (currentCorrection && currentCorrection.score) || 5.0
        ).toFixed(1) // Ensure it has one decimal place
      )
    );
  };


  const handleAccordionClick = (index: number) => {
    // Check that the index is within the bounds of the homeworks array.
    if (index >= 0 && index < homeworks.length) {
      const homework = homeworks[index];
      
      // Check that homework and corrections exist and there is at least one correction.
      if (homework && homework.corrections && homework.corrections.length > 0) {
        // Toggle the active accordion index
        if (!isStartDateInFuture(homework.startDate) && homework.status !== "CLOSED") {
          if (activeAccordion === index) {
            setActiveAccordion(null);
          } else {
            setActiveAccordion(index);
          }
        } }// If there are no corrections, you might not do anything or handle it differently
    }
  };
  
  const isStartDateInFuture = (startDate: Date): boolean => {
    return new Date(startDate) > new Date();
  };

  const isDetailsButtonDisabled = (correction: Correction) => !correction.justification;

  return (
    <div className={`homework-box ${type} container mt-3`}>
      <h2 style={{ color: "#072f75" }}>
        {type === "pending" ? "Próximas Tareas" : "Tareas Anteriores"}
      </h2>
      {homeworks.map((hw: Homework, index: number) => (
        <div key={index} className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === index ? "active" : ""}`}
                type="button"
                onClick={() => handleAccordionClick(index)}
              >
                <span className="hw-title" style={{ width: "200px" }}> Ensayo {hw.title} </span>
                <span className="ml-auto fecha-title">
                  {new Date(hw.startDate) > new Date() ? 
                  `No disponible hasta: ${hw.startDate.toLocaleString()}` : 
                  hw.status === "CLOSED" ?
                    "Corrección cerrada" :
                    hw.corrections && hw.corrections.length === 0 ?
                      'Sin correcciones aún' :
                      `Cierre: ${hw.deadline.toLocaleString()}`
}
                </span>
              </button>
            </h2>
            <div
              className={`accordion-collapse collapse ${activeAccordion === index ? "show" : ""}`}
            >
              <div className="accordion-body">
                {hw.corrections && hw.corrections.map(
                  (correction: Correction, correctionIndex: number) => (
                    <div
                      key={correctionIndex}
                      className={`correction-row mt-3 clickable ${correction.justification ? 'has-justification' : ''}`}
                      onClick={() => handleOpenModal(correction, index)}
                    >
                      Corrección {correctionIndex + 1}
                      {correction.status === "OUT" ? (
                        <span className="out-of-flow">
                          Quedaste fuera del flujo normal
                        </span>
                      ) : (
                        <>
                          {correction.corrected ? (
                            <span
                              className={`details-link ${correction.score === -1 ? "red-text" : ""}`}
                              onClick={(e) => !isDetailsButtonDisabled(correction) && handleOpenDetailsModal(e, correction)}
                              style={isDetailsButtonDisabled(correction) ? { pointerEvents: 'none', opacity: '0.6' } : {}}
                            >
                              Ver detalles (Puntaje: {correction.score.toFixed(1)}/10)
                            </span>
                          ) : (
                            <span className="not-delivered">
                              Aún no Entregado
                            </span>
                          )}

                          <a href={correction.attachment?.url} target="_blank" rel="noopener noreferrer" className="essay-link">
                            Ver Ensayo
                          </a>

                          <a rel="noopener noreferrer" className="corregir-link">
                            Corregir
                          </a>
                        </>
                      )}


                    </div>
                    
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

  
  
  {showModal && (
      <div className="modal fade show d-block" tabIndex={-1}> 
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Corrección Ensayo</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body mb-0 pb-1">

              <textarea
                className="form-control mb-3"
                rows={6}
                placeholder="Escribe tu corrección..."
                value={reviewText}
                onChange={(e) => setReviewText(e.target.value)}
              />
               {error && (
                  <div className="error-message">
                    {error}
                  </div>
                )}
              {!isToggleOn && (
                <>
                  <label htmlFor="customRange">
                    Califica el ensayo: {rating.toFixed(1)}/10
                  </label>
                  <input
                    type="range"
                    className="form-range"
                    id="customRange"
                    min="-1"
                    max="10"
                    step="0.5"
                    value={rating}
                    onChange={(e) => {
                      const selectedValue = Number(e.target.value);
                      if (selectedValue !== -0.5) {
                        setRating(selectedValue);
                      }
                    }}
                  />
                </>
              )}
              <div className="custom-checkbox mt-2">
                <input
                  type="checkbox"
                  checked={isToggleOn}
                  onChange={handleToggleChange}
                  id="negativeRatingToggle"
                />
                <label
                  className="custom-checkbox-label"
                  htmlFor="negativeRatingToggle"
                >
                  ¿Contiene datos de autor?
                </label>
              </div>
              
              <div>
                <div className="alert alert-warning my-1 py-1 text-sm" role="alert">
                  Recuerda no incluir el puntaje ni datos tuyos dentro del comentario. Y que el puntaje -1 es solamente si incluye datos de autor.
                </div>
              </div>  
            </div>
            <div className="modal-footer">
              <div className="rubric-link" onClick={() => setShowRubricModal(true)}>
                <u style={{ cursor: 'pointer', color: 'blue' }}>Ver Rúbrica</u>
              </div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseModal}
              >
                Cerrar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleReviewSubmission}
              >
                Enviar Corrección
              </button>
            </div>
          </div>
        </div>
        <RubricE2Modal showRubricModal={showRubricModal} setShowRubricModal={setShowRubricModal} />
      </div>
  )}
  
      {showDetailsModal && (
        <div className="modal fade show d-block" tabIndex={-1}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Detalles de Corrección</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseDetailsModal}
                ></button>
              </div>
              <div className="modal-body">
                <p>Justificación: {currentCorrection?.justification}</p>
                <p>Calificación: {currentCorrection?.score.toFixed(1)}/10</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseDetailsModal}
                >
                  Cerrar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CorrectionBox;
