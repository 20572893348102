import React, { useState, useEffect } from 'react';
import MyNavbar from "../../components/NavBar";
import { Homework3, BoxProps3, Evaluation } from "../../components/SharedTypes";
import EvaluationBox from "../../components/EvaluationBox";
import BlockedEvaluationBox from "../../components/BlockedEvaluationBox";
import axios from 'axios';
import "../../styles/etapa3.css";
import { useAuthHeader, useAuthUser } from "react-auth-kit";

type ApiDataItem = {
  number: number;
  assignmentSchedules: AssignmentSchedule[];
  assignmentUsers: AssignmentUser[];
};

type AssignmentUser = {
  id: number;
  status: string;
  correctionReviews: Reviews[];
};

type Reviews = {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  correctionScore: number;
  studentComment: string | null;
  correction: Correction;
};

type Correction = {
  id: number;
  correctorId: number;
  correctedId: number;
  corrected: boolean;
  score: number;
  containsAuthorData: boolean;
  justification: string | null;
  comments: string | null;
};

type AssignmentSchedule = {
  id: number;
  stage: string;
  status: string;
  startDate: string;
  endDate: string;
};

const Etapa3: React.FC = () => {
  const [pendingHomework, setPendingHomework] = useState<Homework3[]>([]);
  const [pastHomework, setPastHomework] = useState<Homework3[]>([]);
  const backendUrl = process.env.REACT_APP_API_URL;
  const stageThreeEndpoint = "/stages/fourth/data/third/"
  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);
  const authUser = useAuthUser();
  const userId = authUser()?.id;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}${stageThreeEndpoint}${userId}?stage=3`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        
        const responseData = response.data;
        const apiData: ApiDataItem[] = responseData.data;
        
        const homeworks: Homework3[] = [];
        for (const assignment of apiData) {
          const assignmentNumber = String(assignment.number);
          const schedules = assignment.assignmentSchedules[0];
          const startDate = schedules.startDate;
          const deadline = schedules.endDate;
          const assignmentUsers = assignment.assignmentUsers;
          const assignmentUser = assignmentUsers[0];
        
          const evaluations: Evaluation[] = [];
        
          for (const review of assignmentUser.correctionReviews) {
            const reviewId = review.id;
            const stat = assignmentUser.status;
            const correction = review.correction;
            const reviewText = correction.justification;
            const ratingObtained = correction.score;
            const correctedCorrection = correction.corrected;
            const ratingGiven = review.correctionScore;
            const correctedReview = review.corrected;
            
            evaluations.push({
                reviewId: reviewId,
                reviewText: reviewText,
                ratingGiven: ratingGiven,
                ratingObtained: ratingObtained,
                correctedReview: correctedReview,
                correctedCorrection: correctedCorrection,
                flowStatus: stat
              });
          }

          if (evaluations.length !== 0){
            homeworks.push({
              assignmentNumber: assignmentNumber,
              deadline: deadline,
              startDate: startDate,
              evaluations: evaluations,
              status: schedules.status
            });
          }
          
        }

        return homeworks;
      } catch (error) {
        return [];
      }
    };
      
      
    const currentDate = new Date();
    const pending: Homework3[] = [];
    const past: Homework3[] = [];

    fetchData()
      .then((homeworks) => {
        homeworks.forEach((homework) => {

          const deadlineString = homework.deadline;
          if (deadlineString !== null){
            const deadlineDate = new Date(deadlineString)
            if (currentDate < deadlineDate) {
              pending.push(homework);
            } else {
              past.push(homework);
            }
          }
        });

        setPendingHomework(pending);
        setPastHomework(past);
      })
      .catch((error) => {
        // console.error('Error al obtener los datos:', error);
      });
  }, [backendUrl, userId, token]);

  const pendingBoxProps: BoxProps3 = {
    type: "pending",
    homeworkList: pendingHomework,
  };

  const pastBoxProps: BoxProps3 = {
    type: "past",
    homeworkList: pastHomework,
  };

  return (
    <div className="App3">
      <MyNavbar />
      <div className="title-text">Etapa 3: Evaluar Correcciones</div>
      <div className="alerta">
        <div
          className="alert alert-warning alert-dismissible fade show custom-alert"
          role="alert"
        >
          <strong>Recuerda:</strong> Debes evaluar las 
              dos correcciones que se te fueron asignadas. Debes revisar que se hayan mencionado 2 
              puntos positivos y 2 puntos de mejora sobre tu ensayo. Ante dudas puedes escribir al correo <strong>ics2813.limerick@gmail.com</strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
        <div className="box3">
          <EvaluationBox {...pendingBoxProps} />
          <BlockedEvaluationBox {...pastBoxProps} />
        </div>
      </div>
    </div>
  );
};

export default Etapa3;
