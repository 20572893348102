import { Alert } from 'antd';
import React from 'react'
import { useAuthUser } from "react-auth-kit";

const CurrentUserInfo = () => {

    const authUser = useAuthUser();

    return (
      <div className="mt-lg-0 mt-1 py-0 px-lg-2 px-0 " >
         <Alert message={authUser()?.email || ''} type="info" />
      </div>
    )
}

export default CurrentUserInfo