import React from "react";
import "./App.css";
import { AuthProvider } from "react-auth-kit";
import RoutesComponent from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function App() {
  return (
    <AuthProvider
      authType={"cookie"}
      authName={"_auth"}
      cookieDomain={window.location.hostname}
      cookieSecure={window.location.protocol === "https:"} // set true if https is other used window.location.protocol === "https:"
    >
      <ToastContainer />
      <RoutesComponent />
    </AuthProvider>
  );
}
