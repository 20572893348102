import React, { useState, useEffect } from "react";
import axios from 'axios'; // 
import { Recorrection3, HomeworkRecorrection3, BoxPropsRecorrection3 } from "./SharedTypes";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import Toast from 'react-bootstrap/Toast';
import { toast } from "react-toastify";


function formatDate(dateString: string | null) {
  if (dateString === null) {
    return null;
  }
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1; 
  const year = date.getFullYear();

  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();

  const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;

  const formattedTime = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;

  return `${formattedDate}, ${formattedTime}`;
}

const RecorrectionBoxEtapa3: React.FC<BoxPropsRecorrection3> = ({
  type,
  homeworkList: initialHomeworkList,
}) => {
  const backendUrl = process.env.REACT_APP_API_URL;
  const recorrectionUrl = "/stages/fourth/revision/third";
  const [homeworks, setHomeworks] = useState<HomeworkRecorrection3[]>(initialHomeworkList);
  const [comments, setComments] = useState<string>("");
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [currentRecorrection, setCurrentRecorrection] = useState<Recorrection3 | null>(
    null
  );
  const [activeAccordion, setActiveAccordion] = useState<number | null>(null);
  const [isToggleOn, setIsToggleOn] = useState(false); 
  const [error, setError] = useState<string | null>(null); 
  const [showToast, setShowToast] = useState(false);

  const authHeader = useAuthHeader();
  const token = authHeader().slice(7);
  const authUser = useAuthUser();
  const userId = authUser()?.id;

  useEffect(() => {
    const initializedHomeworks = initialHomeworkList.map((hw) => ({
      ...hw,
      recorrections: hw.recorrections 
    }));
    setHomeworks(initializedHomeworks);
  }, [initialHomeworkList]);

  const sendData = async () => {
    try {
      const response = await axios.post(`${backendUrl}${recorrectionUrl}`, {
        comment: comments,
        assignment: currentRecorrection?.currentAssignmentUserId,
        stage: 3,
        user: userId,
        instanceId: currentRecorrection?.instanceId
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success('Recorrección creada exitosamente', {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      // console.log('Solicitud POST exitosa:', response.data);
      // handleShowToast();
    } catch (error) {
      toast.error(`Error al crear recorección`, {
        position: "bottom-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });

      return;
    }
  };

  const handleOpenModal = (recorrection: Recorrection3, index: number) => {
    setCurrentRecorrection(recorrection);
    setActiveAccordion(index);
    setComments("");
    setError(null);
    
    if (recorrection.reviewText) {
      
      setIsToggleOn(recorrection.ratingObtained === -1); 
    } 
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);

};

 
  const handleCloseDetailsModal = () => {
    setShowDetailsModal(false);
  };

  const handleReviewSubmission = () => {
    if (currentRecorrection) {
      const newHomeworks = [...homeworks];
      const hwWithCurrentRecorrection = newHomeworks.find((hw) =>
        hw.recorrections?.includes(currentRecorrection)
      );
  
      if (hwWithCurrentRecorrection && hwWithCurrentRecorrection.recorrections) {
        const evalIndex = hwWithCurrentRecorrection.recorrections.findIndex(
          (corr) => corr === currentRecorrection
        );
        const currentDate = new Date()
        const recorrectionDate = new Date(hwWithCurrentRecorrection.deadline);
        if (evalIndex !== -1) {
          if (!comments){
            setError("Debes inlcuir comentarios para enviar una recorrección.");
    
          } else if (currentDate > recorrectionDate) {
            window.alert("Se acabó el tiempo de entrega.");
            window.location.reload();
          
          } else {
            sendData();
            handleCloseModal();
          }
        } 
      }      
    }
  };

  const handleAccordionClick = (index: number) => {
    const hw = homeworks[index];
    if (activeAccordion === index || hw.status === "CLOSED") {
      setActiveAccordion(null);
    } else {
      setActiveAccordion(index);
    }
  };

  const handleShowToast = () => {
    setShowToast(true);
    setTimeout(() => {
      setShowToast(false);
    }, 6000); 
  };

  const handleHideToast = () => {
    setShowToast(false);
  };

  return (
    <div className={`homework-box ${type} container mt-3`}>
      <h2 style={{ color: "#072f75" }}>
        {type === "pending" ? "Próximas Tareas" : "Tareas Anteriores"}
      </h2>
      {homeworks.map((hw: HomeworkRecorrection3, index: number) => (
        <div key={index} className="accordion">
          <div className="accordion-item">
            <h2 className="accordion-header">
              <button
                className={`accordion-button ${activeAccordion === index ? "active" : ""}`}
                type="button"
                onClick={() => handleAccordionClick(index)}
              >
                <span className="hw-title" style={{ width: "200px" }}> Ensayo {hw.assignmentNumber} </span>
                <span className="endDate">
                {new Date(hw.startDate) > new Date() ? 
                    `No disponible hasta: ${formatDate(hw.startDate)}` : 
                    hw.status === "CLOSED" ?
                      "Recorrección cerrada" :
                      `Cierre: ${formatDate(hw.deadline)}`
                  }
                </span>
              </button>
            </h2>
            <div
               className={`accordion-collapse collapse ${activeAccordion === index && new Date(hw.startDate) <= new Date() ? "show" : ""}`}
            >
              <div className="accordion-body">
                {hw.recorrections && hw.recorrections.map(
                  (recorrection: Recorrection3, recorrectionIndex: number) => (
                
                    <div
                      key={recorrectionIndex}
                      className={`correction-row mt-3 clickable ${recorrection.flowStatus === "OUT" ? "disabled" : ""}`}
                      onClick={() => recorrection.flowStatus !== "OUT" && handleOpenModal(recorrection, index)}
                    >
                      Recorreccón {recorrectionIndex + 1} 
                    
                      {recorrection.flowStatus === "OUT" ? (
                      <div className="points4">
                        Quedaste fuera del flujo
                      </div>
                    ) : (
                      <>
                        {recorrection.correctedCorrection ? (
                          <div className="points4">
                            <span>
                              Puntaje entregado por mí: {recorrection.ratingGiven}/10
                            </span> 
                          </div>
                        )  : (
                          <span className="points4">
                            No entregaste corrección
                          </span>
                        )}
                        {recorrection.correctedReview ? (
                          <div className="points-given">
                            <span>
                              Puntaje asignado: {recorrection.ratingObtained}/3
                            </span> 
                          </div>
                        )  : (
                          <span className="not-delivered">
                            Aún no te han evaluado
                          </span>
                        )}
                      </>
                    )}
                    </div>
                  )   
                )}
              </div>
            </div>
          </div>
        </div>
      ))}

  
  
  {showModal && (
      <div className="modal fade show d-block" tabIndex={-1}> 
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Recorregir Evaluación</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleCloseModal}
              ></button>
            </div>
            <div className="modal-body">
                <div className="">
                <p><strong>Corrección entregada por mí:</strong> {currentRecorrection?.reviewText}</p>
                <p><strong>Puntaje entregado por mí:</strong> {currentRecorrection?.ratingGiven}/10</p>
                <p><strong>Puntaje que se me asignó por mi corrección:</strong> { currentRecorrection?.correctedReview && currentRecorrection?.ratingObtained != null ? `${currentRecorrection?.ratingObtained}/3` : '--'}</p>
              </div>

              {!isToggleOn && (
                <>
                <label htmlFor="comments">
                    <strong>Solicitar Recorrección:</strong>
                  </label>
                  
                  <textarea
                    className="form-control mb-0"
                    rows={6}
                    placeholder="Comentarios..."
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    
                      />
                </>
              )}

              {error && (
              <div className="error-message">
                {error}
              </div>
            )}
              
            </div>
            {/* {currentRecorrection?.revisions?.length > 0 ?
              <span className="px-4 my-0 text-muted">Ya tienes una corrección enviada, puedes volver a enviarla por si te falto agregar algo.</span>
              : null
            } */}
            <div className="modal-footer">

              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleCloseModal}
              >
                Cerrar
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleReviewSubmission}
                disabled={!(currentRecorrection?.correctedCorrection)  || !(currentRecorrection?.correctedReview)}
              >
                Enviar Recorreción
              </button>
            </div>
          </div>
        </div>

      </div>
  )}
  
      {showDetailsModal && (
        <div className="modal fade show d-block" tabIndex={-1}>
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Detalles de Corrección</h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleCloseDetailsModal}
                ></button>
              </div>
              <div className="modal-body">
                <p>Corrección: {currentRecorrection?.reviewText}</p>
                <p>Puntaje Obtenido: {currentRecorrection?.ratingObtained}/10</p>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseDetailsModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {showToast && (
        <Toast
          onClose={handleHideToast}
          show={showToast}
          delay={3000}
          autohide
          style={{
            position: 'fixed',
            bottom: 10,
            right: 10,
            backgroundColor: '#CBFCCD',
            zIndex: 1000,
          }}
        >
          <Toast.Header>
            <strong className="me-auto">Éxito</strong>
          </Toast.Header>
          <Toast.Body>
            Tu recorrección fue entregada exitosamente.
            <div className="progress mt-2" style={{ height: '4px' }}>
              <div
                className="progress-bar bg-success"
                role="progressbar"
                style={{ width: '100%' }}
                aria-valuenow={100}
                aria-valuemin={0}
                aria-valuemax={100}
              ></div>
            </div>
          </Toast.Body>
        </Toast>
      )}
    </div>
  );
};

export default RecorrectionBoxEtapa3;