import React from "react";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import axios from "axios";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "../styles/changePassword.css";
import RoleNavBar from "../components/RoleNavbar";

type ChangePasswordValues = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

export default function ChangePassword() {
  const navigate = useNavigate();
  const authHeader = useAuthHeader();
  const initialValues: ChangePasswordValues = {
    currentPassword: "",
    password: "",
    confirmPassword: "",
  };

  const authUser = useAuthUser();
  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required(
      "La contraseña actual es obligatoria"
    ),
    password: Yup.string()
      .required("La nueva contraseña es obligatoria")
      .min(8, "La contraseña debe tener al menos 8 caracteres")
      .matches(
        /^(?=.*[A-Z]).*$/,
        "La contraseña debe contener al menos 1 letra mayúscula"
      ),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        "Las contraseñas deben coincidir"
      )
      .required("La confirmación de contraseña es obligatoria"),
  });
  const handleSubmit = async (
    values: ChangePasswordValues,
    { setSubmitting, setErrors, resetForm }: FormikHelpers<ChangePasswordValues>
  ) => {
    try {
      const backendUrl = process.env.REACT_APP_API_URL;
      const userId = authUser()?.id;
      const changePasswordEndpoint = `/users/${userId}/changePassword`;
      const response = await axios.post(
        `${backendUrl}${changePasswordEndpoint}`,
        values,
        {
          headers: {
            Authorization: authHeader(),
          },
        }
      );
      toast.success("Contraseña cambiada exitosamente", {
        position: "bottom-right",
        onClose: () => {
          resetForm();
          navigate("/logOut");
        },
      });
    } catch (error) {
      setErrors({ password: "Error al cambiar de contrasena" });
      if (axios.isAxiosError(error) && error.response) {
        const status = error.response.status;
        if (status === 401) {
          setErrors({ currentPassword: "Contraseña actual incorrecta" });
        }
        if (status === 400) {
          setErrors({ password: "Error al cambiar de contrasena" });
        }
      }
    } finally {
      setSubmitting(false);
    }
  };
  return (
    <div className="App1">
      <RoleNavBar />
      <div className="change-container mt-5">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting }) => (
            <Form className="login-form">
              <div className="password-change-form-group">
                <label htmlFor="email">Contraseña actual:</label>
                <Field
                  type="password"
                  id="currentPassword"
                  name="currentPassword"
                  className="password-change-form-control"
                  placeholder="Contraseña actual"
                />
                <ErrorMessage
                  name="currentPassword"
                  component="div"
                  className="password-change-error-message"
                />
              </div>

              <div className="password-change-form-group">
                <label htmlFor="password">Nueva Contraseña:</label>
                <Field
                  type="password"
                  id="password"
                  name="password"
                  className="password-change-form-control"
                  placeholder="Nueva contraseña"
                />
                <ErrorMessage
                  name="password"
                  component="div"
                  className="password-change-error-message"
                />
              </div>
              <div className="password-change-form-group">
                <label htmlFor="confirmNewPassword">
                  Repetir nueva contraseña:
                </label>
                <Field
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  className="password-change-form-control"
                  placeholder="Confirma tu contraseña"
                />
                <ErrorMessage
                  name="confirmPassword"
                  component="div"
                  className="password-change-error-message"
                />
              </div>
              <div className="text-center">
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#072f75",
                    border: "none",
                  }}
                  className="btn password-change-btn"
                  disabled={isSubmitting}
                >
                  Cambiar
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
