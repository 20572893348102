import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../App.css";

export default function MyNavbar() {
  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="shadow p-3">
      <Navbar.Brand as={Link} to="/" className="navbar-link">
        Limerick
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="navbar-nav-dropdown"
        className="custom-toggler"
      />
      <Navbar.Collapse id="navbar-nav-dropdown">
        <Nav className="ms-auto">
          <Nav.Link
            as={Link}
            to="/login"
            active
            className="navbar-link"
            style={{ marginRight: "13px" }}
          >
            Ingresar
          </Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}
