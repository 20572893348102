import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useIsAuthenticated } from "react-auth-kit";

type PrivateCommonRoutesProps = {
  redirectPath: string;
};
export default function PrivateCommonRoutes(
  props: PrivateCommonRoutesProps
): JSX.Element {
  const isAuthenticated = useIsAuthenticated();
  return isAuthenticated() ? <Outlet /> : <Navigate to={props.redirectPath} />;
}
