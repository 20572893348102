import React from 'react';
import MyNavbar from "../../components/RoleNavbar";
import Table from 'react-bootstrap/Table';
import axios from "axios";
import { useEffect, useState } from 'react';
import "react-datetime/css/react-datetime.css";
import "../../styles/formularios.css";
import { useAuthHeader, useAuthUser } from "react-auth-kit";
import { Alert, Col, Row } from 'antd';

type DataItem = {
  id: string
  stage: string;
  status: string;
  assignment: Assignment;
  corrections: Correction[];
  reviewsRecieved: CorrectionReview[];
  revisions: Revision[];
  startDate: string;
  endDate: string;
  e2Ready: boolean;
  e3Ready: boolean;
  e4Ready: boolean;
  e2Revision: Revision | undefined;
  e3Revision: Revision | undefined;
  score: number;
  finalScore: number;
};

type CourseSection = {
  number: number;
};

type Assignment = {
  number: number;
  averageScore: number;
  createdAt: string;
  status: string;
  courseSection: CourseSection;
  assignmentSchedules: AssignmentSchedule[];
};

type AssignmentSchedule = {
  endDate: string | number | Date;
  stage: string;
  status: string;
};

type Correction = {
  corrected: boolean;
  score: number;
};

type CorrectionReview = {
  corrected: boolean;
  assignmentScheduleId: number;
  assigmentUserId: string;
  correctionScore: number;
};

type Revision = {
  scoreDelta: number;
  stage: string;
  assigmentUser: assigmentUser;
};

type assigmentUser = {
  assignment: Assignment;
};

const stages = [ 'FIRST', 'SECOND', 'THIRD', 'FOURTH'];

const Resultados: React.FC = () => {
  const [data, setData] = useState<DataItem[]>([]);
  const backendUrl = process.env.REACT_APP_API_URL;
  const assignmentsEndpoint = "/assignmentUser?user=";
  const revisionsEndpoint = "/users/results/";
  const authHeader = useAuthHeader();
  const authUser = useAuthUser();
  const userId = authUser()?.id;

  useEffect(() => {

    const fetchData = async () => {
      try {
        const response = await axios.get(`${backendUrl}${assignmentsEndpoint}${userId}`, {
          headers: { Authorization: authHeader()}
        });

        const response2 = await axios.get(`${backendUrl}${revisionsEndpoint}${userId}`, {
          headers: { Authorization: authHeader()}
        });

        const revisionData = response2.data.data;

        const assignmentData = response.data.data;

        const currentDate = new Date();        

        assignmentData.forEach((item: DataItem) => {
          item.assignment.assignmentSchedules.sort((a: AssignmentSchedule, b: AssignmentSchedule) => {
            return stages.indexOf(a.stage) - stages.indexOf(b.stage);
          });

          // console.log(item.assignment.assignmentSchedules)

          item.e2Ready = item.assignment.assignmentSchedules[1].status != "OPEN" && new Date(item.assignment.assignmentSchedules[1].endDate) < currentDate;
          item.e3Ready = item.assignment.assignmentSchedules[2].status != "OPEN" && item.e2Ready && new Date(item.assignment.assignmentSchedules[2].endDate) < currentDate;
          item.e3Revision = revisionData.find( (rec: Revision) => { 
            return rec.stage == stages[1] && rec.assigmentUser.assignment.number == item.assignment.number
          });
          item.e3Revision = revisionData.find( (rec: Revision) => { 
            return rec.stage == stages[2] && rec.assigmentUser.assignment.number == item.assignment.number
          });
        });

        assignmentData.sort((a: DataItem, b: DataItem) => {
          return a.assignment.number - b.assignment.number;
        });

        setData(assignmentData);
        
      } catch (error) {
        // console.error('Error al realizar la solicitud:', error);
      }
    };

    fetchData();
  }, [backendUrl]);

  const get_status = (status: any, conditions_to_show: boolean) => {
    if (conditions_to_show) {
      if (status === "DELIVERED") {
        return "Enviado";
      } else if (status === "OUT") {
        return "No completado";
      } else if (status === "MU") {
        return "Datos de autor";
      } else if  (status === "NC") {
        return "Enviado";
      }
    } else {
      if (status !== 'NOT_DELIVERED') {
        return "Enviado"
      } else {
        return "No enviado"
      }
    }
  }

  return (
    <div className="Student">
      <MyNavbar />
      <div className="title-text">Resultado de los ensayos</div>

      <div className="container">
      <Alert
        message="Si tienes un estado de enviado pero sin puntaje final, es porque alguien no te respondio alguna etapa y será revisada por un ayudante."
        type="warning"
        closable
        className='mx-2 mb-2'
      />
      </div>
      <div className="tabla">
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th className="headers" rowSpan={2}>Número de ensayo</th>
              <th className="headers" rowSpan={2} >Estado</th>
              <th className="headers" colSpan={2}> Etapa 2</th>
              <th className="headers" colSpan={2}> Etapa 3</th>
              <th className="headers" colSpan={2}> Ajuste de Recorrección</th>
              <th className="headers" rowSpan={2}> Puntaje</th>
              <th className="headers" rowSpan={2}> Ajuste</th>
              <th className="headers" rowSpan={2}> Puntaje Final</th>
              {/* <th className="headers" rowSpan={2}> Promedio</th> */}
            </tr>
            <tr>
              <th className="headers">Corrector 1</th>
              <th className="headers">Corrector 2</th>
              <th className="headers">Corrector 1</th>
              <th className="headers">Corrector 2</th>
              <th className="headers">Etapa 2</th>
              <th className="headers">Etapa 3</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <td className="center">{item.assignment.number}</td>
                {/* <td className="center">{
                  item.status == "NOT_DELIVERED"  ? 
                  "No entregado" 
                  : (item.status == "DELIVERED" ? "Entregado" : "Cerrado")
                }</td> */}
                <td className="center">{ get_status(item.status, item.e2Ready && item.e3Ready) }</td>
                <td className="center">{
                  item.e2Ready && item.e3Ready ?
                  (item.corrections.length != 0 && item.corrections[0].corrected ? item.corrections[0].score : "No corregido") 
                  : "Pendiente"
                }</td>
                <td className="center">{
                  item.e2Ready && item.e3Ready ?
                  (item.corrections.length != 0 && item.corrections.length > 1 && item.corrections[1].corrected ? item.corrections[1].score : "No corregido")
                  : "Pendiente"
                }</td>
                <td className="center">{
                  item.e3Ready?
                  (item.reviewsRecieved.length != 0 && item.reviewsRecieved[0].corrected ? item.reviewsRecieved[0].correctionScore != null ? item.reviewsRecieved[0].correctionScore : '--': "No corregido")
                  : "Pendiente"
                }</td>
                <td className="center">{
                  item.e3Ready?
                  (item.reviewsRecieved.length != 0 && item.reviewsRecieved.length > 1 && item.reviewsRecieved[1].corrected  ? item.reviewsRecieved[1].correctionScore != null ? item.reviewsRecieved[1].correctionScore : '--' : "No corregido")
                  : "Pendiente"
                }</td>
                <td className="center">
                {!(item.revisions.length > 0 )
                  ? "No solicitado"
                  : item.revisions.reduce((sum, revision) => {
                      if (revision.stage === "SECOND") {
                        return sum + (revision.scoreDelta || 0);
                      } else {
                        return sum;
                      }
                    }, 0)
                }
                </td>
                <td className="center">
                {!(item.revisions.length > 0 )
                  ? "No solicitado"
                  : item.revisions.reduce((sum, revision) => {
                      if (revision.stage === "THIRD") {
                        return sum + (revision.scoreDelta || 0);
                      } else {
                        return sum;
                      }
                    }, 0)
                }
                </td>
                <td className="center">{!item.e3Ready || item.score == null ? "Por calcular" : item.score}</td>
                <td className="center">{!item.e3Ready || item.finalScore == null ? "Por calcular" : item.revisions.reduce((sum, revision) => sum + (revision.scoreDelta || 0), 0)}</td>
                <td className="center">{!item.e3Ready || item.finalScore == null ? "Por calcular" : item.finalScore}</td>
                {/* <td className="center">{item.assignment.averageScore == null ? "Por calcular" : item.assignment.averageScore}</td> */}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default Resultados;